import React from "react";
import Index from "../components/applicationRecord/PermitFee/Index";

const PermitFee = () => {
  return (
    <div>
      <Index />
    </div>
  );
};

export default PermitFee;
