import React, { useEffect, useState } from "react";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  dialogCancelButton,
  userHeading,
} from "../../../utils/muiComponentStyles";
import { useSelector } from "../../../redux/store";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CircularProgressLoader from "../../../utils/CircularProgressLoader";
import {
  tableDataCell,
  tableDataRow,
  tableHeadCell,
} from "../../applicationForm/ApplicationFormStyle";

const ConfirmContractorModal = ({
  loader,
  openModal,
  handleCloseContractorModal,
  selectedContractorDetail,
  expectedContractor,
  setExpectedContractor,
  showExpectedContractor,
  setShowExpectedContractor,
  dataExpectedContractor,
  setDataExpectedContractor,
  handleSubmitContractor,
}) => {
  const { contractorForListingData } = useSelector(
    (state) => state.contractorReducer
  );

  const [filterContractorRecord, setFilterContractorRecord] = useState([]);

  console.log("showExpectedContractor", showExpectedContractor);
  console.log("dataExpectedContractor", dataExpectedContractor);

  useEffect(() => {
    if (selectedContractorDetail) {
      let filterList = contractorForListingData.filter((item) => {
        if (item.type === selectedContractorDetail.type) {
          const nameMatches = item?.name
            ? item?.name
                .toLowerCase()
                .includes(selectedContractorDetail?.name.toLowerCase())
            : "";
          const companyNameMatches = item?.companyName
            ?.toLowerCase()
            .includes(selectedContractorDetail?.companyName?.toLowerCase());
          const licenseNumberMatches = item?.licenseNumber
            ?.toLowerCase()
            .includes(selectedContractorDetail?.licenseNumber?.toLowerCase());
          return nameMatches || companyNameMatches || licenseNumberMatches;
        }
      });
      console.log("filterList", filterList);
      setFilterContractorRecord(filterList);
    }
  }, [selectedContractorDetail]);

  const handleSelectedContractor = (contractor) => {
    console.log("contractor", contractor);
    setExpectedContractor(contractor);
  };

  const handleShowMinimumData = () => {
    setShowExpectedContractor(true);
    setDataExpectedContractor(expectedContractor);
    handleCloseContractorModal();
  };

  return (
    <BootstrapDialog
      onClose={() => handleCloseContractorModal()}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      fullWidth="true"
      maxWidth="md"
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => handleCloseContractorModal()}
      >
        <Typography sx={userHeading} variant="userTableHeadingBold">
          Add Contractor
        </Typography>
      </BootstrapDialogTitle>
      <DialogContent>
        <Box paddingTop={4}>
          <Typography
            variant="caption"
            sx={{ fontFamily: "Poppins", fontSize: "14px" }}
          >
            Are you sure, You want to add this contractor ?
          </Typography>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeadCell}>Company Name</TableCell>
                <TableCell sx={tableHeadCell}>Email</TableCell>
                <TableCell sx={tableHeadCell}>Phone Number</TableCell>
                <TableCell sx={tableHeadCell}>License Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterContractorRecord?.map((contractor, index) => (
                <TableRow
                  // selected={contractorId === contractor.id}
                  key={index}
                  sx={[
                    tableDataRow,
                    {
                      backgroundColor:
                        contractor.id === expectedContractor.id
                          ? "crimson"
                          : "",
                      color:
                        contractor.id === expectedContractor.id ? "white" : "",
                    },
                  ]}
                  onClick={() => handleSelectedContractor(contractor)}
                >
                  <TableCell sx={tableDataCell}>
                    {contractor?.companyName}
                  </TableCell>
                  <TableCell sx={tableDataCell}>{contractor?.email}</TableCell>
                  <TableCell sx={tableDataCell}>
                    {contractor?.phoneNumber}
                  </TableCell>
                  <TableCell sx={tableDataCell}>
                    {contractor?.licenseNumber}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          disableRipple
          sx={dialogCancelButton}
          autoFocus
          onClick={() => handleCloseContractorModal()}
        >
          cancel
        </Button>
        <Button
          type="submit"
          onClick={() => {
            if (expectedContractor.temporary) {
              handleSubmitContractor();
            } else {
              handleShowMinimumData();

              // mini data show
              // data select
            }
          }}
          sx={
            loader
              ? {
                  color: "white",
                  fontFamily: "Poppins-semibold",
                  fontSize: "15px",
                  background: "#979797",
                  borderRadius: "10px",
                  "&:hover": { backgroundColor: "#979797" },
                  "&:disabled": {
                    backgroundColor: "#979797",
                  },
                }
              : {
                  backgroundColor: "background.bgLayoutB",
                  color: "white",
                  fontFamily: "Poppins-semibold",
                  fontSize: "15px",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: "background.bgLayoutB",
                    boxShadow: 2,
                  },
                  "&:disabled": {
                    backgroundColor: "#979797",
                  },
                }
          }
        >
          Add Contractor
          {loader ? <CircularProgressLoader /> : ""}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default ConfirmContractorModal;
