import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  TableRow,
  TableHead,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Button,
  Checkbox,
  Box,
  Stack,
} from "@mui/material";
import {
  miniScrollBarStyles,
  rolePermissionHeading,
  tableHeadColor,
  tableHeadColor_minWidth,
} from "./muiRolePermissionsComponentStyles";
import { useDispatch, useSelector } from "../../redux/store";
import { getRolesList } from "../../redux/slices/RoleSlice";
import useLocales from "../../hooks/useLocales";
import toasterContext from "../../utils/context/toasterContext";
import {
  getUserRolesWithPermissionsList,
  getUsersList,
  updateUserRolesPermissions,
} from "../../redux/slices/UserSlice";
import CircularProgressLoader from "../../utils/CircularProgressLoader";

const Index = () => {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const fireToasterContext = useContext(toasterContext);
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [userRolesWithPermissions, setUserRolesWithPermissions] = useState([]);

  let { usersList, userRolesWithpermissionsList } = useSelector(
    (state) => state.userReducer
  );
  let { rolesList } = useSelector((state) => state.roleReducer);

  useEffect(() => {
    dispatch(getUsersList(handleApiRes));
    dispatch(getRolesList(handleApiRes));
    dispatch(getUserRolesWithPermissionsList(handleApiRes));
  }, []);

  const handleApiRes = (error) => {
    if (error) {
      fireToasterContext.fireToasterHandler(false, error);
    }
  };

  useEffect(() => {
    if (usersList?.length > 0) {
      setUsers(JSON.parse(JSON.stringify(usersList)));
    }
  }, [usersList]);

  useEffect(() => {
    if (rolesList?.length > 0) {
      setRoles(JSON.parse(JSON.stringify(rolesList)));
    }
  }, [rolesList]);

  useEffect(() => {
    if (userRolesWithpermissionsList?.length > 0) {
      setUserRolesWithPermissions(userRolesWithpermissionsList);
    }
  }, [userRolesWithpermissionsList]);

  const handleChangeRolePermission = async (data, index) => {
    // make temperory array
    let temp = [...userRolesWithPermissions];
    temp = JSON.parse(JSON.stringify(temp));
    const match = temp[index].findIndex((element) => element.id === data.id);

    // change the value of array
    let obj = temp[index][match];
    obj = JSON.parse(JSON.stringify(obj));
    obj.hasRole = !obj.hasRole;
    temp[index][match] = obj;
    setUserRolesWithPermissions(temp);
  };

  const handleSubmitRolePermissions = () => {
    setLoader(true);
    dispatch(
      updateUserRolesPermissions(
        userRolesWithPermissions.flat(),
        handleApiResponseForSubmitRolesPermissions
      )
    );
  };

  const handleApiResponseForSubmitRolesPermissions = (data) => {
    setLoader(false);
    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage
          ? data.messsage
          : "Users for the roles have been updated successfully"
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          overflow: "none",
          maxHeight: "80vh",
          borderRadius: "10px",
          ...miniScrollBarStyles,
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  ...tableHeadColor,
                  position: "sticky",
                  left: 0,
                  zIndex: 6,
                  borderBottom: "none",
                }}
              >
                <Box sx={{ width: "200px" }}>
                  <Typography
                    sx={rolePermissionHeading}
                    variant="rolePermissionTableHeadingBold"
                  >
                    {translate("userRoles")}
                  </Typography>
                </Box>
              </TableCell>
              {roles.length > 0 &&
                roles.map((role, index) => (
                  <TableCell
                    align="center"
                    sx={{ ...tableHeadColor_minWidth, borderBottom: "none" }}
                    key={index}
                  >
                    <Box>
                      <Typography
                        sx={rolePermissionHeading}
                        variant="rolePermissionTableHeadingBold"
                      >
                        {role.name}
                      </Typography>
                    </Box>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length > 0 &&
              users
                .sort((a, b) => a.id - b.id)
                .map((user, userIndex) => (
                  <TableRow
                    sx={{
                      backgroundColor: user.parentId
                        ? "background.bgTableOddRow"
                        : "background.bgTableEvenRow",
                    }}
                    key={userIndex}
                  >
                    <TableCell
                      sx={{
                        position: "sticky",
                        left: 0,
                        zIndex: 4,
                        backgroundColor: user.parentId
                          ? "background.bgTableOddRow"
                          : "background.bgTableEvenRow",
                        borderBottom: "none",
                      }}
                    >
                      <Typography
                        sx={rolePermissionHeading}
                        variant="rolePermissionTableCellName"
                      >
                        {user?.firstName + " " + user.lastName}
                      </Typography>
                    </TableCell>

                    {userRolesWithPermissions
                      .flat()
                      .filter((element) => element.userId === user.id)
                      ?.map((data) => (
                        <TableCell
                          align="center"
                          key={data.userId + data.roleId}
                          sx={{ borderBottom: "none" }}
                        >
                          <Checkbox
                            checked={data.hasRole}
                            onChange={() => {
                              handleChangeRolePermission(data, userIndex);
                            }}
                          />
                        </TableCell>
                      ))}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        direction="row"
        sx={{ display: "flex", justifyContent: "flex-end", marginTop: "30px" }}
      >
        <Button
          onClick={handleSubmitRolePermissions}
          disableRipple
          sx={
            loader
              ? {
                  background: "#979797",
                  borderRadius: "10px",
                  "&:hover": { backgroundColor: "#979797" },
                  "&:disabled": {
                    backgroundColor: "#979797",
                  },
                }
              : {
                  backgroundColor: "background.bgLayoutB",
                  borderRadius: "10px",
                  "&:hover": { backgroundColor: "background.bgLayoutB" },
                  "&:disabled": {
                    backgroundColor: "#979797",
                  },
                }
          }
        >
          <Typography
            sx={rolePermissionHeading}
            variant="rolePermissionTableHeadingButtonBold"
          >
            {translate("submit")}
          </Typography>
          {loader ? <CircularProgressLoader /> : ""}
        </Button>
      </Stack>
    </>
  );
};

export default Index;
