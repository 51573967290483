const en = {
  // side nav name changing keywords
  groupPermissions: `Group Permissions`,
  userGroup: `Users Group`,
  dashboard: `Dashboard`,
  // alarms replace alerts
  // alarms: `Alarms`,
  alarms: `Alerts`,
  rooms: `Rooms`,
  // procedure replace to events
  // procedures: `Procedures`,
  procedures: `Events`,
  // templates replace to event templates
  // templates: `Templates`,
  templates: `Event Templates`,
  // devices name changing with along input output to input devices and outlput devices
  devices: `Devices`,
  devicesList: `Devices list`,
  inputs: `Input Devices`,
  outputs: `Output Devices`,
  categories: `Categories`,
  // reports keywords
  logs: `History`,
  history: `History`,
  generateReport: `Generate Reports`,
  alertsdemo: `Alerts Demo`,
  // profile keyword change to locations
  // profiles: `Profiles`,
  profiles: `Locations`,
  profile: `Location`,
  license: `License`,
  users: `Users`,
  logout: `Logout`,
  reports: `Reports`,
  busyMode: `Busy Mode`,
  contactType: `Contact Type`,
  busyModeTime: `Busy Mode Time`,
  selectedLocations: `Selected Locations`,
  parentProfileId: "Parent Location",
  createroom: "Create Room",

  // Routes changing keywords
  eventtemplates: `Event Templates`,
  inputdevices: `Input Devices`,
  outputdevices: `Output Devices`,
  historyreports: `History Reports`,
  generatereports: `Generate Reports`,
  alertdemo: `Alert Demo`,
  locations: `Location`,
  roles: "Roles",
  rolesList: "Roles List",
  groupspermissions: `Groups Permissions`,
  usergroups: `User Groups`,
  alerts: `Alerts`,
  allusers: `All Users`,
  event: `Event`,
  userRoles: `User Roles`,
  userroles: `User Roles`,

  // generics keywords
  name: `Name`,
  room: `Room`,
  selectAll: `Select All`,
  selectedInputs: `Selected Inputs:`,
  selectedOutputs: `Selected Outputs:`,
  note: `Note:`,
  areYouSure: `Are you sure, you want to delete`,
  cancel: `Cancel`,

  // dashboard Keywords for changing
  welcome: `Welcome to`,
  projectName: `Soft Circles Template`,

  // dashboard cards keywords for changing
  firstCard: `First Card`,
  secondCard: `Second Card`,
  thirdCard: `Third Card`,
  fourthCard: `Fourth Card`,
  fifthCard: `Fifth Card`,
  sixthCard: `Sixth Card`,

  // alarms table keywords for changing
  // Generate Alarm change into generate alert
  // generateAlarm: `Generate Alarms`,
  generateAlarm: `Generate Alert`,
  noRoomAlarm: `No Room Alarm Procedures are available`,
  activeDay: `Active Day`,
  activeNight: `Active night`,
  procedureActive: `Procedure is active`,
  noAlarmProcedure: `No alarm procedure is available`,
  clearAlarms: `Clear alarms`,
  updateProcedure: `Update Procedure Settings`,

  // logs page changing keywords
  searchLog: `Search...`,
  startDate: `Start Date`,
  startTime: `Start Time`,
  endDate: `End Date`,
  endTime: `End Time`,
  search: `Search`,
  date: `Date`,
  text: `Text`,
  category: `Category`,
  generalLogs: `General Logs`,
  roomLogs: `Room Logs`,
  alertReceivedLogs: `Alert Received Logs`,
  lockLogs: `Lock Logs`,
  result: "Result",
  helpLogs: "Help Logs",

  // procedures page changing keyword
  searchProcedure: `Search...`,
  // create procedure change to create event
  // createProcedure: `Create Procedures`,
  createProcedure: `Create Event`,
  noProcedure: `No Events Available`,

  // create procedure file changes keywords
  createProcedureTitle: `Create Procedure`,
  alarmSound: `Alarm Sound`,
  alarmIntensity: `Alarm Intensity`,
  audioProcedure: `Audio Procedure`,
  selectedAllInput: `Selected All Inputs:`,
  selectedAllOutput: `Selected All Outputs:`,
  rules: `Rules`,
  start: `Start`,
  end: `End`,
  smsNotification: `SMS Notification`,
  whatsappNotification: `WhatsApp Notification`,
  emailNotification: `Email Notification`,
  sendNotificationAfter: "Send Notification After",
  // edit procedure file changes keywords
  // edit procedure change to edit events
  editProcedure: `Edit Event`,
  saveProcedure: `Save Event`,

  // delete procedure file changes keywords
  // delete procedure file change to delete event
  // deleteProcedure: `Delete Procedure`,
  deleteProcedure: `Delete Event`,

  // profile page changing keywords
  searchProfile: `Search...`,
  createProfile: `Create Profile`,
  createLocation: `Create Location`,
  dayToNight: `Day To Night`,
  nightToDay: `Night To Day`,
  noProfile: `No Locations available`,
  escalationGroup: `Escalation Group`,
  groupEscalaltionTimeInMinutes: `Escalation Time`,
  addEscalationGroup: `Add Escalation Group`,
  removeEscalationGroup: `Remove Escalation Group`,

  // create profile page changing keywords
  selectedRooms: "Selected Rooms:",
  dayToNightTime: `Day To Night Time`,
  dayShiftStart: `Day Shift Start`,
  dayShiftEnd: `Day Shift End`,
  nightShiftStart: `Night Shift Start`,
  nightShiftEnd: `Night Shift End`,
  nightToDayTime: `Night To Day Time`,
  secondryEscalationProfileId: `
  Secondry Escalation Location`,
  tertiaryEscalationProfileId: `
  Tertiary Escalation Location`,
  secondryEscalationTimeInMinutes: `
  Escalation Time`,
  tertiaryEscalationTimeInMinutes: `
  Escalation Time`,
  selectedUsers: `Selected Users`,

  // edit profile page changing keywords
  editProfile: "Edit Profile",
  saveLocation: "Save Location",
  editLocation: "Edit Location",
  selectRooms: `Select Rooms`,

  // delete profile page changing keywords
  deleteProfile: "Delete Profile",
  deleteLocation: "Delete Location",
  deleteLocationOf: "Delete Location of",

  // room page list
  noRoom: `No Room available`,
  searchRoom: `Search...`,
  createRoom: `Create Room`,
  editroom: `Edit Room`,
  createevent: `Create Event`,
  editevent: `Edit Event`,
  addinputdevices: `Add Input Devices`,
  editinputdevice: `Edit Input Devices`,
  addoutputdevices: `Add Output Devices`,
  editoutputdevice: `Edit Output Devices`,
  addlocations: `Add Location`,
  updatelocation: `Update Location`,
  adduser: `Add User`,
  udpateuserdetails: `Update User Details`,
  rtspURL: `Rtsp URL`,
  alarmURL: `Alarm URL`,

  // create room changing keywords
  cameraViewAddress: `Camera View Address`,
  doorTopic: `Door Topics`,
  doorPayload: `Door Payload`,
  timeTriggerEnabled: `Time Trigger Enabled`,
  timeTriggerTime: `Trigger Time`,
  timeTriggerTrigger: `Trigger Name`,
  audioSetupEnabled: `Audio Setup Enabled`,
  cameraIpAddress: `Camera Ip Address`,
  cameraUsername: `Camera Username`,
  cameraPassword: `Camera Password`,
  selectedProfiles: `Selected Locations`,
  escalationTime: `Escalation Time`,

  // update room file changing keywords
  editRoom: `Edit Room`,
  saveRoom: `Save Room`,

  // delete room changing keywords
  deleteRoom: `Delete Room`,

  // device listing page changing keywords
  searchDevices: `Search...`,
  createDevice: `Create Device`,
  deviceType: `Device Type`,
  noDeviceAvailable: `No Device Available`,

  // create device file changing keywords
  type: `Type`,
  ipAddress: `Ip Address`,
  userName: `Username`,
  password: `Password`,
  topic: `Topic`,

  // edit device file changing keywords
  editDevice: `Edit Device`,
  saveDevice: `Save Device`,

  // delete device file changing keywords
  deleteDevice: `Delete Device`,

  // inputs file changing keywords
  searchInputs: `Search...`,
  // create input change to create input device
  // createInput: `Create Input Device`,
  createInput: `Create Input Device`,
  triggers: `Triggers`,
  noInput: `No Input available`,

  // create inputs changing keywords
  trigger: `Trigger`,
  device: `Device`,

  // edit input file changing keywords
  // edit input to edit input device
  editInput: `Edit Input Device`,
  saveInput: `Save Input Device`,

  // delete input file changing keywords
  // delete input to delete input device
  deleteInput: `Delete Input Device`,

  // output file changing keywords
  searchOutput: `Search...`,
  // create output change into create output device
  // createOutput: `Create Output`,
  createOutput: `Create Output Device`,
  noOutput: `No output available`,

  // edit output file changing keywords
  editOutput: `Edit Output Device`,
  saveOutput: `Save Output Device`,

  // delete output file changing keywords
  deleteOutput: `Delete Output Device`,

  // category file changing keywords
  noCategory: `No Category Availble`,
  priority: "Priority",
  searchCategory: "Search...",
  high: "High",
  medium: `Medium`,
  low: `Low`,

  // create category file changing keywords
  createCategory: `Create Category`,

  // edit category file changing keywords
  editCategory: `Edit Category`,
  saveCategory: `Save Category`,

  // License file changing keywords
  companyName: `Company Name`,
  licenseValidTill: `License valid till`,
  numberOfUsers: `Number of users`,
  numberOfRooms: `Number of rooms`,
  numberOfDevices: `Number of devices`,
  numberOfInputs: `Number of inputs`,
  numberOfProcedures: `Number of procedures`,
  uploadLicenseFile: `Upload license file`,
  chooseFile: `Choose file`,
  noFileChosen: `No file chosen`,
  generatePreLicense: `Generate pre-license`,

  // users list files changing keywords
  searchUser: `Search...`,
  createUser: `Create User`,
  noUserAvailable: `No user available`,
  groups: `Groups`,
  groupsList: `Groups List`,
  status: `Status`,

  // create user files changing keywords
  firstName: `First Name`,
  lastName: `Last Name`,
  selectedRoles: `Selected Roles`,
  selectedGroups: `Selected Groups`,
  email: `E-mail`,
  phoneNumber: `Phone Number`,
  whatsapp: `Whatsapp`,
  sameForWhatsapp: `Same for Whatsapp`,

  // edit user files changing keywords
  editUser: `Edit User`,
  saveUser: `Save User`,
  active: `Active`,
  inActive: `InActive`,

  // role files changing keywords
  searchRole: `Search...`,
  createRole: `Create Role`,
  createGroup: `Create Group`,
  noRoleAvailable: `No group available`,
  description: `Description`,

  // edit role file changing keywords
  editRole: `Edit Role`,
  editGroup: `Edit Group`,
  saveRole: `Save Role`,
  saveGroup: `Save Group`,

  // delete role file changing keywords
  deleteRole: `Delete Role`,
  deleteGroup: `Delete Group`,

  // rolepermissions file changing keywords
  submit: `Submit`,
  rolePermissions: `Role Permissions`,
  rolespermissions: `Role Permissions`,

  // userRolepermissions file changing keywords
  userRolePermission: `User/Groups`,

  // templates files changing keywords
  searchTemplates: `Search...`,
  // create templates changes to create event templates
  // createTemplate: `Create Template`,
  createTemplate: `Create Event Template`,
  noTemplatesAvailable: `No Templates available`,

  // edit template file keyword
  editTemplate: `Edit Event Template`,
  saveTemplate: `Save Event Template`,

  // create template file keyword
  // add procedure to add event
  // addProcedure: `Add Procedure`,
  addProcedure: `Add Event`,

  // delete template file keyword
  // delete template to delete event templates
  deleteTemplate: `Delete Event Template`,

  // generall reports
  filter: `Filter`,
  export: `Export`,
  normal: "Normal",
  warning: "Warning",
  alarm: "Alarm",
  apply: "Apply",
  raised: "Raised",
  accepted: "Accepted",
  resolved: "Resolved",

  // alert demo keywords
  selectLocation: "Select Location",
  duration: "Duration",
  counter: "Counter",
  selectedEvents: "Selected Events",

  // report file keywords
  monthly: "Monthly",
  weekly: "Weekly",
  daily: "Daily",
  sunday: `Sunday`,
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  yearMonthDay: "Year, Month and Day",
  yearMonthEndDate: "Year, Month and End Date",
  yearMonth: "Year and Month",

  //groups file keywords
  noGroup: `No Groups available`,
  members: `Members`,
};

export default en;
