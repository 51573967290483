import React from "react";
import Index from "../../components/contractors/AddNewContractor";
const addContractor = () => {
  return (
    <div>
      <Index />
    </div>
  );
};

export default addContractor;
