import React, { useContext, useState } from "react";
import {
  Container,
  Typography,
  DialogActions,
  Button,
  DialogContent,
  Box,
} from "@mui/material";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  dialogCancelButton,
  userHeading,
} from "../../../utils/muiComponentStyles";
import toasterContext from "../../../utils/context/toasterContext";
import CircularProgressLoader from "../../../utils/CircularProgressLoader";
import { useDispatch } from "../../../redux/store";
import { deleteApplication } from "../../../redux/slices/dashboard/applicantsSlice";

const Index = ({ selectedApplication, openModal, handleCloseModal }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const fireToasterContext = useContext(toasterContext);

  const handleDeleteApplication = () => {
    setLoader(true);
    dispatch(deleteApplication(selectedApplication.id, handleApiRes));
  };

  const handleApiRes = (data) => {
    setLoader(false);
    if (data.status) {
      let boolean = true;
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "Application deleted successfully"
      );
      handleCloseModal(boolean);
    } else {
      let boolean = false;
      fireToasterContext.fireToasterHandler(
        false,
        data?.message ? data.message : "Something went wrong"
      );
      handleCloseModal(boolean);
    }
  };

  return (
    <Container>
      <BootstrapDialog
        onClose={() => handleCloseModal(false)}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleCloseModal(false)}
        >
          <Typography sx={userHeading} variant="userTableHeadingBold">
            Delete Application
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent>
          <Box paddingTop={4}>
            <Typography
              variant="caption"
              sx={{ fontFamily: "Poppins", fontSize: "14px" }}
            >
              Are you sure, You want to delete this applciation ?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            sx={dialogCancelButton}
            autoFocus
            onClick={() => handleCloseModal(false)}
          >
            cancel
          </Button>
          <Button
            type="submit"
            onClick={() => {
              handleDeleteApplication();
            }}
            sx={
              loader
                ? {
                    color: "white",
                    fontFamily: "Poppins-semibold",
                    fontSize: "15px",
                    background: "#979797",
                    borderRadius: "10px",
                    "&:hover": { backgroundColor: "#979797" },
                    "&:disabled": {
                      backgroundColor: "#979797",
                    },
                  }
                : {
                    backgroundColor: "background.bgLayoutB",
                    color: "white",
                    fontFamily: "Poppins-semibold",
                    fontSize: "15px",
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: "background.bgLayoutB",
                      boxShadow: 2,
                    },
                    "&:disabled": {
                      backgroundColor: "#979797",
                    },
                  }
            }
          >
            Delete Application
            {loader ? <CircularProgressLoader /> : ""}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Container>
  );
};

export default Index;
