import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Typography,
  DialogActions,
  Button,
  DialogContent,
  TextField,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  DialogTitle,
  Dialog,
} from "@mui/material";
import { textfield, userHeading } from "../../utils/muiComponentStyles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import toasterContext from "../../utils/context/toasterContext";
import { contractorCheckboxStyle } from "./buildingPlacard/BuildingPlacardStyle";
import CircularProgressLoader from "../../utils/CircularProgressLoader";
import {
  getSingleApplicationData,
  sendEmailToApplicant,
} from "../../redux/slices/dashboard/applicantsSlice";
import { dispatch } from "../../redux/store";
import { useTheme } from "@mui/styles";
import { InfoOutlined } from "@mui/icons-material";

const EmailToApplicant = ({ openEmailModal, handleCloseDialog }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const fireToasterContext = useContext(toasterContext);
  const [emailToApplicant, setEmailToApplicant] = useState({});
  const [messageForApplicant, setMessageForApplicant] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const selectedApplication = JSON.parse(
    localStorage.getItem("selectedApplication")
  );
  const theme = useTheme();

  useEffect(() => {
    // set button disable enable if pdf url exist
    setDisableButton(true);
    if (
      (selectedApplication?.applicationDetailPdfUrl &&
        emailToApplicant.applicationDetail) ||
      (selectedApplication?.permitFeePdfUrl && emailToApplicant.permitFee) ||
      (selectedApplication?.occupancyLetterPdfUrl &&
        emailToApplicant.occupancyLetter) ||
      (selectedApplication?.buildingPlacardPdfUrl &&
        emailToApplicant.buildingPlacard) ||
      (selectedApplication.recordedInspection.length > 0 &&
        emailToApplicant.recordedInspection) ||
      emailToApplicant.additionalInformationRequest
    ) {
      setDisableButton(false);
    }
  }, [emailToApplicant, messageForApplicant]);

  const handleEmailToApplicantCheckboxChange = (event) => {
    event.preventDefault();
    setEmailToApplicant({
      ...emailToApplicant,
      [event.target.name]: event.target.checked,
    });
  };

  const handleEmailApiResponse = (data) => {
    handleCloseDialog();
    if (data.status) {
      setBtnLoading(false);
      fireToasterContext.fireToasterHandler(
        true,
        data.message ? data.message : "Email sent successfully"
      );
      dispatch(getSingleApplicationData(selectedApplication?.id));
    } else {
      setBtnLoading(false);
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleSendEmailToApplicant = () => {
    if (emailToApplicant.additionalInformationRequest && !messageForApplicant) {
      return fireToasterContext.fireToasterHandler(
        false,
        "Enter your additional Information request"
      );
    }
    setBtnLoading(true);
    let finalData = {
      applicationId: selectedApplication?.id,
      applicationDetail:
        selectedApplication?.applicationDetailPdfUrl &&
        emailToApplicant?.applicationDetail,
      permitFee:
        selectedApplication?.permitFeePdfUrl && emailToApplicant?.permitFee,
      buildingPlacard:
        selectedApplication?.buildingPlacardPdfUrl &&
        emailToApplicant?.buildingPlacard,
      occupancyLetter:
        selectedApplication?.occupancyLetterPdfUrl &&
        emailToApplicant?.occupancyLetter,
      message: messageForApplicant,
      recordedInspection:
        emailToApplicant.recordedInspection &&
        selectedApplication.recordedInspection.length > 0,
      additionalInformationRequest:
        emailToApplicant.additionalInformationRequest,
    };
    dispatch(sendEmailToApplicant(finalData, handleEmailApiResponse));
  };

  return (
    <Container>
      <Dialog
        onClose={handleCloseDialog}
        open={openEmailModal}
        fullWidth
        style={{
          borderRadius: "50px",
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
          sx={{
            backgroundColor: "background.bgLayoutA",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 1.2,
          }}
        >
          <Typography sx={userHeading} variant="userTableHeadingBold">
            Email To Applicant
          </Typography>
          <IconButton onClick={handleCloseDialog}>
            <CancelOutlinedIcon
              sx={{ color: "white", width: 30, height: 30 }}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box paddingTop={4}>
            <FormControl component="fieldset" variant="standard">
              <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={contractorCheckboxStyle}
                      checked={
                        (selectedApplication?.applicationDetailPdfUrl &&
                          emailToApplicant?.applicationDetail) ||
                        false
                      }
                      onChange={handleEmailToApplicantCheckboxChange}
                      name="applicationDetail"
                      icon={
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="35"
                            height="35"
                            rx="9.5"
                            fill="white"
                            stroke="#979797"
                          />
                        </svg>
                      }
                      checkedIcon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9L0 27C0 29.3869 0.948211 31.6761 2.63604 33.364C4.32387 35.0518 6.61305 36 9 36H27C29.3869 36 31.6761 35.0518 33.364 33.364C35.0518 31.6761 36 29.3869 36 27V9C36 6.61305 35.0518 4.32387 33.364 2.63604C31.6761 0.948211 29.3869 0 27 0H9ZM27.0077 13.4203C27.3279 13.0203 27.4761 12.5095 27.4197 12.0003C27.3632 11.491 27.1068 11.025 26.7069 10.7049C26.3069 10.3847 25.7961 10.2365 25.2868 10.2929C24.7776 10.3493 24.3116 10.6057 23.9914 11.0057L14.8783 22.4074L11.2294 19.6714C11.0268 19.5195 10.7963 19.4089 10.5509 19.3461C10.3056 19.2832 10.0503 19.2693 9.79954 19.3051C9.54883 19.3409 9.30762 19.4258 9.08969 19.5548C8.87176 19.6838 8.68139 19.8545 8.52943 20.0571C8.37747 20.2598 8.26691 20.4903 8.20405 20.7357C8.1412 20.981 8.12728 21.2363 8.1631 21.487C8.19891 21.7377 8.28376 21.979 8.4128 22.1969C8.54183 22.4148 8.71253 22.6052 8.91514 22.7571L14.058 26.6143C14.4588 26.9153 14.961 27.0485 15.4583 26.9856C15.9555 26.9228 16.4088 26.6688 16.722 26.2774L27.0077 13.4203Z"
                            fill="#040F25"
                          />
                        </svg>
                      }
                    />
                  }
                  label="Application Details"
                />
              </FormGroup>
              <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={contractorCheckboxStyle}
                      checked={
                        (selectedApplication?.permitFeePdfUrl &&
                          emailToApplicant.permitFee) ||
                        false
                      }
                      onChange={handleEmailToApplicantCheckboxChange}
                      name="permitFee"
                      icon={
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="35"
                            height="35"
                            rx="9.5"
                            fill="white"
                            stroke="#979797"
                          />
                        </svg>
                      }
                      checkedIcon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9L0 27C0 29.3869 0.948211 31.6761 2.63604 33.364C4.32387 35.0518 6.61305 36 9 36H27C29.3869 36 31.6761 35.0518 33.364 33.364C35.0518 31.6761 36 29.3869 36 27V9C36 6.61305 35.0518 4.32387 33.364 2.63604C31.6761 0.948211 29.3869 0 27 0H9ZM27.0077 13.4203C27.3279 13.0203 27.4761 12.5095 27.4197 12.0003C27.3632 11.491 27.1068 11.025 26.7069 10.7049C26.3069 10.3847 25.7961 10.2365 25.2868 10.2929C24.7776 10.3493 24.3116 10.6057 23.9914 11.0057L14.8783 22.4074L11.2294 19.6714C11.0268 19.5195 10.7963 19.4089 10.5509 19.3461C10.3056 19.2832 10.0503 19.2693 9.79954 19.3051C9.54883 19.3409 9.30762 19.4258 9.08969 19.5548C8.87176 19.6838 8.68139 19.8545 8.52943 20.0571C8.37747 20.2598 8.26691 20.4903 8.20405 20.7357C8.1412 20.981 8.12728 21.2363 8.1631 21.487C8.19891 21.7377 8.28376 21.979 8.4128 22.1969C8.54183 22.4148 8.71253 22.6052 8.91514 22.7571L14.058 26.6143C14.4588 26.9153 14.961 27.0485 15.4583 26.9856C15.9555 26.9228 16.4088 26.6688 16.722 26.2774L27.0077 13.4203Z"
                            fill="#040F25"
                          />
                        </svg>
                      }
                    />
                  }
                  label="Permit Fees"
                />
              </FormGroup>
              <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={contractorCheckboxStyle}
                      checked={
                        (selectedApplication?.buildingPlacardPdfUrl &&
                          emailToApplicant.buildingPlacard) ||
                        false
                      }
                      onChange={handleEmailToApplicantCheckboxChange}
                      name="buildingPlacard"
                      icon={
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="35"
                            height="35"
                            rx="9.5"
                            fill="white"
                            stroke="#979797"
                          />
                        </svg>
                      }
                      checkedIcon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9L0 27C0 29.3869 0.948211 31.6761 2.63604 33.364C4.32387 35.0518 6.61305 36 9 36H27C29.3869 36 31.6761 35.0518 33.364 33.364C35.0518 31.6761 36 29.3869 36 27V9C36 6.61305 35.0518 4.32387 33.364 2.63604C31.6761 0.948211 29.3869 0 27 0H9ZM27.0077 13.4203C27.3279 13.0203 27.4761 12.5095 27.4197 12.0003C27.3632 11.491 27.1068 11.025 26.7069 10.7049C26.3069 10.3847 25.7961 10.2365 25.2868 10.2929C24.7776 10.3493 24.3116 10.6057 23.9914 11.0057L14.8783 22.4074L11.2294 19.6714C11.0268 19.5195 10.7963 19.4089 10.5509 19.3461C10.3056 19.2832 10.0503 19.2693 9.79954 19.3051C9.54883 19.3409 9.30762 19.4258 9.08969 19.5548C8.87176 19.6838 8.68139 19.8545 8.52943 20.0571C8.37747 20.2598 8.26691 20.4903 8.20405 20.7357C8.1412 20.981 8.12728 21.2363 8.1631 21.487C8.19891 21.7377 8.28376 21.979 8.4128 22.1969C8.54183 22.4148 8.71253 22.6052 8.91514 22.7571L14.058 26.6143C14.4588 26.9153 14.961 27.0485 15.4583 26.9856C15.9555 26.9228 16.4088 26.6688 16.722 26.2774L27.0077 13.4203Z"
                            fill="#040F25"
                          />
                        </svg>
                      }
                    />
                  }
                  label="Building Placard"
                />
              </FormGroup>
              <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={contractorCheckboxStyle}
                      checked={
                        (selectedApplication?.occupancyLetterPdfUrl &&
                          emailToApplicant.occupancyLetter) ||
                        false
                      }
                      onChange={handleEmailToApplicantCheckboxChange}
                      name="occupancyLetter"
                      icon={
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="35"
                            height="35"
                            rx="9.5"
                            fill="white"
                            stroke="#979797"
                          />
                        </svg>
                      }
                      checkedIcon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9L0 27C0 29.3869 0.948211 31.6761 2.63604 33.364C4.32387 35.0518 6.61305 36 9 36H27C29.3869 36 31.6761 35.0518 33.364 33.364C35.0518 31.6761 36 29.3869 36 27V9C36 6.61305 35.0518 4.32387 33.364 2.63604C31.6761 0.948211 29.3869 0 27 0H9ZM27.0077 13.4203C27.3279 13.0203 27.4761 12.5095 27.4197 12.0003C27.3632 11.491 27.1068 11.025 26.7069 10.7049C26.3069 10.3847 25.7961 10.2365 25.2868 10.2929C24.7776 10.3493 24.3116 10.6057 23.9914 11.0057L14.8783 22.4074L11.2294 19.6714C11.0268 19.5195 10.7963 19.4089 10.5509 19.3461C10.3056 19.2832 10.0503 19.2693 9.79954 19.3051C9.54883 19.3409 9.30762 19.4258 9.08969 19.5548C8.87176 19.6838 8.68139 19.8545 8.52943 20.0571C8.37747 20.2598 8.26691 20.4903 8.20405 20.7357C8.1412 20.981 8.12728 21.2363 8.1631 21.487C8.19891 21.7377 8.28376 21.979 8.4128 22.1969C8.54183 22.4148 8.71253 22.6052 8.91514 22.7571L14.058 26.6143C14.4588 26.9153 14.961 27.0485 15.4583 26.9856C15.9555 26.9228 16.4088 26.6688 16.722 26.2774L27.0077 13.4203Z"
                            fill="#040F25"
                          />
                        </svg>
                      }
                    />
                  }
                  label="Occupancy Letter"
                />
              </FormGroup>
              <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={contractorCheckboxStyle}
                      checked={
                        (selectedApplication?.recordedInspection.length &&
                          emailToApplicant.recordedInspection) ||
                        false
                      }
                      onChange={handleEmailToApplicantCheckboxChange}
                      name="recordedInspection"
                      icon={
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="35"
                            height="35"
                            rx="9.5"
                            fill="white"
                            stroke="#979797"
                          />
                        </svg>
                      }
                      checkedIcon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9L0 27C0 29.3869 0.948211 31.6761 2.63604 33.364C4.32387 35.0518 6.61305 36 9 36H27C29.3869 36 31.6761 35.0518 33.364 33.364C35.0518 31.6761 36 29.3869 36 27V9C36 6.61305 35.0518 4.32387 33.364 2.63604C31.6761 0.948211 29.3869 0 27 0H9ZM27.0077 13.4203C27.3279 13.0203 27.4761 12.5095 27.4197 12.0003C27.3632 11.491 27.1068 11.025 26.7069 10.7049C26.3069 10.3847 25.7961 10.2365 25.2868 10.2929C24.7776 10.3493 24.3116 10.6057 23.9914 11.0057L14.8783 22.4074L11.2294 19.6714C11.0268 19.5195 10.7963 19.4089 10.5509 19.3461C10.3056 19.2832 10.0503 19.2693 9.79954 19.3051C9.54883 19.3409 9.30762 19.4258 9.08969 19.5548C8.87176 19.6838 8.68139 19.8545 8.52943 20.0571C8.37747 20.2598 8.26691 20.4903 8.20405 20.7357C8.1412 20.981 8.12728 21.2363 8.1631 21.487C8.19891 21.7377 8.28376 21.979 8.4128 22.1969C8.54183 22.4148 8.71253 22.6052 8.91514 22.7571L14.058 26.6143C14.4588 26.9153 14.961 27.0485 15.4583 26.9856C15.9555 26.9228 16.4088 26.6688 16.722 26.2774L27.0077 13.4203Z"
                            fill="#040F25"
                          />
                        </svg>
                      }
                    />
                  }
                  label="Recorded Inspection"
                />
              </FormGroup>
              <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={contractorCheckboxStyle}
                      checked={
                        emailToApplicant.additionalInformationRequest || false
                      }
                      onChange={handleEmailToApplicantCheckboxChange}
                      name="additionalInformationRequest"
                      icon={
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="35"
                            height="35"
                            rx="9.5"
                            fill="white"
                            stroke="#979797"
                          />
                        </svg>
                      }
                      checkedIcon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9L0 27C0 29.3869 0.948211 31.6761 2.63604 33.364C4.32387 35.0518 6.61305 36 9 36H27C29.3869 36 31.6761 35.0518 33.364 33.364C35.0518 31.6761 36 29.3869 36 27V9C36 6.61305 35.0518 4.32387 33.364 2.63604C31.6761 0.948211 29.3869 0 27 0H9ZM27.0077 13.4203C27.3279 13.0203 27.4761 12.5095 27.4197 12.0003C27.3632 11.491 27.1068 11.025 26.7069 10.7049C26.3069 10.3847 25.7961 10.2365 25.2868 10.2929C24.7776 10.3493 24.3116 10.6057 23.9914 11.0057L14.8783 22.4074L11.2294 19.6714C11.0268 19.5195 10.7963 19.4089 10.5509 19.3461C10.3056 19.2832 10.0503 19.2693 9.79954 19.3051C9.54883 19.3409 9.30762 19.4258 9.08969 19.5548C8.87176 19.6838 8.68139 19.8545 8.52943 20.0571C8.37747 20.2598 8.26691 20.4903 8.20405 20.7357C8.1412 20.981 8.12728 21.2363 8.1631 21.487C8.19891 21.7377 8.28376 21.979 8.4128 22.1969C8.54183 22.4148 8.71253 22.6052 8.91514 22.7571L14.058 26.6143C14.4588 26.9153 14.961 27.0485 15.4583 26.9856C15.9555 26.9228 16.4088 26.6688 16.722 26.2774L27.0077 13.4203Z"
                            fill="#040F25"
                          />
                        </svg>
                      }
                    />
                  }
                  label="Additional Information Request"
                />
              </FormGroup>
            </FormControl>

            {selectedApplication.status === "Approved" &&
              emailToApplicant.permitFee && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "center",
                    background: theme.palette.info.light,
                    color: "white",
                    p: 1,
                  }}
                >
                  <InfoOutlined />
                  <Typography variant="subtitle2">
                    This is an approved application. Do you still want to send
                    payment email ?
                  </Typography>
                </Box>
              )}

            <TextField
              // disabled={!emailToApplicant.additionalInformationRequest}
              sx={{ ...textfield, mt: 2 }}
              fullWidth
              label="Type Email message here"
              name="name"
              variant="outlined"
              type="text"
              onChange={(event) => setMessageForApplicant(event.target.value)}
              InputLabelProps={{
                style: { color: "#6D7B88" },
              }}
              rows={3}
              multiline
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={disableButton}
            onClick={handleSendEmailToApplicant}
            sx={
              btnLoading
                ? {
                    width: "178px",
                    height: "46px",
                    background: "#979797",
                    borderRadius: "10px",
                    color: "white",
                    "&:hover": { backgroundColor: "#979797" },
                  }
                : {
                    width: "178px",
                    height: "46px",
                    backgroundColor: "background.bgLayoutB",
                    borderRadius: "10px",
                    color: "white",
                    fontFamily: "Poppins-Bold",
                    "&:hover": {
                      backgroundColor: "red",
                      boxShadow: 2,
                      transition: ".7s ease-in",
                    },
                    "&:disabled": {
                      backgroundColor: "#979797",
                    },
                  }
            }
          >
            Send Email
            {btnLoading ? <CircularProgressLoader /> : ""}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default EmailToApplicant;
