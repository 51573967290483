import React from "react";
import Index from "../components/applicationRecord/Media/index";
const Media = () => {
  return (
    <div>
      <Index />
    </div>
  );
};

export default Media;
