import React from "react";
import { Container } from "@mui/material";
import ElectricalContractors from "./EditContractors/ElectricalContractors";
import DwellingContractor from "./EditContractors/DwellingContractor";
import HvacContractor from "./EditContractors/HvacContractor";
import PlumbingContractor from "./EditContractors/PlumbingContractor";
import GeneralContractor from "./EditContractors/GeneralContractor";
const Index = () => {
  const selectedContractor = JSON.parse(
    localStorage.getItem("selectedContractor")
  );

  return (
    <>
      <Container>
        {selectedContractor?.type === "Electrical" ? (
          <ElectricalContractors />
        ) : selectedContractor?.type === "Construction" ? (
          <DwellingContractor />
        ) : selectedContractor?.type === "HVAC" ? (
          <HvacContractor />
        ) : selectedContractor?.type === "Plumbing" ? (
          <PlumbingContractor />
        ) : selectedContractor?.type === "General" ? (
          <GeneralContractor />
        ) : null}
      </Container>
    </>
  );
};

export default Index;
