import React, { useContext, useState } from "react";
import {
  Container,
  Typography,
  DialogActions,
  Button,
  DialogContent,
  Box,
} from "@mui/material";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  dialogCancelButton,
  userHeading,
} from "../../utils/muiComponentStyles";
import toasterContext from "../../utils/context/toasterContext";
import CircularProgressLoader from "../../utils/CircularProgressLoader";
import { useDispatch } from "../../redux/store";
import { deleteUser } from "../../redux/slices/UserSlice";

const Index = ({ selecteduser, openDeleteDialog, handleCloseDialog }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const fireToasterContext = useContext(toasterContext);

  const handleDeleteUser = () => {
    setLoader(true);
    dispatch(deleteUser(selecteduser.id, handleApiRes));
  };

  const handleApiRes = (data) => {
    setLoader(false);

    if (data.status) {
      console.log("data", data);
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "User deleted successfully"
      );
      handleCloseDialog();
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
      handleCloseDialog();
    }
  };

  return (
    <Container>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDeleteDialog}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography sx={userHeading} variant="userTableHeadingBold">
            Delete User
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent>
          <Box paddingTop={4}>
            <Typography
              variant="caption"
              sx={{ fontFamily: "Poppins", fontSize: "14px" }}
            >
              Are You Sure, You Want To Delete{" "}
              <b>{`${selecteduser.firstName} ${selecteduser.lastName}`}</b> ?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            sx={dialogCancelButton}
            autoFocus
            onClick={handleCloseDialog}
          >
            cancel
          </Button>
          <Button
            type="submit"
            onClick={handleDeleteUser}
            sx={
              loader
                ? {
                    color: "white",
                    fontFamily: "Poppins-semibold",
                    fontSize: "15px",
                    background: "#979797",
                    borderRadius: "10px",
                    "&:hover": { backgroundColor: "#979797" },
                    "&:disabled": {
                      backgroundColor: "#979797",
                    },
                  }
                : {
                    backgroundColor: "background.bgLayoutB",
                    color: "white",
                    fontFamily: "Poppins-semibold",
                    fontSize: "15px",
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: "background.bgLayoutB",
                      boxShadow: 2,
                    },
                    "&:disabled": {
                      backgroundColor: "#979797",
                    },
                  }
            }
          >
            Delete User
            {loader ? <CircularProgressLoader /> : ""}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Container>
  );
};

export default Index;
