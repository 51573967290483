import React from "react";
import Index from "../components/applicationRecord/OccupancyLetter/Index";
const OccupencyLetter = () => {
  return (
    <div>
      <Index />
    </div>
  );
};

export default OccupencyLetter;
