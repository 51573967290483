// import { Box, Typography, Grid } from "@mui/material";
import { Grid } from "@mui/material";
import React from "react";
// import { dashboardHeading } from "../utils/muiComponentStyles";
import DashboardComponet from "../components/dashboard/index";
// import useLocales from "../hooks/useLocales";

const gridContainerStyle = { marginTop: "30px", marginBottom: "30px" };

const Dashboard = () => {
  // const { translate } = useLocales();
  return (
    <>
      {/* <Box sx={{ display: "flex" }}>
        <Typography sx={dashboardHeading} variant="dashboardHeading">
          <span>{translate("welcome")}</span>
          <Typography sx={dashboardHeading} variant="dashboardHeadingBold">
            <span>WiPermit</span>
          </Typography>
        </Typography>
      </Box> */}
      <Grid container sx={gridContainerStyle}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <DashboardComponet />
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
