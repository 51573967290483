import React from "react";
import {
  Page,
  Text,
  View,
  Font,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import poppinsRegular from "../../../assets/fonts/Poppins-Regular.ttf";
import poppinsBold from "../../../assets/fonts/Poppins-Bold.ttf";
import poppinsMedium from "../../../assets/fonts/Poppins-SemiBold.ttf";
import logo from "../../../assets/icons/logo.png";

// ** custom fonts
Font.register({
  family: "PoppinsFamily",
  fonts: [
    {
      src: poppinsRegular,
    },
    {
      src: poppinsBold,
      fontWeight: "bold",
    },
    { src: poppinsMedium, fontWeight: "semibold" },
  ],
});

const inspectionStatusArray = [
  { id: 1, name: "Completed" },
  { id: 2, name: "Failed" },
  { id: 3, name: "Approved with Conditions noted" },
];

const inspectionPerformedArray = [
  { id: 1, name: "Footing" },
  { id: 2, name: "Foundation" },
  { id: 3, name: "Drain tile" },
  { id: 4, name: "Foundation water proofing" },
  { id: 5, name: "Foundation Insulation" },
  { id: 6, name: "Erosion Control" },
  { id: 7, name: "Rough Construction" },
  { id: 8, name: "Final Construction" },
  { id: 9, name: "Rough Electrical" },
  { id: 10, name: "Final Electrical" },
  { id: 11, name: "Underground Electrical" },
  { id: 12, name: "Electrical Service" },
  { id: 13, name: "Rough HVAC" },
  { id: 14, name: "Final HVAC" },
  { id: 15, name: "Final Plumbing" },
  { id: 16, name: "Rough Plumbing" },
  { id: 17, name: "Underground Plumbing" },
  { id: 18, name: "Plumbing Sewer Lateral" },
  { id: 19, name: "Building Insulation" },
  { id: 20, name: "Vapor Barrier" },
  { id: 21, name: "Final Occupancy Inspection" },
  { id: 22, name: "Temporary Occupancy Inspection" },
  { id: 23, name: "Gas Inspection" },
  { id: 24, name: "Other See Notes" },
];

// ** Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    paddingBottom: 65,
    paddingHorizontal: 10,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  minContainer: {
    margin: "30",
  },
  mainViewTag: {
    margin: "30px",
  },
  heading: {
    fontFamily: "PoppinsFamily",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#000000",
    margin: "20",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  titleHeading: {
    // flexDirection: "row",
    // display: "flex",
    // alignItems: "center",
    fontFamily: "PoppinsFamily",
    marginTop: "20px",
    gap: "15px",
    fontSize: "14px",
    borderBottom: "1px solid black",
  },
  pdfText: {
    fontFamily: "PoppinsFamily",
    fontSize: "12px",
  },
  titleSubPart1stView: {
    width: "33%",
  },
  titleSubPartLastView: {
    width: "34%",
  },
});

// ** Create Document Component
const RecordInspectionPdf = ({ data }) => {
  let application = JSON.parse(localStorage.getItem("selectedApplication"));

  function convertToAmPm(timeString) {
    const timeParts = timeString.split(":");
    let hours = parseInt(timeParts[0]);
    const minutes = timeParts[1];
    // const seconds = timeParts[2];

    let ampm = "AM";
    if (hours >= 12) {
      ampm = "PM";
      if (hours > 12) {
        hours -= 12;
      }
    } else if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${minutes} ${ampm}`;
  }

  function checkFileExt(filename) {
    const ext = filename.split(".");
    return ext[ext.length - 1];
  }

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };

  return (
    <Document pageMode="fullScreen" pageLayout="oneColumn">
      <Page size="A4" style={styles.page}>
        {/* main container */}
        <View style={styles.mainViewTag}>
          <View style={styles.heading}>
            <Image src={logo} style={{ height: "50px", width: "70px" }} />
            <Text>Inspection Report</Text>
          </View>
          {/* project address */}
          {[...data]
            ?.sort((a, b) => new Date(a.date) - new Date(b.date))
            .map((inspection, index) => (
              <View key={index} style={{ width: "100%" }}>
                {/* <View style={styles.titleHeading}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Inspection Report
                  </Text>
                </View> */}
                <View
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                    ...styles.pdfText,
                  }}
                >
                  <View style={styles.titleSubPart1stView}>
                    <Text
                      style={{
                        fontFamily: "PoppinsFamily",
                        fontWeight: "semibold",
                      }}
                    >
                      Inspection Date
                    </Text>
                    <Text style={{ fontFamily: "PoppinsFamily" }}>
                      {formatDate(inspection?.date)}
                    </Text>
                  </View>
                  <View style={styles.titleSubPart1stView}>
                    <Text
                      style={{
                        fontFamily: "PoppinsFamily",
                        fontWeight: "semibold",
                      }}
                    >
                      Time
                    </Text>
                    <Text style={{ fontFamily: "PoppinsFamily" }}>
                      {convertToAmPm(inspection?.time)}
                    </Text>
                  </View>
                  <View style={styles.titleSubPartLastView}>
                    <Text
                      style={{
                        fontFamily: "PoppinsFamily",
                        fontWeight: "semibold",
                      }}
                    >
                      Inspection Performed
                    </Text>
                    <Text style={{ fontFamily: "PoppinsFamily" }}>
                      {/* {inspectionPerformedArray.filter((item) =>
                      inspection?.inspectionPerformed.includes(item.id)
                    )} */}
                      {inspection.inspectionPerformed
                        .map((item) => {
                          for (
                            let i = 0;
                            i < inspectionPerformedArray.length;
                            i++
                          ) {
                            if (item === inspectionPerformedArray[i].id) {
                              return inspectionPerformedArray[i].name;
                            }
                          }
                        })
                        .join(", ")}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                    ...styles.pdfText,
                  }}
                >
                  <View style={styles.titleSubPartLastView}>
                    <Text
                      style={{
                        fontFamily: "PoppinsFamily",
                        fontWeight: "semibold",
                      }}
                    >
                      Project Address
                    </Text>
                    <Text style={{ fontFamily: "PoppinsFamily" }}>
                      {/* {inspectionPerformedArray.filter((item) =>
                      inspection?.inspectionPerformed.includes(item.id)
                    )} */}
                      {application.projectAddress}
                    </Text>
                  </View>
                  <View style={styles.titleSubPart1stView}></View>
                  <View style={styles.titleSubPart1stView}>
                    <Text
                      style={{
                        fontFamily: "PoppinsFamily",
                        fontWeight: "semibold",
                      }}
                    >
                      Inspection Status
                    </Text>
                    <Text style={{ fontFamily: "PoppinsFamily" }}>
                      {inspectionStatusArray.map((item) => {
                        if (item.name === inspection?.inspectionStatus) {
                          return item?.name;
                        }
                      })}
                    </Text>
                  </View>
                </View>
                {inspection?.inspectionNotes && (
                  <View
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      ...styles.pdfText,
                    }}
                  >
                    <View>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        Inspection Notes
                      </Text>
                      <Text style={{ fontFamily: "PoppinsFamily" }}>
                        {inspection?.inspectionNotes}
                      </Text>
                    </View>
                  </View>
                )}
                <View style={styles.titleHeading}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Inspector Information
                  </Text>
                </View>
                <View
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    ...styles.pdfText,
                  }}
                >
                  <View style={{ width: "50%" }}>
                    <Text
                      style={{
                        fontFamily: "PoppinsFamily",
                        fontWeight: "semibold",
                      }}
                    >
                      Inspector Name
                    </Text>
                    <Text style={{ fontFamily: "PoppinsFamily" }}>
                      {inspection?.inspectorName}
                    </Text>
                  </View>
                  <View style={{ width: "50%" }}>
                    <Text
                      style={{
                        fontFamily: "PoppinsFamily",
                        fontWeight: "semibold",
                      }}
                    >
                      State Credentials
                    </Text>
                    <Text style={{ fontFamily: "PoppinsFamily" }}>
                      {inspection?.inspectorCredentials}
                    </Text>
                  </View>
                </View>
                <View style={styles.titleHeading}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Media
                  </Text>
                </View>
                <View
                  style={{
                    width: "100%",
                    gap: "10px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {inspection?.media.map((media) => (
                    <>
                      {checkFileExt(media.url) !== "pdf" &&
                        checkFileExt(media?.url) !== "ppt" &&
                        checkFileExt(media?.url) !== "pptx" &&
                        checkFileExt(media?.url) !== "doc" &&
                        checkFileExt(media?.url) !== "docx" &&
                        checkFileExt(media?.url) !== "xls" &&
                        checkFileExt(media?.url) !== "xlsx" &&
                        checkFileExt(media?.url) !== "csv" && (
                          <Image
                            src={media?.url}
                            style={{
                              width: "200px",
                              height: "200px",
                              marginTop: "10px",
                            }}
                          />
                        )}
                    </>
                  ))}
                </View>
              </View>
            ))}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};
export default RecordInspectionPdf;
