import React from "react";

import { Typography, Grid, Box } from "@mui/material";
import Loader from "../../Loader/Loader";
import { recordInspectionTypo } from "../../../utils/muiComponentStyles";

const projectTypeList = [
  { id: 1, name: "New Building" },
  { id: 2, name: "Addition" },
  { id: 3, name: "Alteration/Repair" },
  { id: 4, name: "Deck" },
  { id: 5, name: "Demolition" },
  { id: 6, name: "Driveway" },
  { id: 7, name: "Fence" },
  { id: 8, name: "Pool" },
];

const index = ({ loading, application }) => {
  return (
    <Box>
      {loading ? (
        <Box
          sx={{
            // height: 180,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            mr: "88px",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <Box component="div" sx={{ mx: 3, my: 3 }}>
          <Typography sx={{ ...recordInspectionTypo, textAlign: "center" }}>
            Permit No: {application.permitNumber}
          </Typography>

          <Grid container spacing={2} sx={{ mt: 1, wordWrap: "break-word" }}>
            <Grid item xs={12} md={3}>
              <Typography sx={recordInspectionTypo}>Project Type</Typography>
              <Typography sx={recordInspectionTypo} variant="subText">
                {
                  projectTypeList.find(
                    (project) => project.id === application.projectType
                  )?.name
                }
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography sx={recordInspectionTypo}>Project Address</Typography>
              <Typography sx={recordInspectionTypo} variant="subText">
                {application?.projectAddress}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography sx={recordInspectionTypo}>Applicant Name</Typography>
              <Typography sx={recordInspectionTypo} variant="subText">
                {application.applicantName}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography sx={recordInspectionTypo}>Applicant Email</Typography>
              <Typography sx={recordInspectionTypo} variant="subText">
                {application.applicantEmail}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default index;
