import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Grid,
  Typography,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import {
  recordInspectionTypo,
  applicationCardStyle,
} from "../../../utils/muiComponentStyles";
import Loader from "../../Loader/Loader";
import RecordInspectionTable from "./RecordInspectionTable";
import RecordInspectionDetails from "./EditRecordInspectionDetails";
import AddRecordInspection from "./AddRecordInspection";
import { useSelector, useDispatch } from "../../../redux/store";
import { getAllInspections } from "../../../redux/slices/recordedInspectionsSlice";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import RecordInspectionPdf from "./RecordInpectionPdf";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { uploadPdf } from "../../../redux/slices/PdfDataSlice";
import ToasterContext from "../../../utils/context/toasterContext";
import CircularProgressLoader from "../../../utils/CircularProgressLoader";
import {
  getApplicantStatus,
  sendEmailToApplicant,
} from "../../../redux/slices/dashboard/applicantsSlice";

const projectTypeList = [
  { id: 1, name: "New Building" },
  { id: 2, name: "Addition" },
  { id: 3, name: "Alteration/Repair" },
  { id: 4, name: "Deck" },
  { id: 5, name: "Demolition" },
  { id: 6, name: "Driveway" },
  { id: 7, name: "Fence" },
  { id: 8, name: "Pool" },
];

const s3Client = new S3Client({
  endpoint: process.env.REACT_APP_SPACES_ENDPOINT,
  forcePathStyle: true,
  region: process.env.REACT_APP_SPACES_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_SPACES_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SPACES_SECRET_ACCESS_KEY,
  },
});

const RecordInspection = () => {
  const dispatch = useDispatch();
  let application = JSON.parse(localStorage.getItem("selectedApplication"));
  const permissions = localStorage.getItem("permissions");
  const permissionArray = permissions ? permissions.split(",") : [];
  const [manipulationPermission, setManipulationPermission] = useState(false);
  const [loading, setLoading] = useState(false);
  const fireToasterContext = useContext(ToasterContext);
  const [recordInspectionDetails, setRecodInspectionDetails] = useState(false);
  const [addRecordInspection, setAddRecordInspection] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [disablePdfButton, setDisablePdfButton] = useState(false);
  const [emailButtonLoader, setEmailButtonLoader] = useState(false);

  useEffect(() => {
    if (!permissionArray.includes("manipulate application details")) {
      setManipulationPermission(true);
    } else {
      setManipulationPermission(false);
    }
    dispatch(getAllInspections(handleApiResponse));
    setLoading(true);
  }, [dispatch]);

  const handleApiResponse = (data) => {
    if (data.status) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  let { inspectionsList } = useSelector(
    (state) => state.recordedInspectionsReducer
  );

  useEffect(() => {
    if (inspectionsList.length === 0) {
      setDisablePdfButton(false);
      application.recordedInspection = [];
      localStorage.setItem("selectedApplication", JSON.stringify(application));
      dispatch(getApplicantStatus(application.id));
    } else {
      setDisablePdfButton(true);
      handleUploadPdfToSpaces(inspectionsList);
      if (permissionArray.includes("manipulate application details")) {
        setEmailButtonLoader(false);
      }
    }
  }, [inspectionsList]);

  const handleRecordInspection = (data) => {
    setSelectedRecord(data);
    setRecodInspectionDetails((prevDetails) => !prevDetails);
  };
  const handleAddNewRecord = () => {
    setAddRecordInspection((prevDetails) => !prevDetails);
  };
  const handleBackButton = () => {
    setRecodInspectionDetails(false);
    setAddRecordInspection(false);
  };

  const handleUploadPdfToSpaces = async (latestDate) => {
    let pdfLink;
    const pdfBlob = await pdf(
      <RecordInspectionPdf data={latestDate} />
    ).toBlob();
    const pdfBuffer = await new Response(pdfBlob).arrayBuffer();
    // Upload PDF Buffer to DigitalOcean Spaces
    const params = {
      Bucket: process.env.REACT_APP_SPACES_BUCKET_NAME,
      Key: `${process.env.REACT_APP_ENVIRONMENT}/${
        application?.id
      }/Record-Inspection-${application?.id}-${Date.now()}.pdf`,
      Body: pdfBuffer,
      ACL: "public-read",
      ContentType: "application/pdf",
    };

    try {
      await s3Client.send(new PutObjectCommand(params));
      pdfLink = `${process.env.REACT_APP_SPACES_ENDPOINT}/${params.Bucket}/${params.Key}`;
      application.recordedInspectionPdfUrl = pdfLink;
      application.recordedInspection = latestDate;
      localStorage.setItem("selectedApplication", JSON.stringify(application));
      const pdfObj = {
        applicationId: application.id,
        occupancyLetterPdfUrl: false,
        applicationDetailPdfUrl: false,
        buildingPlacardPdfUrl: false,
        permitFeePdfUrl: false,
        recordedInspectionPdfUrl: pdfLink,
      };
      dispatch(uploadPdf(pdfObj));
    } catch (error) {
      console.error("Error uploading PDF:", error);
    }
  };

  const handleEmailApiResponse = (data) => {
    setEmailButtonLoader(false);
    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.message ? data.message : "Email sent successfully"
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleSendEmailToApplicant = () => {
    if (inspectionsList.length < 1) {
      fireToasterContext.fireToasterHandler(
        false,
        "Please create atleast one record inspection against this application"
      );
      return;
    }
    setEmailButtonLoader(true);
    let finalData = {
      applicationId: application?.id,
      applicationDetail: false,
      permitFee: false,
      buildingPlacard: false,
      occupancyLetter: false,
      recordedInspection: true,
      message: "",
    };
    dispatch(sendEmailToApplicant(finalData, handleEmailApiResponse));
  };

  return (
    <div>
      {/* <PDFViewer style={{ height: "100vh", width: "100%" }}>
        <RecordInspectionPdf data={inspectionsList} />
      </PDFViewer> */}
      <Card>
        <CardHeader
          disableTypography
          title={
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                {addRecordInspection
                  ? "Add New Inspection"
                  : recordInspectionDetails
                  ? "Update Record Inspection Details"
                  : "Record Inspection"}
              </Box>
              <Box>
                Status: {application?.status} | Permit Fee:{" "}
                {(application?.totalAmount * 1)?.toFixed(2)}
              </Box>
            </Box>
          }
          sx={applicationCardStyle}
          avatar={
            recordInspectionDetails ? (
              <IconButton onClick={() => handleBackButton()}>
                <KeyboardBackspaceRoundedIcon
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            ) : addRecordInspection ? (
              <>
                <IconButton onClick={() => handleBackButton()}>
                  <KeyboardBackspaceRoundedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </IconButton>
              </>
            ) : null
          }
        />
        {loading ? (
          <Box
            sx={{
              height: 180,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",

              mr: "166px",
            }}
          >
            <Loader />
          </Box>
        ) : (
          <Box component="div" sx={{ my: 3, mx: 3 }}>
            <Typography sx={{ ...recordInspectionTypo, textAlign: "center" }}>
              Permit No: {application?.permitNumber}
            </Typography>

            <Grid container spacing={2} sx={{ mt: 1, wordWrap: "break-word" }}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Typography sx={recordInspectionTypo}>Project Type</Typography>
                <Typography sx={recordInspectionTypo} variant="subText">
                  {
                    projectTypeList.find(
                      (project) => project.id === application?.projectType
                    )?.name
                  }
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Typography sx={recordInspectionTypo}>
                  Project Address
                </Typography>
                <Typography sx={recordInspectionTypo} variant="subText">
                  {application?.projectAddress}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Typography sx={recordInspectionTypo}>
                  Applicant Name
                </Typography>
                <Typography sx={recordInspectionTypo} variant="subText">
                  {application?.applicantName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Typography sx={recordInspectionTypo}>
                  Applicant Email
                </Typography>
                <Typography sx={recordInspectionTypo} variant="subText">
                  {application?.applicantEmail}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        {/* table section */}
        <hr
          style={{
            borderTop: "1px solid #E7E7E7",
          }}
        />

        {addRecordInspection ? (
          <AddRecordInspection
            manipulationPermission={manipulationPermission}
            handleBackButton={handleBackButton}
          />
        ) : !recordInspectionDetails ? (
          <RecordInspectionTable
            loading={loading}
            manipulationPermission={manipulationPermission}
            inspectionsList={inspectionsList}
            handleRecordInspection={handleRecordInspection}
            handleAddNewRecord={handleAddNewRecord}
          />
        ) : (
          <RecordInspectionDetails
            selectedRecord={selectedRecord}
            manipulationPermission={manipulationPermission}
            handleRecordInspection={handleRecordInspection}
          />
        )}
        {/* <Grid
          container
          sx={{
            padding: "20px",
            display: "flex",
            justifyContent: "flex-end",
            gap: "20px",
          }}
        >
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Button
              sx={{
                width: "286px",
                height: "46px",
                fontFamily: "Poppins-Bold",
                backgroundColor: "#32363F",
                borderRadius: "10px",
                color: "white",
                [`@media screen and (max-width: 1024px)`]: {
                  width: "100%",
                },
                "&:hover": {
                  backgroundColor: "black",
                  boxShadow: 2,
                  transition: ".7s ease-in",
                },
              }}
              onClick={handleSendEmailToApplicant}
            >
              Email to Applicant
              {emailButtonLoader ? <CircularProgressLoader /> : ""}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            {disablePdfButton ? (
              <Button
                // disabled={!pdfButtonEnable}
                variant="contained"
                sx={{
                  width: "100%",
                  height: "46px",
                  fontFamily: "Poppins-Bold",
                  borderRadius: "10px",
                  color: "white",
                  [`@media screen and (max-width: 1024px)`]: {
                    width: "100%",
                  },
                  backgroundColor: "#32363F",
                  "&:hover": {
                    backgroundColor: "black",
                    boxShadow: 2,
                    transition: ".7s ease-in",
                  },
                }}
                onClick={() => {
                  fireToasterContext.fireToasterHandler(
                    false,
                    "Please generate atleast one record before"
                  );
                }}
              >
                Download Occupancy letter
              </Button>
            ) : (
              <PDFDownloadLink
                document={<RecordInspectionPdf data={inspectionsList} />}
                fileName={`Record-Inspection-${application?.id}-${Date.now()}`}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    height: "46px",
                    fontFamily: "Poppins-Bold",
                    backgroundColor: "#32363F",
                    borderRadius: "10px",
                    color: "white",
                    [`@media screen and (max-width: 1024px)`]: {
                      width: "100%",
                    },
                    "&:hover": {
                      backgroundColor: "black",
                      boxShadow: 2,
                      transition: ".7s ease-in",
                    },
                  }}
                >
                  Download Occupancy letter
                </Button>
              </PDFDownloadLink>
            )}
          </Grid>
        </Grid> */}
        {!addRecordInspection && !recordInspectionDetails && (
          <Box
            sx={{
              my: 2,
              mx: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "10px",
              "& > :not(:first-of-type)": {
                m: 0,
              },
              flexWrap: "wrap",
              [`@media screen and (max-width: 1024px)`]: {
                justifyContent: "center",
              },
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "286px",
                height: "46px",
                fontFamily: "Poppins-Bold",
                backgroundColor: "#32363F",
                borderRadius: "10px",
                color: "white",
                [`@media screen and (max-width: 1024px)`]: {
                  width: "100%",
                },
                "&:hover": {
                  backgroundColor: "black",
                  boxShadow: 2,
                  transition: ".7s ease-in",
                },
                ":disabled": { backgroundColor: "#32363F", color: "white" },
              }}
              disabled={manipulationPermission}
              onClick={() => {
                handleSendEmailToApplicant();
              }}
            >
              Email to Applicant
              {emailButtonLoader ? <CircularProgressLoader /> : ""}
            </Button>

            {disablePdfButton ? (
              <PDFDownloadLink
                document={<RecordInspectionPdf data={inspectionsList} />}
                fileName={`Record-Inspection-${application?.id}-${Date.now()}`}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "286px",
                    height: "46px",
                    fontFamily: "Poppins-Bold",
                    backgroundColor: "#32363F",
                    borderRadius: "10px",
                    color: "white",
                    [`@media screen and (max-width: 1024px)`]: {
                      width: "100%",
                    },
                    "&:hover": {
                      backgroundColor: "black",
                      boxShadow: 2,
                      transition: ".7s ease-in",
                    },
                  }}
                >
                  Download Inspection Report
                </Button>
              </PDFDownloadLink>
            ) : (
              <Button
                disabled={manipulationPermission}
                variant="contained"
                sx={{
                  width: "286px",
                  height: "46px",
                  fontFamily: "Poppins-Bold",
                  backgroundColor: "#32363F",
                  borderRadius: "10px",
                  color: "white",
                  [`@media screen and (max-width: 1024px)`]: {
                    width: "100%",
                  },
                  "&:hover": {
                    backgroundColor: "black",
                    boxShadow: 2,
                    transition: ".7s ease-in",
                  },
                  "&:disabled": { backgroundColor: "#32363F", color: "white" },
                }}
                onClick={() =>
                  fireToasterContext.fireToasterHandler(
                    false,
                    "Please create atleast one record inspection against this application!"
                  )
                }
              >
                Download Inspection Report
              </Button>
            )}
          </Box>
        )}
      </Card>
    </div>
  );
};

export default RecordInspection;
