import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../redux/store";
import { getApplicantsData } from "../../redux/slices/dashboard/applicantsSlice";
import CustomTable from "../customTable/Index";
import { Box } from "@mui/material";
const tableHeader = [
  {
    id: 1,
    name: "Permit Date",
  },
  { id: 2, name: "Municipality" },
  { id: 3, name: "Job Address", width: "250px" },
  { id: 4, name: "Job Description" },
  { id: 5, name: "Permit Fee" },
  { id: 6, name: "Payment Status" },
  { id: 7, name: "Application Status" },
];
const Index = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dispatch(getApplicantsData(handleApiResponse));
  }, []);

  let { applicants } = useSelector((state) => state.applicantsReducer);
  const handleApiResponse = (data) => {
    if (data.status) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <Box>
      <CustomTable
        tableHeader={tableHeader}
        bodyData={applicants}
        loading={loading}
      />
    </Box>
  );
};

export default Index;
