import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { dispatch } from "../store";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};
const initialState = {
  contractorList: [],
  contractorForListingData: [],
};

const slice = createSlice({
  name: "contractor",
  initialState,
  reducers: {
    getContractorList(state, action) {
      state.contractorList = action.payload.data;
    },
    getContractorsForListing(state, action) {
      state.contractorForListingData = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getAllContractors(handleApiResponse) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/contractors`,
        { headers }
      );
      if (handleApiResponse) {
        handleApiResponse(response.data);
      }
      dispatch(slice.actions.getContractorList(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getAllContractorsForListing(handleApiResponse) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/contractors?temporary=1`,
        { headers }
      );
      if (handleApiResponse) {
        handleApiResponse(response.data);
      }
      dispatch(slice.actions.getContractorsForListing(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function deleteContractor(id, handleApiRes) {
  return async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST_API_KEY}v1/contractors?id=${id}`,
        { headers }
      );

      if (handleApiRes) {
        handleApiRes(response.data);
      }
      dispatch(getAllContractors());
    } catch (error) {
      // if (handleApiRes) {
      handleApiRes(error.response.data);
      // }
    }
  };
}

export function createContractor(data, handleApiResponse) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/contractors`,
        data
      );
      handleApiResponse(response.data);
    } catch (error) {
      console.log(error);
      if (handleApiResponse) {
        handleApiResponse(error?.response?.data);
      }
    }
  };
}

export function updateContractor(data, handleApiResponse) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/contractors?id=${data.id}`,
        data,
        { headers }
      );
      handleApiResponse(response.data);
      dispatch(getAllContractors());
    } catch (error) {
      console.log(error);
      handleApiResponse(error.response.data.message);
    }
  };
}
