import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../../store";
import axios from "axios";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};
const initialState = {
  applicants: [],
  application: {},
  applicationPdfData: {},
  applicationStatus: {},
};

const slice = createSlice({
  name: "applicants",
  initialState,
  reducers: {
    getAllApplicants(state, action) {
      state.applicants = action.payload.data;
    },

    getSingleApplication(state, action) {
      state.application = action.payload;
    },

    getApplicationPdfData(state, action) {
      state.applicationPdfData = action.payload;
    },
    applicationStatus(state, action) {
      state.applicationStatus = action.payload;
    },
  },
});

export default slice.reducer;

export function getApplicantsData(handleApiResponse) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/applications`,
        { headers }
      );

      if (handleApiResponse) {
        handleApiResponse(response.data);
      }
      dispatch(slice.actions.getAllApplicants(response.data));
    } catch (error) {
      console.log("Error ", error);
      if (handleApiResponse) {
        handleApiResponse(error?.response?.data);
      }
    }
  };
}
export function getApplicantStatus(id) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/applications/application-status?applicationId=${id}`,
        { headers }
      );
      dispatch(slice.actions.applicationStatus(response.data));
    } catch (error) {
      console.log("Error ", error);
    }
  };
}

export function getSingleApplicationData(id, handleApiResponse) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/applications/${id}`
      );

      if (handleApiResponse) {
        handleApiResponse(response.data);
      }
      dispatch(slice.actions.getSingleApplication(response.data.data));
    } catch (error) {
      console.log(error);
      if (handleApiResponse) {
        handleApiResponse(error.response.data.message);
      }
    }
  };
}

export function createApplication(data, handleApiResponse) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/applications`,
        data
      );
      handleApiResponse(response.data);
    } catch (error) {
      console.log(error);
      handleApiResponse(error.response.data.message);
    }
  };
}

export function updatePermitFee(data, handleApiResponse, fees) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/applications/update-application-fee`,
        data,
        { headers }
      );
      handleApiResponse(response.data, fees);
    } catch (error) {
      console.log(error);
      handleApiResponse(error.response.data.message);
    }
  };
}

export function updatePaymentMethod(data, handleApiResponse, fees) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/applications/change-payment-method?id=${data.id}`,
        data,
        { headers }
      );
      handleApiResponse(response.data, fees);
    } catch (error) {
      console.log(error);
      handleApiResponse(error.response.data);
    }
  };
}

export function uploadRecordInspectionMedia(id, array, handleApiResponse) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/applications/add-application-media?recordedInspectionId=${id}`,
        {
          data: array,
        }
      );

      if (handleApiResponse) {
        handleApiResponse(response.data);
      }
    } catch (error) {
      console.log(error);
      if (handleApiResponse) {
        handleApiResponse(error.response.data.message);
      }
    }
  };
}

export function uploadApplicationMedia(
  applicationId,
  array,
  handleApiResponse
) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/applications/add-application-media?applicationId=${applicationId}`,
        {
          data: array,
        }
      );

      if (handleApiResponse) {
        handleApiResponse(response.data);
      }
    } catch (error) {
      console.log(error);
      if (handleApiResponse) {
        handleApiResponse(error.response.data.message);
      }
    }
  };
}

export function updateApplicationDetail(
  data,
  handleApiResponse,
  submitToState
) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/applications?id=${data.id}`,
        data,
        { headers }
      );
      dispatch(getSingleApplicationData(response?.data?.data?.id));
      dispatch(getApplicationPdf());
      handleApiResponse(response.data, submitToState);
    } catch (error) {
      console.log(error);
      handleApiResponse(error?.response?.data);
    }
  };
}

export function submitDataToState(data, handleApiResponse) {
  return async () => {
    try {
      const submitToStateApiResponse = await axios.post(
        `https://statuesque-lebkuchen-e7998e.netlify.app/.netlify/functions/api/state`,
        data,
        { headers }
      );

      console.log("submitToStateApiResponse", submitToStateApiResponse);
      if (!submitToStateApiResponse?.data?.data?.disCode) {
        handleApiResponse(
          false,
          submitToStateApiResponse?.data?.message
            ? submitToStateApiResponse?.data?.message
            : "Something went wrong"
        );
        return;
      }

      await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/applications/submit-to-state`,
        {
          applicationId: data.applicationId,
          submitted: true,
        },
        { headers }
      );

      if (handleApiResponse) {
        handleApiResponse(
          true,
          submitToStateApiResponse?.data?.message
            ? submitToStateApiResponse?.data?.message
            : "Successfully Submitted to State"
        );
      }
    } catch (error) {
      console.log(error);
      handleApiResponse(
        false,
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message
          ? error?.message
          : "Something went wrong"
      );
    }
  };
}

export function updateContractorApplicationSlice(data, handleApiResponse) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/contractors?id=${data.id}`,
        data,
        { headers }
      );
      handleApiResponse(response.data);
      // dispatch(getSingleApplicationData(data.applicationId));
    } catch (error) {
      console.log(error);
      handleApiResponse(error.response.data.message);
    }
  };
}

export function getApplicationPdf(handleApiResponse) {
  let applicationId = JSON.parse(
    localStorage.getItem("selectedApplication")
  ).id;
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/applications/application-pdf?id=${applicationId}`,
        { headers }
      );

      dispatch(slice.actions.getApplicationPdfData(response.data.data));

      if (handleApiResponse) {
        handleApiResponse(response.data);
      }

      // handleApiResponse(response.data);
    } catch (error) {
      console.log(error);
      // if (handleApiResponse) {
      //   handleApiResponse(error?.response?.data);
      // }
    }
  };
}

export function sendEmailToApplicant(data, handleApiResponse) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/applications/send-pdf-emails`,
        data,
        { headers }
      );
      handleApiResponse(response.data);
    } catch (error) {
      console.log(error);
      handleApiResponse(error.response.data.message);
    }
  };
}

export function deleteApplication(id, handleApiRes) {
  return async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST_API_KEY}v1/applications?id=${id}`,
        { headers }
      );
      handleApiRes(response.data);
      dispatch(getApplicantsData());
    } catch (error) {
      handleApiRes(error.response.data);
      console.log(error);
    }
  };
}
