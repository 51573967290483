// muncipalities

import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import axios from "axios";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
  timezoneutcoffset: new Date().getTimezoneOffset(),
};
const initialState = {
  resports: [],
};

const slice = createSlice({
  name: "resports",
  initialState,
  reducers: {
    getAllResports(state, action) {
      state.resports = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getResportsData(handleApiResponse) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/reports/monthly-reports`,
        { headers }
      );

      if (handleApiResponse) {
        handleApiResponse(response.data);
      }
      dispatch(slice.actions.getAllResports(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}
