import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { dispatch } from "../store";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};
const initialState = {
  occupancyLetterList: {},
};

const slice = createSlice({
  name: "occupancyLetter",
  initialState,
  reducers: {
    getOccupancyLetterList(state, action) {
      state.occupancyLetterList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getOccupancyLetter(id, handleApiRes) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/occupency-letters?applicationId=${id}`,
        { headers }
      );
      dispatch(slice.actions.getOccupancyLetterList(response.data));
      if (handleApiRes) {
        handleApiRes(response.data);
      }
    } catch (error) {
      console.log(error);
      if (handleApiRes) {
        handleApiRes(error);
      }
    }
  };
}
export function createOccupancyLetter(finalObj, handleApiRes) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/occupency-letters`,
        finalObj,
        { headers }
      );

      dispatch(getOccupancyLetter(response.data.data.applicationId));
      if (handleApiRes) {
        handleApiRes(response.data);
      }
    } catch (error) {
      console.log(error);
      if (handleApiRes) {
        handleApiRes(error.response.data);
      }
    }
  };
}

export function createOccupancyPdf(pdfData, handlePdfApiRes) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/applications/create-or-update-pdf`,
        pdfData,
        { headers }
      );
      if (handlePdfApiRes) {
        handlePdfApiRes(response.data);
      }
    } catch (error) {
      console.log(error);
      if (handlePdfApiRes) {
        handlePdfApiRes(error?.response?.data);
      }
    }
  };
}
