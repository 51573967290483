import React from "react";
import { Card, Box } from "@mui/material";
import // applicationCardStyle,
// recordInspectionTypo,
"../../../utils/muiComponentStyles";
import PermitFeeTable from "./PermitFeeTable";
// import Loader from "../../Loader/Loader";

// const projectTypeList = [
//   { id: 1, name: "New Building" },
//   { id: 2, name: "Addition" },
//   { id: 3, name: "Alteration/Repair" },
//   { id: 4, name: "Deck" },
//   { id: 5, name: "Demolition" },
//   { id: 6, name: "Driveway" },
//   { id: 7, name: "Fence" },
//   { id: 8, name: "Pool" },
// ];

const Index = () => {
  // let application = JSON.parse(localStorage.getItem("selectedApplication"));
  // const [loading, setLoading] = useState(true);
  // const [applicationStatus, setApplicationStatus] = useState(
  //   JSON.parse(localStorage.getItem("selectedApplication")).status
  // );
  // const [applicationPermitFee, setApplicationPermitFee] = useState(
  //   JSON.parse(localStorage.getItem("selectedApplication")).totalAmount
  // );
  // const [apiResponse, setApiResponse] = useState();
  // useEffect(() => {
  //   // setLoading(false);
  //   const status = JSON.parse(
  //     localStorage.getItem("selectedApplication")
  //   ).status;
  //   const permitFee = JSON.parse(
  //     localStorage.getItem("selectedApplication")
  //   ).totalAmount;
  //   if (status != null) {
  //     setApplicationStatus(status);
  //   }
  //   if (permitFee) {
  //     setApplicationPermitFee(permitFee);
  //   }
  // }, [apiResponse]);

  // const getApiResponse = (data) => {
  //   setApiResponse(data);
  // };
  return (
    <Box>
      <Card>
        {/* <CardHeader
          disableTypography
          title={
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>Permit Fee Detail</Box>
              <Box>
                Status: {applicationStatus} | Permit Fee:
                {(applicationPermitFee * 1)?.toFixed(2)}
              </Box>
            </Box>
          }
          sx={applicationCardStyle}
        /> */}
        {/* {loading ? (
          <Box
            sx={{
              height: 180,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              mr: "88px",
            }}
          >
            <Loader />
          </Box>
        ) : (
          <Box component="div" sx={{ my: 3, mx: 3 }}>
            <Typography sx={{ ...recordInspectionTypo, textAlign: "center" }}>
              Permit No: {application?.permitNumber}
            </Typography>

            <Grid container spacing={2} sx={{ mt: 1, wordWrap: "break-word" }}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Typography sx={recordInspectionTypo}>Project Type</Typography>
                <Typography sx={recordInspectionTypo} variant="subText">
                  {
                    projectTypeList.find(
                      (project) => project.id === application?.projectType
                    )?.name
                  }
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Typography sx={recordInspectionTypo}>
                  Project Address
                </Typography>
                <Typography sx={recordInspectionTypo} variant="subText">
                  {application?.projectAddress}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Typography sx={recordInspectionTypo}>
                  Applicant Name
                </Typography>
                <Typography sx={recordInspectionTypo} variant="subText">
                  {application?.applicantName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Typography sx={recordInspectionTypo}>
                  Applicant Email
                </Typography>
                <Typography sx={recordInspectionTypo} variant="subText">
                  {application?.applicantEmail}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )} */}
        <PermitFeeTable />
      </Card>
    </Box>
  );
};

export default Index;
