import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import loginSliceReducer from "./slices/LoginSlice";
import dashboardSliceReducer from "./slices/dashboard/dashboardSlice";
import applicantsSliceRducer from "./slices/dashboard/applicantsSlice";
import filterSliceRducer from "./slices/dashboard/dashboardFilterSlice";
import userSliceReducer from "./slices/UserSlice";
import resportsSliceReducer from "./slices/resportsSlice";
import recordedInspectionsSliceReducer from "./slices/recordedInspectionsSlice";
import roleSliceReducer from "./slices/RoleSlice";
import permissionSliceReducer from "./slices/permissionSlice";
import muncipalitySliceReducer from "./slices/muncipalitySlice";
import contractorSliceReducer from "./slices/contractorSlice";
import occupancyLetterSliceReducer from "./slices/OccupancyLetterSlice";
import BuildingPlacardSliceReducer from "./slices/BuildingPlacardSlice";
import PdfSliceReducer from "./slices/PdfDataSlice";
import EmailTrackSliceReducer from "./slices/EmailTrackSlice";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  loginReducer: loginSliceReducer,
  dashboardReducer: dashboardSliceReducer,
  filtersReducer: filterSliceRducer,
  applicantsReducer: applicantsSliceRducer,
  userReducer: userSliceReducer,
  resportsReducer: resportsSliceReducer,
  roleReducer: roleSliceReducer,
  recordedInspectionsReducer: recordedInspectionsSliceReducer,
  permissionReducer: permissionSliceReducer,
  muncipalityReducer: muncipalitySliceReducer,
  contractorReducer: contractorSliceReducer,
  occupancyLetterReducer: occupancyLetterSliceReducer,
  buildingPlacardReducer: BuildingPlacardSliceReducer,
  pdfSliceReducer: PdfSliceReducer,
  emailTrackReducer: EmailTrackSliceReducer,
});

export { rootPersistConfig, rootReducer };
