import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { NavLink } from "react-router-dom";
import {
  sidebarDrawer,
  leftbarStyles,
  sidebarSubMenuListItemIcon,
  sidebarSubMenuTypography,
  sidebarSubMenuListItemIconWithPadding,
  rightSidebarListItemButton_Roles,
  rightSidebarListItemButton,
} from "../utils/muiComponentStyles";
import useLocales from "../hooks/useLocales";

const RightSideSubMenu = ({ sidebarData }) => {
  const { translate } = useLocales();
  const classes = leftbarStyles();
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);

  return (
    <div
      style={{ textDecoration: "none" }}
      className={{ paper: classes.drawerPaper }}
    >
      <ListItem
        disablePadding
        sx={{
          "&.MuiListItem-root": {
            color: "white",
            borderRadius: "5px",
            "&.active": {
              // backgroundColor: "#DE2C34",
              borderRadius: "15px",
              textDecoration: "none",
              "& svg": {
                color: "white",
              },
              "& .MuiListItemText-root": {
                color: "white",
                fontFamily: "SofiaProBold",
                fontSize: "15px",
                lineHeight: "50px",
                fontWeight: 700,
              },
            },
          },
        }}
      >
        <ListItemButton
          onClick={sidebarData.children && showSubnav}
          disableRipple
          to={sidebarData.path}
          component={NavLink}
          sx={
            sidebarData.children
              ? rightSidebarListItemButton_Roles
              : rightSidebarListItemButton
          }
        >
          <ListItemIcon
            sx={{
              ...sidebarSubMenuListItemIcon,
              color:
                location.pathname === sidebarData.path
                  ? "#D52027"
                  : sidebarData.color,
            }}
          >
            {sidebarData.icon}
          </ListItemIcon>
          <Typography
            sx={{
              ...sidebarDrawer,
              color:
                location.pathname === sidebarData.path
                  ? "#D52027"
                  : sidebarData.color,
            }}
            variant="rightSidebarTypography"
          >
            {translate(sidebarData.sidebarName)}
          </Typography>
          {sidebarData.children && (
            <Typography sx={sidebarSubMenuTypography}>
              {sidebarData.children && subnav
                ? sidebarData.iconClosed
                : sidebarData.children
                ? sidebarData.iconOpened
                : null}
            </Typography>
          )}
        </ListItemButton>
      </ListItem>

      {/* sub menu */}
      {subnav &&
        sidebarData.children.map((item, index) => {
          return (
            <ListItem key={index} disablePadding>
              <ListItemButton
                disableRipple
                to={item.path}
                component={NavLink}
                sx={{
                  "&.MuiListItemButton-root": {
                    textDecoration: "none",
                    borderRadius: "10px",
                    width: 200,
                    maxHeight: 50,
                    padding: 1,
                    margin: "2px",
                    marginLeft: sidebarData.children ? "20px" : "10px",
                    marginRight: "10px",
                    fontFamily: "Poppins",
                    color: "#040F25",
                  },
                  "&:hover": {
                    // backgroundColor: "background.bgSidebarHover",
                    borderRadius: "10px",
                    // transition: "background 600ms linear",
                  },
                  "&.active": {
                    fontWeight: 1000,
                    // backgroundColor: "primary.main",
                    color: "red",
                    // transition: "background 600ms linear",
                    fontFamily: "Poppins-SemiBold",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    ...sidebarSubMenuListItemIconWithPadding,
                    color:
                      location.pathname === item.path ? "#FFFFFF" : "#040F25",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <Typography sx={sidebarDrawer} variant="rightSidebarTypography">
                  {translate(item.sidebarName)}
                </Typography>
              </ListItemButton>
            </ListItem>
          );
        })}
    </div>
  );
};

export default RightSideSubMenu;
