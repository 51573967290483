import React, { useContext, useState } from "react";
import {
  Container,
  Typography,
  DialogActions,
  Button,
  DialogContent,
  Box,
} from "@mui/material";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  dialogCancelButton,
  userHeading,
} from "../../../utils/muiComponentStyles";
import { useDispatch } from "../../../redux/store";
import toasterContext from "../../../utils/context/toasterContext";
import CircularProgressLoader from "../../../utils/CircularProgressLoader";
import { deleteInspection } from "../../../redux/slices/recordedInspectionsSlice";

const Index = ({ openDeleteDialog, selectedRecord, handleCloseDialog }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const fireToasterContext = useContext(toasterContext);

  const handleRecordInspection = () => {
    setLoader(true);
    dispatch(deleteInspection(selectedRecord.id, handleApiRes));
  };

  const handleApiRes = (data) => {
    setLoader(false);

    if (data.status) {
      handleCloseDialog();
      fireToasterContext.fireToasterHandler(true, data.message);
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
      handleCloseDialog();
    }
  };

  return (
    <Container>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDeleteDialog}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography sx={userHeading} variant="userTableHeadingBold">
            Delete Inspection Report
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent
          sx={{
            visibility: "hidden",
          }}
        >
          <Box paddingTop={4}>
            <Typography
              variant="caption"
              sx={{ fontFamily: "Poppins", fontSize: "16px" }}
            >
              Are You Sure, You Want To Delete this{" "}
              <span
                style={{
                  fontFamily: "Poppins-Bold",
                  textTransform: "capitalize",
                }}
              >
                {`${selectedRecord?.application?.applicantName}`}
              </span>
              ?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            sx={dialogCancelButton}
            autoFocus
            onClick={handleCloseDialog}
          >
            cancel
          </Button>
          <Button
            type="submit"
            onClick={handleRecordInspection}
            sx={
              loader
                ? {
                    color: "white",
                    fontFamily: "Poppins-semibold",
                    fontSize: "15px",
                    background: "#979797",
                    borderRadius: "10px",
                    "&:hover": { backgroundColor: "#979797" },
                    "&:disabled": {
                      backgroundColor: "#979797",
                    },
                  }
                : {
                    backgroundColor: "background.bgLayoutB",
                    color: "white",
                    fontFamily: "Poppins-semibold",
                    fontSize: "15px",
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: "red",
                      boxShadow: 2,
                      transition: ".7s ease-in",
                    },
                    "&:disabled": {
                      backgroundColor: "#979797",
                    },
                  }
            }
          >
            Delete Record
            {loader ? <CircularProgressLoader /> : ""}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Container>
  );
};

export default Index;
