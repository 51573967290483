import React from "react";
import ApplicationTable from "./applicationTable";
import CardList from "./CardList";
const index = () => {
  const permissions = localStorage.getItem("permissions");
  let permissionArray = permissions?.length > 0 ? permissions.split(",") : [];

  return (
    <div>
      <CardList />
      {permissionArray.includes("show applications") && <ApplicationTable />}
    </div>
  );
};

export default index;
