import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../../store";
import axios from "axios";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
  timezoneutcoffset: new Date().getTimezoneOffset(),
};
const initialState = {
  dashboardStatsData: [],
};

const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    getDashboardStats(state, action) {
      state.dashboardStatsData = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getDashboardStatsData(handleApiResponse) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/dashboard/stats`,
        { headers }
      );

      if (handleApiResponse) {
        handleApiResponse(response.data);
      }
      dispatch(slice.actions.getDashboardStats(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}
