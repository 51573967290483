import React from "react";
import {
  Font,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
// import data from "./ApplicationDetailFormPdfData.json";
import poppinsRegular from "../../../assets/fonts/Poppins-Regular.ttf";
import poppinsBold from "../../../assets/fonts/Poppins-Bold.ttf";
import poppinsMedium from "../../../assets/fonts/Poppins-SemiBold.ttf";
import logo from "../../../assets/icons/logo.png";
import checkboxFilled from "../../../assets/pdf-checkbox-show/check-box-filled.png";
import checkboxOutlined from "../../../assets/pdf-checkbox-show/check-box-outlined.png";

Font.register({
  family: "PoppinsFamily",
  fonts: [
    {
      src: poppinsRegular,
    },
    {
      src: poppinsBold,
      fontWeight: "bold",
    },
    { src: poppinsMedium, fontWeight: "semibold" },
  ],
});

const projectTypeList = [
  { id: 1, name: "New Building" },
  { id: 2, name: "Addition" },
  { id: 3, name: "Alteration/Repair" },
  { id: 4, name: "Deck" },
  { id: 5, name: "Demolition" },
  { id: 6, name: "Driveway" },
  { id: 7, name: "Fence" },
  { id: 8, name: "Pool" },
];

const buildingTypeList = [
  { id: 1, name: "Single Family" },
  { id: 2, name: "Duplex" },
  { id: 3, name: "Multi-Family" },
  { id: 4, name: "Commercial-Industrial" },
  { id: 5, name: "Garage/Shed" },
  { id: 6, name: "Does not apply" },
];

const permitRequestedArr = [
  { id: 1, name: "Construction" },
  { id: 2, name: "HVAC" },
  { id: 3, name: "Electric" },
  { id: 4, name: "Plumbing" },
  { id: 5, name: "Erosion Control" },
  { id: 6, name: "Deck" },
  { id: 7, name: "Fence" },
  { id: 8, name: "Driveway" },
  { id: 9, name: "Pool" },
  { id: 10, name: "Other" },
];

// 1,6,7,8,9,10 constraction cost

const personSubmittingApplication = [
  {
    id: 1,
    name: "Contractor",
  },
  {
    id: 2,
    name: "Owner",
  },
];

// Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    // paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 10,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  mainViewTag: {
    margin: "30px",
  },
  heading: {
    fontFamily: "PoppinsFamily",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#000000",
    margin: "20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  muncipality: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    fontFamily: "PoppinsFamily",
    gap: "15px",
    fontSize: "12px",
  },
  titleHeading: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    fontFamily: "PoppinsFamily",
    marginTop: "20px",
    gap: "15px",
    fontSize: "14px",
    borderBottom: "1px solid black",
  },
  titleSubPart: {
    flexDirection: "row",
    display: "flex",
    fontFamily: "PoppinsFamily",
    gap: "15px",
    fontSize: "10px",
    width: "100%",
    marginTop: "10px",
  },
  titleSubPart1stView: {
    width: "33%",
  },
  titleSubPartLastView: {
    width: "34%",
  },
  checkboxImageStyle: {
    width: "20px",
    height: "20px",
  },
  checkBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "7px",
    fontSize: "16px",
    marginTop: "10px",
  },
});

// Create Document Component
const ApplicationDetailFormPdf = ({ data }) => {
  // const data = JSON.parse(localStorage.getItem("PdfApplication"));
  let dwelling = "Dwelling";
  let general = "General";
  function permitArrayFunction(permitArray) {
    const map1 = permitRequestedArr?.filter((x) =>
      permitArray?.find((arr) => {
        if (arr === x.id) {
          return x.name;
        }
      })
    );
    return map1.map((permit) => permit.name).join(", ");
  }

  const constructionCostItem = permitRequestedArr.find((el) =>
    [1, 6, 7, 8, 9, 10].includes(el.id)
  );
  return (
    <Document pageMode="fullScreen" pageLayout="oneColumn">
      <Page size="A4" style={styles.page}>
        <View style={styles.mainViewTag}>
          <View style={styles.heading} fixed>
            <Image src={logo} style={{ height: "50px", width: "70px" }} />
            <Text>Application Detail </Text>
          </View>

          {/* muncipality value */}
          <View style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <View style={styles.muncipality}>
              <Text
                style={{ fontFamily: "PoppinsFamily", fontWeight: "semibold" }}
              >
                Municipality:
              </Text>
              <Text style={{ fontFamily: "PoppinsFamily" }}>
                {data?.muncipalityName}
              </Text>
            </View>
            <View style={styles.muncipality}>
              <Text
                style={{ fontFamily: "PoppinsFamily", fontWeight: "semibold" }}
              >
                Status:
              </Text>
              <Text style={{ fontFamily: "PoppinsFamily" }}>
                {data?.status}
              </Text>
            </View>
          </View>

          {/* project address */}
          <View style={styles.titleHeading}>
            <Text
              style={{ fontFamily: "PoppinsFamily", fontWeight: "semibold" }}
            >
              Project Information
            </Text>
          </View>
          <View style={styles.titleSubPart}>
            <View style={styles.titleSubPart1stView}>
              <Text
                style={{ fontFamily: "PoppinsFamily", fontWeight: "semibold" }}
              >
                Project Address
              </Text>
              <Text style={{ fontFamily: "PoppinsFamily" }}>
                {data?.projectAddress}
              </Text>
            </View>
            <View style={styles.titleSubPart1stView}>
              <Text
                style={{ fontFamily: "PoppinsFamily", fontWeight: "semibold" }}
              >
                Project Type
              </Text>
              <Text style={{ fontFamily: "PoppinsFamily" }}>
                {projectTypeList.map((projectType) => {
                  if (projectType.id === data?.projectType) {
                    return projectType.name;
                  }
                })}
              </Text>
            </View>
            <View style={styles.titleSubPartLastView}>
              <Text
                style={{ fontFamily: "PoppinsFamily", fontWeight: "semibold" }}
              >
                Building Type
              </Text>
              <Text style={{ fontFamily: "PoppinsFamily" }}>
                {buildingTypeList.map((buildingType) => {
                  if (buildingType.id === data?.buildingType) {
                    return buildingType.name;
                  }
                })}
              </Text>
            </View>
          </View>

          {/* Job description view  */}
          <View style={styles.titleHeading}>
            <Text
              style={{ fontFamily: "PoppinsFamily", fontWeight: "semibold" }}
            >
              Job Description
            </Text>
          </View>
          <View style={styles.titleSubPart}>
            <View style={styles.titleSubPart1stView}>
              <Text
                style={{ fontFamily: "PoppinsFamily", fontWeight: "semibold" }}
              >
                Job Description
              </Text>
              <Text style={{ fontFamily: "PoppinsFamily" }}>
                {data?.jobDescription}
              </Text>
            </View>
            <View style={styles.titleSubPart1stView}>
              <Text
                style={{ fontFamily: "PoppinsFamily", fontWeight: "semibold" }}
              >
                Total Square Feet
              </Text>
              <Text style={{ fontFamily: "PoppinsFamily" }}>
                {data?.totalSquareFeet}
              </Text>
            </View>
            <View style={styles.titleSubPartLastView}>
              <Text
                style={{ fontFamily: "PoppinsFamily", fontWeight: "semibold" }}
              >
                Permit Requested
              </Text>
              <Text style={{ fontFamily: "PoppinsFamily" }}>
                {/* {permitRequestedArr.filter((option) =>
                  data?.permitRequestedArr?.find(
                    (permitId) => option.id == permitId
                  )
                )} */}
                {permitArrayFunction(data?.permitRequested)}
              </Text>
            </View>
          </View>

          {data?.permitRequestedOtherDescription && (
            <View style={styles.titleSubPart}>
              <View style={{ width: "100%" }}>
                <Text
                  style={{
                    fontFamily: "PoppinsFamily",
                    fontWeight: "semibold",
                  }}
                >
                  Permit Requested Other Description
                </Text>
                <Text style={{ fontFamily: "PoppinsFamily" }}>
                  {data?.permitRequestedOtherDescription}
                </Text>
              </View>
            </View>
          )}

          {/* Cost view  */}
          <View style={styles.titleHeading}>
            <Text
              style={{
                fontFamily: "PoppinsFamily",
                fontWeight: "semibold",
                marginTop: "10px",
              }}
            >
              Cost
            </Text>
          </View>
          <View>
            <Text style={styles.titleSubPart}>Cost = Material + Labor</Text>
          </View>
          <View style={styles.titleSubPart}>
            <View style={styles.titleSubPart}>
              {constructionCostItem && (
                <View style={{ width: "25%" }}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Construction Cost
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.constructionCost}
                  </Text>
                </View>
              )}
              {data?.permitRequested?.includes(3) && (
                <View style={{ width: "25%" }}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Electrical Cost
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.electricalCost}
                  </Text>
                </View>
              )}
              {data?.permitRequested?.includes(4) && (
                <View style={{ width: "25%" }}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Plumber Cost
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.plumbingCost}
                  </Text>
                </View>
              )}
              {data?.permitRequested?.includes(2) && (
                <View style={{ width: "25%" }}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    HVAC Cost
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.hvacCost}
                  </Text>
                </View>
              )}
            </View>
          </View>

          {/* Owner information view  */}
          <View style={styles.titleHeading}>
            <Text
              style={{
                fontFamily: "PoppinsFamily",
                fontWeight: "semibold",
              }}
            >
              Owner Information
            </Text>
          </View>
          <View style={styles.titleSubPart}>
            <View style={{ width: "25%" }}>
              <Text
                style={{ fontFamily: "PoppinsFamily", fontWeight: "semibold" }}
              >
                Name
              </Text>
              <Text style={{ fontFamily: "PoppinsFamily" }}>
                {data?.ownerName}
              </Text>
            </View>
            <View style={{ width: "25%" }}>
              <Text
                style={{ fontFamily: "PoppinsFamily", fontWeight: "semibold" }}
              >
                Phone Number
              </Text>
              <Text style={{ fontFamily: "PoppinsFamily" }}>
                {data?.ownerPhoneNumber}
              </Text>
            </View>
            <View style={{ width: "25%" }}>
              <Text
                style={{ fontFamily: "PoppinsFamily", fontWeight: "semibold" }}
              >
                Address
              </Text>
              <Text style={{ fontFamily: "PoppinsFamily" }}>
                {data?.ownerAddress}
              </Text>
            </View>
            <View style={{ width: "25%" }}>
              <Text
                style={{ fontFamily: "PoppinsFamily", fontWeight: "semibold" }}
              >
                Zip Code
              </Text>
              <Text style={{ fontFamily: "PoppinsFamily" }}>
                {data?.ownerZip}
              </Text>
            </View>
          </View>
          <View style={styles.titleSubPart}>
            <View style={{ width: "25%" }}>
              <Text
                style={{ fontFamily: "PoppinsFamily", fontWeight: "semibold" }}
              >
                City
              </Text>
              <Text style={{ fontFamily: "PoppinsFamily" }}>
                {data?.ownerCity}
              </Text>
            </View>
            <View style={{ width: "25%" }}>
              <Text
                style={{ fontFamily: "PoppinsFamily", fontWeight: "semibold" }}
              >
                State
              </Text>
              <Text style={{ fontFamily: "PoppinsFamily" }}>
                {data?.ownerState}
              </Text>
            </View>
            <View style={{ width: "25%" }}>
              <Text
                style={{ fontFamily: "PoppinsFamily", fontWeight: "semibold" }}
              >
                Email
              </Text>
              <Text style={{ fontFamily: "PoppinsFamily" }}>
                {data?.ownerEmail}
              </Text>
            </View>
            <View style={{ width: "25%" }}></View>
            {/* <View style={styles.titleSubPartLastView} /> */}
          </View>

          {/* Dwell contractor information view  */}
          {data?.dwellingContractorByOwner && (
            <View style={styles.checkBox}>
              {data?.dwellingContractorByOwner ? (
                <Image src={checkboxFilled} style={styles.checkboxImageStyle} />
              ) : (
                <Image
                  src={checkboxOutlined}
                  style={styles.checkboxImageStyle}
                />
              )}
              <Text>
                {data?.buildingType < 3 && data?.projectType < 4
                  ? dwelling
                  : (data?.buildingType > 2 && data?.projectType) ||
                    (data?.buildingType < 3 && data?.projectType > 4)
                  ? general
                  : dwelling}
              </Text>
            </View>
          )}
          {data?.dwellingContractorId && (
            <View>
              {data?.dwellingContractor?.type === "General" ? (
                <>
                  <View style={styles.titleHeading}>
                    <Text
                      style={{
                        fontFamily: "PoppinsFamily",
                        fontWeight: "semibold",
                      }}
                    >
                      General Contractor Information
                    </Text>
                  </View>

                  <View style={styles.titleSubPart}>
                    <View style={styles.titleSubPart1stView}>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        Name
                      </Text>
                      <Text style={{ fontFamily: "PoppinsFamily" }}>
                        {data?.dwellingContractor?.name}
                      </Text>
                    </View>
                    <View style={styles.titleSubPart1stView}>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        Company Name
                      </Text>
                      <Text style={{ fontFamily: "PoppinsFamily" }}>
                        {data?.dwellingContractor?.companyName}
                      </Text>
                    </View>
                    <View style={styles.titleSubPart1stView}>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        Phone Number
                      </Text>
                      <Text style={{ fontFamily: "PoppinsFamily" }}>
                        {data?.dwellingContractor?.phoneNumber}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.titleSubPart}>
                    <View>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        Email
                      </Text>
                      <Text style={{ fontFamily: "PoppinsFamily" }}>
                        {data?.dwellingContractor?.email}
                      </Text>
                    </View>
                  </View>
                </>
              ) : (
                <>
                  <View style={styles.titleHeading}>
                    <Text
                      style={{
                        fontFamily: "PoppinsFamily",
                        fontWeight: "semibold",
                      }}
                    >
                      Dwelling Contractor Information
                    </Text>
                  </View>

                  <View style={styles.titleSubPart}>
                    <View style={styles.titleSubPart1stView}>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        Name
                      </Text>
                      <Text style={{ fontFamily: "PoppinsFamily" }}>
                        {data?.dwellingContractor?.name}
                      </Text>
                    </View>
                    <View style={styles.titleSubPart1stView}>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        Company Name
                      </Text>
                      <Text style={{ fontFamily: "PoppinsFamily" }}>
                        {data?.dwellingContractor?.companyName}
                      </Text>
                    </View>
                    <View style={styles.titleSubPartLastView}>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        Email
                      </Text>
                      <Text style={{ fontFamily: "PoppinsFamily" }}>
                        {data?.dwellingContractor?.email}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.titleSubPart}>
                    <View style={styles.titleSubPart1stView}>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        City
                      </Text>
                      <Text>{data?.dwellingContractor?.city}</Text>
                    </View>
                    <View style={styles.titleSubPart1stView}>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        Zip
                      </Text>
                      <Text style={{ fontFamily: "PoppinsFamily" }}>
                        {data?.dwellingContractor?.zip}
                      </Text>
                    </View>
                    <View style={styles.titleSubPartLastView}>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        Address
                      </Text>
                      <Text>{data?.dwellingContractor?.address}</Text>
                    </View>
                  </View>

                  <View style={styles.titleSubPart}>
                    <View style={styles.titleSubPart1stView}>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        Phone Number
                      </Text>
                      <Text style={{ fontFamily: "PoppinsFamily" }}>
                        {data?.dwellingContractor?.phoneNumber}
                      </Text>
                    </View>
                    <View style={styles.titleSubPart1stView}>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        License Number
                      </Text>
                      <Text style={{ fontFamily: "PoppinsFamily" }}>
                        {data?.dwellingContractor?.licenseNumber}
                      </Text>
                    </View>
                    <View style={styles.titleSubPartLastView}>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        License Expiration Date
                      </Text>
                      <Text style={{ fontFamily: "PoppinsFamily" }}>
                        {
                          data?.dwellingContractor?.licenseExpirationDate?.split(
                            "T"
                          )[0]
                        }
                      </Text>
                    </View>
                  </View>

                  {/* Dwell qualifier contractor information view  */}
                  <View style={styles.titleHeading}>
                    <Text
                      style={{
                        fontFamily: "PoppinsFamily",
                        fontWeight: "semibold",
                      }}
                    >
                      Dwelling Contractor Qualifier Information
                    </Text>
                  </View>

                  <View style={styles.titleSubPart}>
                    <View style={styles.titleSubPart1stView}>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        Name
                      </Text>
                      <Text style={{ fontFamily: "PoppinsFamily" }}>
                        {
                          data?.dwellingContractor
                            ?.dwellingContractorQualifierName
                        }
                      </Text>
                    </View>
                    <View style={styles.titleSubPart1stView}>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        Zip Code
                      </Text>
                      <Text style={{ fontFamily: "PoppinsFamily" }}>
                        {
                          data?.dwellingContractor
                            ?.dwellingContractorQualifierZip
                        }
                      </Text>
                    </View>
                    <View style={styles.titleSubPartLastView}>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        Address
                      </Text>
                      <Text style={{ fontFamily: "PoppinsFamily" }}>
                        {
                          data?.dwellingContractor
                            ?.dwellingContractorQualifierAddress
                        }
                      </Text>
                    </View>
                  </View>

                  <View style={styles.titleSubPart}>
                    <View style={styles.titleSubPart1stView}>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        Phone Number
                      </Text>
                      <Text style={{ fontFamily: "PoppinsFamily" }}>
                        {
                          data?.dwellingContractor
                            ?.dwellingContractorQualifierPhoneNumber
                        }
                      </Text>
                    </View>
                    <View style={styles.titleSubPart1stView}>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        Email
                      </Text>
                      <Text style={{ fontFamily: "PoppinsFamily" }}>
                        {
                          data?.dwellingContractor
                            ?.dwellingContractorQualifierEmail
                        }
                      </Text>
                    </View>
                    <View style={styles.titleSubPartLastView}>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        City
                      </Text>
                      <Text style={{ fontFamily: "PoppinsFamily" }}>
                        {
                          data?.dwellingContractor
                            ?.dwellingContractorQualifierCity
                        }
                      </Text>
                    </View>
                  </View>

                  <View style={styles.titleSubPart}>
                    <View style={styles.titleSubPart1stView}>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        License Number
                      </Text>
                      <Text style={{ fontFamily: "PoppinsFamily" }}>
                        {
                          data?.dwellingContractor
                            ?.dwellingContractorQualifierLicenseNumber
                        }
                      </Text>
                    </View>
                    <View style={styles.titleSubPart1stView}>
                      <Text
                        style={{
                          fontFamily: "PoppinsFamily",
                          fontWeight: "semibold",
                        }}
                      >
                        License Exipration Date
                      </Text>
                      <Text style={{ fontFamily: "PoppinsFamily" }}>
                        {
                          data?.dwellingContractor?.dwellingContractorQualifierLicenseExpirationDate?.split(
                            "T"
                          )[0]
                        }
                      </Text>
                    </View>
                    <View style={styles.titleSubPartLastView} />
                  </View>
                </>
              )}
            </View>
          )}

          {data?.electricalContractorByOwner && (
            <View style={styles.checkBox}>
              {data?.electricalContractorByOwner ? (
                <Image src={checkboxFilled} style={styles.checkboxImageStyle} />
              ) : (
                <Image
                  src={checkboxOutlined}
                  style={styles.checkboxImageStyle}
                />
              )}
              <Text>Electrical</Text>
            </View>
          )}

          {data?.electricalContractor && (
            <>
              <View style={styles.titleHeading}>
                <Text
                  style={{
                    fontFamily: "PoppinsFamily",
                    fontWeight: "semibold",
                  }}
                >
                  Electrical Contractor Information
                </Text>
              </View>

              <View style={styles.titleSubPart}>
                {/* <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Name
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.electricalContractor?.name}
                  </Text>
                </View> */}
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Company Name
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.electricalContractor?.companyName}
                  </Text>
                </View>
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Address
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.electricalContractor?.address}
                  </Text>
                </View>
                <View style={styles.titleSubPartLastView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Email
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.electricalContractor?.email}
                  </Text>
                </View>
              </View>

              <View style={styles.titleSubPart}>
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Phone Number
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.electricalContractor?.phoneNumber}
                  </Text>
                </View>
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    City
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.electricalContractor?.city}
                  </Text>
                </View>
                <View style={styles.titleSubPartLastView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Zip
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.electricalContractor?.zip}
                  </Text>
                </View>
              </View>

              <View style={styles.titleSubPart}>
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    License Number
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.electricalContractor?.licenseNumber}
                  </Text>
                </View>
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    License Expiration Date
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {
                      data?.electricalContractor?.licenseExpirationDate?.split(
                        "T"
                      )[0]
                    }
                  </Text>
                </View>
                <View style={styles.titleSubPartLastView} />
              </View>

              {/* Master Electrician contractor information view  */}
              <View style={styles.titleHeading}>
                <Text
                  style={{
                    fontFamily: "PoppinsFamily",
                    fontWeight: "semibold",
                  }}
                >
                  Master Electrician Contractor Information
                </Text>
              </View>

              <View style={styles.titleSubPart}>
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Name
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.electricalContractor?.masterElectricianName}
                  </Text>
                </View>
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Email
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.electricalContractor?.masterElectricianEmail}
                  </Text>
                </View>
                <View style={styles.titleSubPartLastView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Address
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.electricalContractor?.masterElectricianAddress}
                  </Text>
                </View>
              </View>

              <View style={styles.titleSubPart}>
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Phone Number
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.electricalContractor?.masterElectricianPhoneNumber}
                  </Text>
                </View>
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    City
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.electricalContractor?.masterElectricianCity}
                  </Text>
                </View>
                <View style={styles.titleSubPartLastView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Zip
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.electricalContractor?.masterElectricianZip}
                  </Text>
                </View>
              </View>

              <View style={styles.titleSubPart}>
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    License Number
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.electricalContractor?.masterElectricianLicenseNumber}
                  </Text>
                </View>
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    License Expiration Date
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {
                      data?.electricalContractor?.masterElectricianLicenseExpirationDate?.split(
                        "T"
                      )[0]
                    }
                  </Text>
                </View>
                <View style={styles.titleSubPartLastView} />
              </View>
            </>
          )}

          {/* Plumber contractor information view  */}
          {data?.plumbingContractorByOwner && (
            <View style={styles.checkBox}>
              {data?.plumbingContractorByOwner ? (
                <Image src={checkboxFilled} style={styles.checkboxImageStyle} />
              ) : (
                <Image
                  src={checkboxOutlined}
                  style={styles.checkboxImageStyle}
                />
              )}
              <Text>Plumbing</Text>
            </View>
          )}
          {data?.plumbingContractor && (
            <>
              <View style={styles.titleHeading}>
                <Text
                  style={{
                    fontFamily: "PoppinsFamily",
                    fontWeight: "semibold",
                  }}
                >
                  Plumber Contractor Information
                </Text>
              </View>

              <View style={styles.titleSubPart}>
                {/* <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Name
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.plumbingContractor?.name}
                  </Text>
                </View> */}
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Company Name
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.plumbingContractor?.companyName}
                  </Text>
                </View>
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Email
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.plumbingContractor?.email}
                  </Text>
                </View>
                <View style={styles.titleSubPartLastView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Address
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.plumbingContractor?.address}
                  </Text>
                </View>
              </View>

              <View style={styles.titleSubPart}>
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Phone Number
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.plumbingContractor?.phoneNumber}
                  </Text>
                </View>
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    City
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.plumbingContractor?.city}
                  </Text>
                </View>
                <View style={styles.titleSubPartLastView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Zip
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.plumbingContractor?.zip}
                  </Text>
                </View>
              </View>

              <View style={styles.titleSubPart}>
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    License Number
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.plumbingContractor?.licenseNumber}
                  </Text>
                </View>
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    License Expiration Date
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {
                      data?.plumbingContractor?.licenseExpirationDate?.split(
                        "T"
                      )[0]
                    }
                  </Text>
                </View>
                <View style={styles.titleSubPartLastView} />
              </View>
            </>
          )}

          {/* HVAC contractor information view  */}
          {data?.hvacContractorByOwner && (
            <View style={styles.checkBox}>
              {data?.hvacContractorByOwner ? (
                <Image src={checkboxFilled} style={styles.checkboxImageStyle} />
              ) : (
                <Image
                  src={checkboxOutlined}
                  style={styles.checkboxImageStyle}
                />
              )}
              <Text>HVAC</Text>
            </View>
          )}
          {data?.hvacContractor && (
            <>
              <View style={styles.titleHeading}>
                <Text
                  style={{
                    fontFamily: "PoppinsFamily",
                    fontWeight: "semibold",
                  }}
                >
                  HVAC Contractor Information
                </Text>
              </View>

              <View style={styles.titleSubPart}>
                {/* <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Name
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.hvacContractor?.name}
                  </Text>
                </View> */}
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Company name
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.hvacContractor?.companyName}
                  </Text>
                </View>
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Email
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.hvacContractor?.email}
                  </Text>
                </View>
                <View style={styles.titleSubPartLastView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Address
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.hvacContractor?.address}
                  </Text>
                </View>
              </View>

              <View style={styles.titleSubPart}>
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Phone Number
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.hvacContractor?.phoneNumber}
                  </Text>
                </View>
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    City
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.hvacContractor?.city}
                  </Text>
                </View>
                <View style={styles.titleSubPartLastView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    Zip
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.hvacContractor?.zip}
                  </Text>
                </View>
              </View>

              <View style={styles.titleSubPart}>
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    License Number
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.hvacContractor?.licenseNumber}
                  </Text>
                </View>
                <View style={styles.titleSubPart1stView}>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontWeight: "semibold",
                    }}
                  >
                    License Expiration Date
                  </Text>
                  <Text style={{ fontFamily: "PoppinsFamily" }}>
                    {data?.hvacContractor?.licenseExpirationDate?.split("T")[0]}
                  </Text>
                </View>
                <View style={styles.titleSubPartLastView} />
              </View>
            </>
          )}

          {/* Applicant information view  */}
          <View style={styles.titleHeading}>
            <Text
              style={{
                fontFamily: "PoppinsFamily",
                fontWeight: "semibold",
              }}
            >
              Applicant Information
            </Text>
          </View>

          <View style={styles.titleSubPart}>
            <View style={styles.titleSubPart1stView}>
              <Text
                style={{ fontFamily: "PoppinsFamily", fontWeight: "semibold" }}
              >
                Person Submit Application
              </Text>
              <Text style={{ fontFamily: "PoppinsFamily" }}>
                {personSubmittingApplication.map((personSubmitApplication) => {
                  if (
                    personSubmitApplication.id ===
                    data?.personSubmittingApplication
                  ) {
                    return personSubmitApplication.name;
                  }
                })}
                {/* {data?.personSubmittingApplication} */}
              </Text>
            </View>
            <View style={styles.titleSubPart1stView}>
              <Text
                style={{ fontFamily: "PoppinsFamily", fontWeight: "semibold" }}
              >
                Applicant Name
              </Text>
              <Text style={{ fontFamily: "PoppinsFamily" }}>
                {data?.applicantName}
              </Text>
            </View>
            <View style={styles.titleSubPartLastView}>
              <Text
                style={{ fontFamily: "PoppinsFamily", fontWeight: "semibold" }}
              >
                Applicant Email
              </Text>
              <Text style={{ fontFamily: "PoppinsFamily" }}>
                {data?.applicantEmail}
              </Text>
            </View>
          </View>

          {data?.note && (
            <View style={styles.titleHeading}>
              <Text
                style={{ fontFamily: "PoppinsFamily", fontWeight: "semibold" }}
              >
                Note
              </Text>
              <Text style={{ fontFamily: "PoppinsFamily" }}>{data?.note}</Text>
            </View>
          )}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default ApplicationDetailFormPdf;
