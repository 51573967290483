export const applicantTypos = {
  "&.MuiTypography-root": {
    color: "#040F25",
    fontWeight: 700,
    fontFamily: "Poppins-Bold",
    fontSize: "20px",
    textTransform: "capitalize",
    mb: 2,
  },
  "&.MuiTypography-subText": {
    color: "#6D7B88",
    fontFamily: "Poppins-Light",
    fontSize: "16px",
    fontWeight: 300,
    textTransform: "capitalize",
    letterSpacing: "0.68px",
  },
};

export const saveButton = {
  backgroundColor: "black",
  height: "43px",
  color: "white",
  px: "10px",
  fontSize: "12px",
  borderRadius: 2,
  // width: "200px",
  fontFamily: "Poppins-semibold",
  textTransform: "capitalize",
};

export const saveContractorButton = {
  "&.MuiButton-root": {
    // height: "100%",
    color: "white",
    padding: "10px",
    backgroundColor: "#D52027",
    fontFamily: "Poppins-semibold",
    fontSize: "14px",
    // marginTop: "30px",
    borderRadius: 0,
    width: "200px",
    "&:hover": {
      backgroundColor: "red",
      transition: ".7s ease-in",
      boxShadow: 2,
    },
  },
};

export const cancelContractorButton = {
  "&.MuiButton-root": {
    backgroundColor: "#32363F",
    // height: "100%",
    color: "white",
    padding: "10px",
    fontFamily: "Poppins-semibold",
    fontSize: "14px",
    // marginTop: "30px",
    borderRadius: 0,
    width: "200px",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: "black",
      boxShadow: 2,
      transition: ".7s ease-in",
    },
  },
};

export const applicationFormSubmitButton = {
  "&.MuiButton-root": {
    display: "flex",
    alignItems: "center",
    // backgroundColor: "#d52027",
    // height: "100%",
    color: "white",
    gap: "10px",
    padding: "10px",
    fontFamily: "Poppins-semibold",
    fontSize: "12px",
    // marginTop: "30px",
    borderRadius: 2,
    width: "100%",
    "&:hover": {
      backgroundColor: "red",
      transition: ".7s ease-in",
      boxShadow: 2,
    },
  },
};

export const applicationFormDeleteButton = {
  "&.MuiButton-root": {
    display: "flex",
    alignItems: "center",
    // backgroundColor: "#d52027",
    // height: "100%",
    color: "white",
    gap: "10px",
    padding: "10px",
    fontFamily: "Poppins-semibold",
    fontSize: "12px",
    // marginTop: "30px",
    borderRadius: 2,
    width: "100%",
    "&:hover": {
      backgroundColor: "#FC6A03",
      transition: ".7s ease-in",
      boxShadow: 2,
    },
  },
};

export const applicationFormDownloadButton = {
  "&.MuiButton-root": {
    display: "flex",
    alignItems: "center",
    // backgroundColor: "#d52027",
    // height: "45px",
    color: "white",
    gap: "10px",
    padding: "10px",
    fontFamily: "Poppins-semibold",
    fontSize: "12px",
    // marginTop: "30px",
    borderRadius: 2,
    width: "100%",
    "&:hover": {
      backgroundColor: "black",
      transition: ".7s ease-in",
      boxShadow: 2,
    },
    "&:active": {
      textDecoration: "none",
    },
  },
};

export const applicationFormStateButton = {
  "&.MuiButton-root": {
    display: "flex",
    alignItems: "center",
    // backgroundColor: "#d52027",
    // height: "45px",
    color: "white",
    gap: "10px",
    padding: "10px",
    fontFamily: "Poppins-semibold",
    fontSize: "12px",
    // marginTop: "30px",
    borderRadius: 2,
    width: "100%",
    "&:active": {
      textDecoration: "none",
    },
  },
};
