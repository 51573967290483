import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { cancelContractorButton } from "./ApplicationDetailFormStyle";

const ApplicationDetailFormContractorDetail = ({ type, data, handleClose }) => {
  return (
    <Box sx={{ p: 4 }}>
      {type === "General" ? (
        <>
          <Box sx={{ fontFamily: "Poppins-semiBold", pl: 2 }}>
            {type} Contractor
          </Box>
          <Grid container sx={{ pl: 2 }}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              Name
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={8}>
              {data.name}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              Company name
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={8}>
              {data.companyName}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              Email
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={8}>
              {data.email}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              Phone number
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={8}>
              {data.phoneNumber}
            </Grid>
          </Grid>
          <Button
            sx={{ ...cancelContractorButton, marginTop: "15px" }}
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
        </>
      ) : (
        <>
          <Box sx={{ fontFamily: "Poppins-semiBold", pl: 2 }}>
            {type} Contractor
          </Box>
          <Grid container sx={{ pl: 2 }}>
            {type === "Dwelling" && (
              <>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  Name
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={8}>
                  {data.name}
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={4} lg={4}>
              Company name
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={8}>
              {data.companyName}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              Email
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={8}>
              {data.email}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              Phone number
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={8}>
              {data.phoneNumber}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              License number
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={8}>
              {data.licenseNumber}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              License expiration date
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={8}>
              {data.licenseExpirationDate?.split("T")[0]}
            </Grid>
          </Grid>
          <Button
            sx={{ ...cancelContractorButton, marginTop: "15px" }}
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
        </>
      )}
    </Box>
  );
};

export default ApplicationDetailFormContractorDetail;
