import {
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

const inputHeading = {
  "&.MuiTypography-inputTableHeadingBold": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-inputTableCell": {
    color: "white",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-inputTableCellName": {
    color: "white#6D7B88",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};

const paginationStackStyles = {
  display: "flex",
  justifyContent: "right",
  alignItems: "center",
  marginTop: "20px",
};

const paginationRowStyles = {
  ".MuiSvgIcon-root ": {
    fill: "#9B9B9B",
  },
  ":before": { borderBottomColor: "#9B9B9B", opacity: "0%" },
  ":after": { borderBottomColor: "#9B9B9B", opacity: "0%" },
  "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before": {
    borderBottom: `2px solid #9B9B9B`,
    opacity: "0%",
  },
};

const useTablePagination = ({
  page,
  setPage,
  rowsPerPage,
  handleChangePage,
  filteredList,
  setRowsPerPage,
}) => {
  //For custom pagination
  var arrayLength = 0,
    totalPages = 0;
  if (filteredList?.length < 1) {
    arrayLength = 0;
  } else {
    arrayLength = filteredList?.length;
    totalPages = Math.floor(arrayLength / rowsPerPage);
    if (arrayLength % rowsPerPage !== 0) totalPages += 1;
  }

  const handleRowsPerPage = (event) => {
    //setting page to first whenever rows changes
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  return (
    <>
      <Stack direction="row" spacing={2} sx={paginationStackStyles}>
        <Typography sx={inputHeading} variant="inputTableCellName">
          Rows per page:
        </Typography>
        <FormControl sx={{ m: 1, minWidth: 50 }}>
          <Select
            variant="standard"
            id="rowsPerPage-label"
            sx={paginationRowStyles}
            value={rowsPerPage}
            onChange={(event) => handleRowsPerPage(event)}
            displayEmpty
            // SelectProps={{
            //   renderValue: (value) => value,
            // }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
        <Pagination
          page={page + 1}
          color="primary"
          count={totalPages}
          onChange={(event, value) => handleChangePage(event, value)}
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default useTablePagination;
