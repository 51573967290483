import React from "react";
import Index from "../components/applicationRecord/buildingPlacard/BuildingPlacard";

const BuildingPlacard = () => {
  return (
    <div>
      <Index />
    </div>
  );
};

export default BuildingPlacard;
