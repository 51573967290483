export const titleTypography = {
  "&.MuiTypography-root": {
    fontFamily: "Poppins-semibold",
    fontWeight: 500,
    fontSize: 20,
  },
};

export const recieptBox = {
  "&.MuiBox-root": {
    marginTop: 3,
    fontFamily: "Poppins-semibold",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
};

export const pdfBox = {
  "&.MuiBox-root": {
    marginTop: 3,
    height: "150px",
    width: "150px",
  },
};
