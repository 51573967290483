import React from "react";
import Index from "../components/Municipalities/Index";
const Municipalities = () => {
  return (
    <div>
      <Index />
    </div>
  );
};
export default Municipalities;
