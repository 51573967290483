import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  IconButton,
  Button,
  InputBase,
  Grid,
} from "@mui/material";
import {
  DeleteOutlineOutlined,
  EditOutlined,
  Search,
} from "@mui/icons-material";
import {
  StyledTableRow,
  miniScrollBarStyles,
  styledTableCell,
  tableBodyTypos,
  userHeading,
} from "../../utils/muiComponentStyles";
import Loader from "../Loader/Loader";
import { useDispatch, useSelector } from "../../redux/store";
import CustomPagination from "../../hooks/useTablePagination";
import AddMunicipalitieDialog from "./AddMunicipalitieDialog";
import EditMunicipalitieDialog from "./EditMunicipalitieDialog";
import DeleteMunicipalitieDialog from "./DeleteMunicipalitieDialog";
import { getAllMuncipalities } from "../../redux/slices/muncipalitySlice";

const Index = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedMunicipality, setSelectedMunicipality] = useState();
  const [filteredList, setFilteredList] = useState([]);
  const [toBeSearched, setToBeSearched] = useState("");

  useEffect(() => {
    dispatch(getAllMuncipalities(handleApiRes));
  }, [dispatch]);

  let { muncipalityList } = useSelector((state) => state.muncipalityReducer);

  useEffect(() => {
    setFilteredList(muncipalityList);
  }, [muncipalityList]);

  const handleApiRes = (response) => {
    if (response.status) {
      setLoading(false);
    }
  };

  const handleCreateNewMunicipalitie = () => {
    setOpenAddDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenAddDialog(false);
    setOpenDeleteDialog(false);
    setOpenEditDialog(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleEditMunicipality = (data) => {
    setOpenEditDialog(true);
    setSelectedMunicipality(data);
  };

  const handleDeleteMunicipality = (data) => {
    setOpenDeleteDialog(true);
    setSelectedMunicipality(data);
  };

  const handleFilter = (bodyData, searchTerm) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return bodyData.filter((municipality) => {
      let munciName = municipality?.name
        ?.toLowerCase()
        ?.includes(lowerCaseSearchTerm);
      let munciId = municipality?.muniId
        ?.toLowerCase()
        ?.includes(lowerCaseSearchTerm);
      let munciKey = municipality?.muniAccessKey
        ?.toLowerCase()
        ?.includes(lowerCaseSearchTerm);
      return munciName || munciId || munciKey;
    });
  };

  const filteredData = handleFilter(filteredList, toBeSearched);

  return (
    <Box>
      <Grid spacing={2} container sx={{ my: 2 }}>
        <Grid item xs={12} md={8} sm={8} lg={10}>
          <InputBase
            onChange={(e) => setToBeSearched(e.target.value)}
            disabled={loading}
            sx={{
              width: "100%",
              height: "38px",
              border: "1px solid rgba(169, 169, 169, 0.6)",
              borderRadius: 2,
              backgroundColor: "background.bgSearchBar",
              fontFamily: "Poppins",
            }}
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            startAdornment={
              <Search
                sx={{
                  color: "rgba(151, 151, 151, 0.68);",
                  paddingLeft: "3px",
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={4} sm={4} lg={2}>
          <Button
            onClick={handleCreateNewMunicipalitie}
            disableRipple
            disabled={loading}
            sx={{
              backgroundColor: "background.bgLayoutB",
              width: "100%",
              borderRadius: "10px",
              "&:hover": { backgroundColor: "background.bgLayoutB" },
              "&:disabled": {
                backgroundColor: "rgba(224, 224, 224, 1)",
              },
            }}
          >
            <Typography sx={userHeading} variant="userHeadingBoldButton">
              Create Municipality
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          maxHeight: 640,
          borderRadius: "10px",
          [`::-webkit-scrollbar`]: { width: "0px", background: "transparent" },
          ...miniScrollBarStyles,
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead sx={{ backgroundColor: "background.bgLayoutA" }}>
            <TableRow>
              <TableCell
                sx={{
                  ...styledTableCell,
                  backgroundColor: "background.bgLayoutA",
                  width: "220px",
                }}
                component="th"
                scope="row"
              >
                <Typography sx={userHeading} variant="userTableHeadingBold">
                  Municipality Name
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  ...styledTableCell,
                  backgroundColor: "background.bgLayoutA",
                  width: "220px",
                }}
                component="th"
                scope="row"
              >
                <Typography sx={userHeading} variant="userTableHeadingBold">
                  Municipality access key
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  ...styledTableCell,
                  backgroundColor: "background.bgLayoutA",
                  width: "312px",
                }}
              >
                <Typography sx={userHeading} variant="userTableHeadingBold">
                  Municipality Id
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  ...styledTableCell,
                  borderBottom: "none",
                  backgroundColor: "background.bgLayoutA",
                  width: "312px",
                }}
                component="th"
                scope="row"
              ></TableCell>
              <TableCell
                sx={{
                  ...styledTableCell,
                  backgroundColor: "background.bgLayoutA",
                  width: "312px",
                }}
                component="th"
                scope="row"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  sx={{ ...styledTableCell, minWidth: "312px" }}
                  component="th"
                  scope="row"
                ></TableCell>
                <TableCell
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    ...styledTableCell,
                    height: "auto",
                    minWidth: "312px",
                  }}
                >
                  <Box sx={{ mr: 10 }}>
                    <Loader />
                  </Box>
                </TableCell>
                <TableCell sx={styledTableCell}></TableCell>
                <TableCell sx={styledTableCell}></TableCell>
                <TableCell sx={styledTableCell}></TableCell>
              </TableRow>
            ) : filteredData?.length < 1 ? (
              <TableRow>
                <TableCell sx={styledTableCell}></TableCell>
                <TableCell
                  sx={{
                    ...styledTableCell,
                    minWidth: "312px",
                    textAlign: "center",
                  }}
                >
                  <Typography sx={userHeading} variant="userTableCellName">
                    No Municipality found
                  </Typography>
                </TableCell>
                <TableCell sx={styledTableCell}></TableCell>
                <TableCell sx={styledTableCell}></TableCell>
                <TableCell sx={styledTableCell}></TableCell>
              </TableRow>
            ) : (
              filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow
                    hover
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      sx={{ ...styledTableCell, minWidth: "312px" }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "312px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellNameAccessKey"
                      >
                        {row.muniAccessKey}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: "312px",
                        borderBottom: "none",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {row.muniId}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={styledTableCell}
                      component="th"
                      scope="row"
                    ></TableCell>
                    <TableCell
                      sx={styledTableCell}
                      component="th"
                      scope="row"
                      align="right"
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton
                          sx={{
                            "&.MuiButtonBase-root": {
                              backgroundColor: "background.bgLayoutA",
                              color: "white",
                              height: "36px",
                              width: "36px",
                            },
                          }}
                          onClick={() => {
                            handleEditMunicipality(row);
                          }}
                        >
                          <EditOutlined />
                        </IconButton>
                        <IconButton
                          sx={{
                            "&.MuiButtonBase-root": {
                              backgroundColor: "background.bgLayoutB",
                              color: "white",
                              height: "36px",
                              width: "36px",
                            },
                          }}
                          onClick={() => handleDeleteMunicipality(row)}
                        >
                          <DeleteOutlineOutlined />
                        </IconButton>
                      </span>
                    </TableCell>
                  </StyledTableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        filteredList={filteredData}
        setRowsPerPage={setRowsPerPage}
      />
      {openAddDialog && (
        <AddMunicipalitieDialog
          openAddDialog={openAddDialog}
          handleCloseDialog={handleCloseDialog}
        />
      )}
      {openEditDialog && (
        <EditMunicipalitieDialog
          openEditDialog={openEditDialog}
          selectedMunicipality={selectedMunicipality}
          handleCloseDialog={handleCloseDialog}
        />
      )}
      {openDeleteDialog && (
        <DeleteMunicipalitieDialog
          openDeleteDialog={openDeleteDialog}
          selectedMunicipality={selectedMunicipality}
          handleCloseDialog={handleCloseDialog}
        />
      )}
    </Box>
  );
};

export default Index;
