export const permitRepairDetails = {
  margin: "auto",
  marginTop: "50px",
  borderRadius: "7px",
};

export const sprinkler = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};
export const permitNumber = {
  color: "#040F25",
  padding: "15px",
  marginTop: "15px",
  display: "flex",
  justifyContent: "center",
  fontSize: "20px !important",
  fontFamily: "Poppins-Bold",
};
export const projectDetails = {
  display: "flex",
  gap: "5%",
  justifyContent: "center",
  marginBottom: "20px",
};

export const projectDetailsBox = {
  width: "16%",
  color: "#040F25",
};
export const buttonGroup = {
  display: "flex",
  justifyContent: "space-between",
  flexGrow: 1,
};

export const summary = {
  display: "flex",
  justifyContent: "space-between",
  color: "#040F25",
};

export const saveButton = {
  display: "flex",
  alignItems: "center",
  backgroundColor: "#d52027",
  height: "100%",
  color: "white",
  padding: "10px",
  fontFamily: "Poppins-semibold",
  fontSize: "14px",
  // marginTop: "30px",
  borderRadius: 2,
  width: "200px",
  "&:hover": {
    backgroundColor: "red",
    transition: ".7s ease-in",
    boxShadow: 2,
  },
};

export const permitNumberTypo = {
  "&.MuiTypography-root": {
    fontSize: "20px",
    fontFamily: "Poppins-Bold",
    letterSpacing: "1.26px",
    lineHeight: "37.5px",
  },
  "&.MuiTypography-subText": {
    fontSize: "16px",
    fontFamily: "Poppins-Light",
    letterSpacing: "0.68px",
    lineHeight: "24px",
  },
};

export const tableHeadStyle = {
  backgroundColor: "#040F25",
  color: "white",
  fontSize: "18px",
  fontFamily: "Poppins-Bold",
  // ml: 5.8,
};

export const tableCellStyle = {
  py: 0.5,
  px: 3,
};
export const repairFee = {
  color: "#D52027",
  fontSize: "16px",
  fontFamily: "Poppins-Bold",
};
