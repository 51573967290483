import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Stack,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import {
  DeleteObjectCommand,
  PutObjectCommand,
  S3Client,
} from "@aws-sdk/client-s3";
import {
  applicationCardStyle,
  closeIconButton,
  listMediaInnerWrapper,
  recordInspectionTypo,
  removeButton,
  downloadButtonWrapper,
  downloadMediaButton,
} from "../../../utils/muiComponentStyles";
import { dispatch, useSelector } from "../../../redux/store";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";

import ToasterContext from "../../../utils/context/toasterContext";
import CircularProgressLoader from "../../../utils/CircularProgressLoader";
import {
  uploadApplicationMedia,
  getSingleApplicationData,
  getApplicantStatus,
} from "../../../redux/slices/dashboard/applicantsSlice";
import MediaApplicationDetails from "./applicationDetails";
import PdfPreview from "./../../../assets/application-media-previews/wipermit-pdf-thumbnail.png";
import DocsPreview from "./../../../assets/application-media-previews/wipermit-docs-thumbnail.jpg";
import PowerPointPreview from "./../../../assets/application-media-previews/wipermit-powerpoint-thumbnail.jpg";
import ExcelPreview from "./../../../assets/application-media-previews/wipermit-xls-thumbnail.jpg";
// import { filter } from "lodash";

const s3Client = new S3Client({
  endpoint: process.env.REACT_APP_SPACES_ENDPOINT,
  forcePathStyle: true,
  region: process.env.REACT_APP_SPACES_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_SPACES_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SPACES_SECRET_ACCESS_KEY,
  },
});

const Index = () => {
  const matches = useMediaQuery("(max-width:600px)");
  let applicationData = JSON.parse(localStorage.getItem("selectedApplication"));
  const permissions = localStorage.getItem("permissions");
  const permissionArray = permissions ? permissions.split(",") : [];
  const [manipulationPermission, setManipulationPermission] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [imagesToBeDeleted, setImagesToBeDeleted] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [selectedDocument, setSelectedDocuments] = useState([]);
  const [filteredImages, setFilteredImages] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [uploadMediaButton, setUploadMediaButton] = useState(true);
  const [occupancyLetterPdf, setOccupancyLetterPdf] = useState([]);
  const [buildingPlacardPdf, setBuildingPlacardPdf] = useState([]);

  const fireToasterContext = useContext(ToasterContext);

  useEffect(() => {
    dispatch(getSingleApplicationData(applicationData.id, handleApiResponse));
    setLoading(true);
  }, []);
  const { application } = useSelector((state) => state.applicantsReducer);
  const handleApiResponse = (data) => {
    if (data.status) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPreviewImages(
      application?.media?.map((file) => {
        if (file?.type === "Docs") {
          return {
            url: DocsPreview,
            type: file?.type,
            urlDownload: file?.url,
          };
        } else if (file?.type === "Excel") {
          return {
            url: ExcelPreview,
            type: file?.type,
            urlDownload: file?.url,
          };
        } else if (file?.type === "Powerpoint") {
          return {
            url: PowerPointPreview,
            type: file?.type,
            urlDownload: file?.url,
          };
        } else if (file?.type === "Pdf") {
          return {
            url: PdfPreview,
            type: file?.type,
            urlDownload: file?.url,
          };
        } else if (file?.type === "Image") {
          return {
            url: file?.url,
            type: file?.type,
            urlDownload: file?.url,
          };
        }
      })
    );
    if (!permissionArray.includes("manipulate application details")) {
      setManipulationPermission(true);
    } else {
      setManipulationPermission(false);
    }
  }, [application?.media]);

  useEffect(() => {
    setFilteredImages(
      previewImages?.filter((el) => {
        return el?.type === "Image";
      })
    );
    setFilteredDocuments(
      previewImages?.filter((el) => {
        return el?.type !== "Image";
      })
    );
  }, [previewImages]);

  useEffect(() => {
    if (applicationData?.buildingPlacardPdfUrl) {
      let buildingPlacardObj = {
        url: PdfPreview,
        type: "Pdf",
        urlDownload: applicationData?.buildingPlacardPdfUrl,
      };
      setBuildingPlacardPdf([buildingPlacardObj]);
    }
    if (applicationData?.occupancyLetterPdfUrl) {
      let occupancyLetterObj = {
        url: PdfPreview,
        type: "Pdf",
        urlDownload: applicationData?.occupancyLetterPdfUrl,
      };
      setOccupancyLetterPdf([occupancyLetterObj]);
    }
  }, []);

  const handleAddPhotoClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };
  const handleAddDocClick = () => {
    const fileInput = document.getElementById("docInput");
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleSelectFiles = async (event) => {
    if (event.target.files[0]) {
      setUploadMediaButton(false);

      let tempPreviewImages = [...filteredImages];
      // let tempPreviewDocuments = [...filteredDocuments];
      let tempSelectedMedia = [...selectedMedia];
      const selectedFile = event.target.files[0];

      let fileExtension = checkFileExt(event.target.files[0].name);
      fileExtension = fileExtension?.toLowerCase();
      if (
        fileExtension == "png" ||
        fileExtension == "jpg" ||
        fileExtension == "jpeg" ||
        fileExtension == "webp"
      ) {
        const imageUrl = URL.createObjectURL(selectedFile);

        tempPreviewImages.push({
          type: "Image",
          url: imageUrl,
          name: selectedFile.name,
          urlDownload: imageUrl,
        });
        setFilteredImages(tempPreviewImages);

        tempSelectedMedia.push({
          file: selectedFile,
          fileId: filteredImages?.length > 0 ? filteredImages?.length : 0,
          type: "Image",
        });
        setSelectedMedia(tempSelectedMedia);
      } else {
        fireToasterContext.fireToasterHandler(false, "Invalid File");
      }
    }
    event.target.value = "";
  };

  const handleSelectFilesForDocument = async (event) => {
    if (event.target.files[0]) {
      setUploadMediaButton(false);

      // let tempPreviewImages = [...filteredImages];
      let tempPreviewDocuments = [...filteredDocuments];
      let tempSelectedMedia = [...selectedDocument];
      const selectedFile = event.target.files[0];

      let fileExtension = checkFileExt(event.target.files[0].name);
      fileExtension = fileExtension?.toLowerCase();

      if (fileExtension == "docx" || fileExtension == "doc") {
        const imageUrl = URL.createObjectURL(selectedFile);
        tempPreviewDocuments.push({
          type: "Docs",
          url: DocsPreview,
          name: selectedFile.name,
          urlDownload: imageUrl,
        });
        setFilteredDocuments(tempPreviewDocuments);
        tempSelectedMedia.push({
          file: selectedFile,
          fileId: filteredDocuments?.length > 0 ? filteredDocuments?.length : 0,
          type: "Docs",
        });
        setSelectedDocuments(tempSelectedMedia);
      } else if (
        fileExtension == "csv" ||
        fileExtension == "xls" ||
        fileExtension == "xlsx"
      ) {
        const imageUrl = URL.createObjectURL(selectedFile);
        tempPreviewDocuments.push({
          type: "Excel",
          url: ExcelPreview,
          name: selectedFile.name,
          urlDownload: imageUrl,
        });
        setFilteredDocuments(tempPreviewDocuments);
        tempSelectedMedia.push({
          file: selectedFile,
          fileId: filteredDocuments?.length > 0 ? filteredDocuments?.length : 0,
          type: "Excel",
        });
        setSelectedDocuments(tempSelectedMedia);
      } else if (fileExtension == "pdf") {
        const imageUrl = URL.createObjectURL(selectedFile);
        tempPreviewDocuments.push({
          type: "Pdf",
          url: PdfPreview,
          name: selectedFile.name,
          urlDownload: imageUrl,
        });
        setFilteredDocuments(tempPreviewDocuments);
        tempSelectedMedia.push({
          file: selectedFile,
          fileId: filteredDocuments?.length > 0 ? filteredDocuments?.length : 0,
          type: "Pdf",
        });
        setSelectedDocuments(tempSelectedMedia);
      } else if (fileExtension == "pptx" || fileExtension == "ppt") {
        const imageUrl = URL.createObjectURL(selectedFile);
        tempPreviewDocuments.push({
          type: "Powerpoint",
          url: PowerPointPreview,
          name: selectedFile.name,
          urlDownload: imageUrl,
        });
        setFilteredDocuments(tempPreviewDocuments);
        tempSelectedMedia.push({
          file: selectedFile,
          fileId: filteredDocuments?.length > 0 ? filteredDocuments?.length : 0,
          type: "Powerpoint",
        });
        setSelectedDocuments(tempSelectedMedia);
      } else {
        fireToasterContext.fireToasterHandler(false, "Invalid File");
      }
    }
    event.target.value = "";
  };

  const removeObjectsFromSpaces = async () => {
    try {
      for (let i = 0; i < imagesToBeDeleted.length; i++) {
        const deleteParams = {
          Bucket: process.env.REACT_APP_SPACES_BUCKET_NAME,
          Key: imagesToBeDeleted[i],
        };
        const command = new DeleteObjectCommand(deleteParams);
        await s3Client.send(command);
      }
    } catch (error) {
      console.log("Error deleting");
    }
    setImagesToBeDeleted([]);
  };

  // S3 bucket media uploaded response
  const handleUploadMediaResponse = async (data) => {
    if (data.status) {
      setUploading(false);
      setUploadMediaButton(true);
      removeObjectsFromSpaces();
      setSelectedMedia([]);
      setSelectedDocuments([]);
      dispatch(getApplicantStatus(applicationData.id));
      dispatch(getSingleApplicationData(applicationData.id));
      fireToasterContext.fireToasterHandler(true, "Media Upload successfully");
    } else {
      fireToasterContext.fireToasterHandler(false, "Something went wrong");
      setUploading(false);
    }
  };

  function checkFileExt(filename) {
    const ext = filename.split(".");
    return ext[ext.length - 1];
  }

  // Handle the upload of images to the S3 bucket.

  const handleUploadFilesToSpaces = async (finalMediaArrr) => {
    try {
      let allMediaArr = filteredImages.filter((el) => {
        return el?.url
          ?.toString()
          ?.startsWith(process.env.REACT_APP_SPACES_ENDPOINT);
      });

      for (let i = 0; i < filteredDocuments.length; i++) {
        if (
          filteredDocuments[i].urlDownload
            ?.toString()
            ?.startsWith(process.env.REACT_APP_SPACES_ENDPOINT)
        ) {
          allMediaArr.push({
            url: filteredDocuments[i].urlDownload,
            type: filteredDocuments[i].type,
          });
        }
      }

      // Loop through each selected media file
      for (let i = 0; i < finalMediaArrr.length; i++) {
        const params = {
          Bucket: process.env.REACT_APP_SPACES_BUCKET_NAME,
          Key: `${process.env.REACT_APP_ENVIRONMENT}/${applicationData.id}/${
            finalMediaArrr[i].file.name.split(" ").join("-").split(".")[0]
          }-${Date.now()}.${checkFileExt(finalMediaArrr[i].file.name)}`,
          Body: finalMediaArrr[i].file,
          ACL: "public-read",
        };
        // Upload the media file to the S3 bucket
        await s3Client.send(new PutObjectCommand(params));
        // Add the uploaded media URL to the array
        allMediaArr.push({
          url: `${process.env.REACT_APP_SPACES_ENDPOINT}/${params.Bucket}/${params.Key}`,
          type: finalMediaArrr[i].type,
        });
      }

      // Dispatch the uploadMedia action with the necessary parameters
      dispatch(
        uploadApplicationMedia(
          applicationData.id,
          allMediaArr,
          handleUploadMediaResponse
        )
      );
    } catch (error) {
      setLoading(false);
    }
  };

  const handleRemovePreview = async (index, imageUrl) => {
    setUploadMediaButton(false);
    let tempImagesToBeDeleted = [...imagesToBeDeleted];
    if (imageUrl.toString().startsWith(process.env.REACT_APP_SPACES_ENDPOINT)) {
      let splittedImageUrl = imageUrl.split("/");
      splittedImageUrl.splice(0, 4);
      let keyToBeDeleted = splittedImageUrl.join("/");
      tempImagesToBeDeleted.push(keyToBeDeleted);

      setImagesToBeDeleted(tempImagesToBeDeleted);
    }
    let tempPreviewImages = [...filteredImages];
    tempPreviewImages.splice(index, 1);
    setFilteredImages(tempPreviewImages);
    let tempSelectedMedia = [...selectedMedia];
    let selectedMediaIndexToBeRemoved = tempSelectedMedia?.findIndex((el) => {
      return el.fileId == index;
    });
    if (selectedMediaIndexToBeRemoved > -1) {
      tempSelectedMedia.splice(selectedMediaIndexToBeRemoved, 1);
      setSelectedMedia(tempSelectedMedia);
    }
  };

  const handleRemoveDocumentPreview = async (index, imageUrl) => {
    setUploadMediaButton(false);

    let tempImagesToBeDeleted = [...imagesToBeDeleted];
    if (
      imageUrl?.toString()?.startsWith(process.env.REACT_APP_SPACES_ENDPOINT)
    ) {
      let splittedImageUrl = imageUrl.split("/");
      splittedImageUrl.splice(0, 4);
      let keyToBeDeleted = splittedImageUrl.join("/");
      tempImagesToBeDeleted.push(keyToBeDeleted);

      setImagesToBeDeleted(tempImagesToBeDeleted);
    }
    let tempPreviewImages = [...filteredDocuments];

    tempPreviewImages.splice(index, 1);
    setFilteredDocuments(tempPreviewImages);
    let tempSelectedMedia = [...selectedDocument];
    let selectedMediaIndexToBeRemoved = tempSelectedMedia?.findIndex((el) => {
      return el.fileId == index;
    });
    if (selectedMediaIndexToBeRemoved > -1) {
      tempSelectedMedia.splice(selectedMediaIndexToBeRemoved, 1);
      setSelectedDocuments(tempSelectedMedia);
    }
  };

  const handleUploadMediaButton = () => {
    setUploading(true);
    let finalMediaArrr = [...selectedMedia, ...selectedDocument];

    handleUploadFilesToSpaces(finalMediaArrr);
  };

  return (
    <div>
      <Card>
        <CardHeader
          disableTypography
          title={
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>Media and Documents</Box>
              <Box>
                Status: {application?.status} | Permit Fee:{" "}
                {(application?.totalAmount * 1)?.toFixed(2)}
              </Box>
            </Box>
          }
          sx={applicationCardStyle}
        />
        <CardContent>
          <Container sx={{ my: 2 }}>
            <MediaApplicationDetails
              loading={loading}
              application={application}
            />
            <hr
              style={{
                borderTop: "1px solid #E7E7E7",
              }}
            />
            {/* add images container */}
            <Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box sx={{ my: 3 }}>
                  <Typography sx={recordInspectionTypo}>Images</Typography>
                </Box>
                <Box onClick={handleAddPhotoClick} sx={{ cursor: "pointer" }}>
                  <Typography
                    sx={{ ...recordInspectionTypo, color: "#D52027" }}
                  >
                    +Add Photos
                  </Typography>
                  <input
                    disabled={manipulationPermission}
                    id="fileInput"
                    style={{ display: "none" }}
                    type="file"
                    onChange={handleSelectFiles}
                  />
                </Box>
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  gap: 3,
                  flexWrap: matches ? "wrap" : "nowrap",
                  alignItems: "center",
                }}
              >
                <Box sx={listMediaInnerWrapper}>
                  {filteredImages?.map((media, index) => {
                    const fileName = media?.urlDownload?.split("/").pop();
                    return (
                      <Box key={index} sx={{ position: "relative" }}>
                        <img
                          src={media?.url}
                          alt="image preview"
                          style={{
                            width: 327,
                            height: 240,
                            borderRadius: "10px",
                          }}
                        />

                        {!manipulationPermission && (
                          <button
                            onClick={() =>
                              handleRemovePreview(index, media?.url)
                            }
                            style={removeButton}
                          >
                            <span>
                              <Tooltip title="Remove">
                                <IconButton size="small" sx={closeIconButton}>
                                  <CloseRoundedIcon />
                                </IconButton>
                              </Tooltip>
                            </span>
                          </button>
                        )}
                        <a
                          href={media.urlDownload}
                          download={media.urlDownload}
                          rel="noreferrer"
                          // style={{
                          //   display: !media?.urlDownload
                          //     ?.toString()
                          //     ?.startsWith(
                          //       process.env.REACT_APP_SPACES_ENDPOINT
                          //     )
                          //     ? "none"
                          //     : "block",
                          // }}
                        >
                          <Tooltip title="Downlaod">
                            <div style={downloadButtonWrapper}>
                              <Button
                                variant="outlined"
                                fullWidth
                                sx={downloadMediaButton}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    variant="subText"
                                    sx={{
                                      color: "white",
                                      marginRight: "8px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {media.name ? media.name : fileName}
                                  </Typography>

                                  <DownloadForOfflineRoundedIcon
                                    sx={{
                                      fontSize: "27px",
                                    }}
                                  />
                                </Box>
                              </Button>
                            </div>
                          </Tooltip>
                        </a>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
            {/* media doc */}
            <Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box sx={{ my: 3 }}>
                  <Typography sx={recordInspectionTypo}>Documents</Typography>
                </Box>
                <Box onClick={handleAddDocClick} sx={{ cursor: "pointer" }}>
                  <Typography
                    sx={{ ...recordInspectionTypo, color: "#D52027" }}
                  >
                    +Add Documents
                  </Typography>
                  <input
                    disabled={manipulationPermission}
                    id="docInput"
                    style={{ display: "none" }}
                    type="file"
                    onChange={handleSelectFilesForDocument}
                  />
                </Box>
              </Stack>

              <Box
                sx={{
                  display: "flex",
                  gap: 3,
                  flexWrap: matches ? "wrap" : "nowrap",
                  alignItems: "center",
                }}
              >
                <Box sx={listMediaInnerWrapper}>
                  {filteredDocuments?.map((media, index) => {
                    const fileName = media?.urlDownload?.split("/").pop();
                    return (
                      <Box key={index} sx={{ position: "relative" }}>
                        <img
                          src={media?.url}
                          alt="image preview"
                          style={{
                            width: 327,
                            height: 240,
                            borderRadius: "10px",
                          }}
                        />

                        {!manipulationPermission && (
                          <button
                            onClick={() =>
                              handleRemoveDocumentPreview(
                                index,
                                media.urlDownload
                              )
                            }
                            style={{
                              ...removeButton,
                              display:
                                media?.urlDownload &&
                                (media.urlDownload.startsWith(
                                  `${process.env.REACT_APP_SPACES_ENDPOINT}/${process.env.REACT_APP_SPACES_BUCKET_NAME}/${process.env.REACT_APP_ENVIRONMENT}/${application?.id}/Occupancy-letter`
                                ) ||
                                  media.urlDownload.startsWith(
                                    `${process.env.REACT_APP_SPACES_ENDPOINT}/${process.env.REACT_APP_SPACES_BUCKET_NAME}/${process.env.REACT_APP_ENVIRONMENT}/${application?.id}/Building-Placard-Detail`
                                  ))
                                  ? "none"
                                  : "block",
                            }}
                          >
                            <span>
                              <Tooltip title="Remove">
                                <IconButton size="small" sx={closeIconButton}>
                                  <CloseRoundedIcon />
                                </IconButton>
                              </Tooltip>
                            </span>
                          </button>
                        )}
                        <a
                          href={media.urlDownload}
                          download={media.urlDownload}
                          rel="noreferrer"
                          // style={{
                          //   display: !media?.urlDownload
                          //     ?.toString()
                          //     ?.startsWith(
                          //       process.env.REACT_APP_SPACES_ENDPOINT
                          //     )
                          //     ? "none"
                          //     : "block",
                          // }}
                        >
                          <Tooltip title="Downlaod">
                            <div style={downloadButtonWrapper}>
                              <Button
                                variant="outlined"
                                fullWidth
                                sx={downloadMediaButton}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    variant="subText"
                                    sx={{
                                      color: "white",
                                      marginRight: "8px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {media.name ? media.name : fileName}
                                  </Typography>

                                  <DownloadForOfflineRoundedIcon
                                    sx={{
                                      fontSize: "27px",
                                    }}
                                  />
                                </Box>
                              </Button>
                            </div>
                          </Tooltip>
                        </a>
                      </Box>
                    );
                  })}
                  {occupancyLetterPdf?.map((media, index) => {
                    const fileName = media?.urlDownload?.split("/").pop();
                    return (
                      <Box key={index} sx={{ position: "relative" }}>
                        <img
                          src={media?.url}
                          alt="image preview"
                          style={{
                            width: 327,
                            height: 240,
                            borderRadius: "10px",
                          }}
                        />

                        <button
                          onClick={() =>
                            handleRemoveDocumentPreview(
                              index,
                              media.urlDownload
                            )
                          }
                          style={{
                            ...removeButton,
                            display:
                              media?.urlDownload &&
                              (media.urlDownload.startsWith(
                                `${process.env.REACT_APP_SPACES_ENDPOINT}/${process.env.REACT_APP_SPACES_BUCKET_NAME}/${process.env.REACT_APP_ENVIRONMENT}/${application?.id}/Occupancy-letter`
                              ) ||
                                media.urlDownload.startsWith(
                                  `${process.env.REACT_APP_SPACES_ENDPOINT}/${process.env.REACT_APP_SPACES_BUCKET_NAME}/${process.env.REACT_APP_ENVIRONMENT}/${application?.id}/Building-Placard-Detail`
                                ))
                                ? "none"
                                : "block",
                          }}
                        >
                          {/* <span>
                            <Tooltip title="Remove">
                              <IconButton size="small" sx={closeIconButton}>
                                <CloseRoundedIcon />
                              </IconButton>
                            </Tooltip>
                          </span> */}
                        </button>
                        <a
                          href={media.urlDownload}
                          download={media.urlDownload}
                          rel="noreferrer"
                          style={{
                            display: !media?.urlDownload
                              ?.toString()
                              ?.startsWith(
                                process.env.REACT_APP_SPACES_ENDPOINT
                              )
                              ? "none"
                              : "block",
                          }}
                        >
                          <Tooltip title="Downlaod">
                            <div style={downloadButtonWrapper}>
                              <Button
                                variant="outlined"
                                fullWidth
                                sx={downloadMediaButton}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    variant="subText"
                                    sx={{
                                      color: "white",
                                      marginRight: "8px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {fileName}
                                  </Typography>

                                  <DownloadForOfflineRoundedIcon
                                    sx={{
                                      fontSize: "27px",
                                    }}
                                  />
                                </Box>
                              </Button>
                            </div>
                          </Tooltip>
                        </a>
                      </Box>
                    );
                  })}
                  {buildingPlacardPdf?.map((media, index) => {
                    const fileName = media?.urlDownload?.split("/").pop();
                    return (
                      <Box key={index} sx={{ position: "relative" }}>
                        <img
                          src={media?.url}
                          alt="image preview"
                          style={{
                            width: 327,
                            height: 240,
                            borderRadius: "10px",
                          }}
                        />

                        <button
                          onClick={() =>
                            handleRemoveDocumentPreview(
                              index,
                              media.urlDownload
                            )
                          }
                          style={{
                            ...removeButton,
                            display:
                              media?.urlDownload &&
                              (media.urlDownload.startsWith(
                                `${process.env.REACT_APP_SPACES_ENDPOINT}/${process.env.REACT_APP_SPACES_BUCKET_NAME}/${process.env.REACT_APP_ENVIRONMENT}/${application?.id}/Occupancy-letter`
                              ) ||
                                media.urlDownload.startsWith(
                                  `${process.env.REACT_APP_SPACES_ENDPOINT}/${process.env.REACT_APP_SPACES_BUCKET_NAME}/${process.env.REACT_APP_ENVIRONMENT}/${application?.id}/Building-Placard-Detail`
                                ))
                                ? "none"
                                : "block",
                          }}
                        >
                          <span>
                            <Tooltip title="Remove">
                              <IconButton size="small" sx={closeIconButton}>
                                <CloseRoundedIcon />
                              </IconButton>
                            </Tooltip>
                          </span>
                        </button>
                        <a
                          href={media.urlDownload}
                          download={media.urlDownload}
                          rel="noreferrer"
                          style={{
                            display: !media?.urlDownload
                              ?.toString()
                              ?.startsWith(
                                process.env.REACT_APP_SPACES_ENDPOINT
                              )
                              ? "none"
                              : "block",
                          }}
                        >
                          <Tooltip title="Downlaod">
                            <div style={downloadButtonWrapper}>
                              <Button
                                variant="outlined"
                                fullWidth
                                sx={downloadMediaButton}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    variant="subText"
                                    sx={{
                                      color: "white",
                                      marginRight: "8px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {fileName}
                                  </Typography>

                                  <DownloadForOfflineRoundedIcon
                                    sx={{
                                      fontSize: "27px",
                                    }}
                                  />
                                </Box>
                              </Button>
                            </div>
                          </Tooltip>
                        </a>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
            {/* upload media and document button  */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 1,
              }}
            >
              <Button
                onClick={handleUploadMediaButton}
                disabled={uploadMediaButton || manipulationPermission}
                sx={
                  uploading
                    ? {
                        width: "178px",
                        height: "46px",
                        backgroundColor: "#979797",
                        borderRadius: "10px",
                        color: "white",
                        "&:hover": { backgroundColor: "#979797" },
                        "&:disabled": {
                          backgroundColor: "#979797",
                        },
                      }
                    : {
                        width: "178px",
                        height: "46px",
                        backgroundColor: "background.bgLayoutB",
                        borderRadius: "10px",
                        color: "white",
                        fontFamily: "Poppins-Bold",
                        "&:hover": {
                          backgroundColor: "red",
                          boxShadow: 2,
                          transition: ".7s ease-in",
                        },
                        "&:disabled": {
                          backgroundColor: uploadMediaButton
                            ? "#979797"
                            : manipulationPermission && "background.bgLayoutB",
                          color: manipulationPermission && "white",
                        },
                      }
                }
              >
                Upload
                {uploading ? <CircularProgressLoader /> : ""}
              </Button>
            </Box>{" "}
          </Container>
        </CardContent>
      </Card>
    </div>
  );
};

export default Index;
