import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import EmailSideNav from "../emailSidenav/EmailSideNav";
import EmailBody from "../emailBody/EmailBody";
import { useDispatch, useSelector } from "../../../../redux/store";
import { getAllEmailTrackRecord } from "../../../../redux/slices/EmailTrackSlice";
import ToasterContext from "../../../../utils/context/toasterContext";

const drawerWidth = 240;

const Index = () => {
  const dispatch = useDispatch();
  let { emailTrackList } = useSelector((state) => state.emailTrackReducer);
  const fireToasterContext = useContext(ToasterContext);
  const [loader, setLoader] = useState(false);
  const [showDetailEmailBody, setShowEmailDetailBody] = useState();

  useEffect(() => {
    setLoader(true);
    dispatch(getAllEmailTrackRecord(handelApiResponse));
  }, []);

  const handelApiResponse = (data) => {
    if (data.status) {
      setLoader(false);
    } else {
      fireToasterContext.fireToasterHandler(false, "Something went wrong");
      setLoader(false);
    }
  };

  const showData = (data) => {
    setShowEmailDetailBody(data);
  };
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        gap: "10px",
        backgroundColor: "white",
        borderRadius: "10px",
      }}
    >
      <EmailSideNav
        loader={loader}
        record={emailTrackList}
        showData={showData}
      />

      <Box
        component="main"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, p: 2 }}
      >
        <EmailBody bodyData={showDetailEmailBody} />
      </Box>
    </Box>
  );
};

export default Index;
