import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  tableBodyTypos,
  StyledTableRow,
  userHeading,
  miniScrollBarStyles,
} from "../../../utils/muiComponentStyles";
import {
  tableCellBackgroundColor,
  tableIconBox,
  tableIconButton,
  tableIconButtonWithMargin,
} from "../../users/muiUserComponentStyles";
import Loader from "../../Loader/Loader";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import DeleteRecordInspacetionDialog from "./DeleteRecordInspacetion";

const inspectionPerformedArray = [
  { id: 1, name: "Footing" },
  { id: 2, name: "Foundation" },
  { id: 3, name: "Drain tile" },
  { id: 4, name: "Foundation water proofing" },
  { id: 5, name: "Foundation Insulation" },
  { id: 6, name: "Erosion Control" },
  { id: 7, name: "Rough Construction" },
  { id: 8, name: "Final Construction" },
  { id: 9, name: "Rough Electrical" },
  { id: 10, name: "Final Electrical" },
  { id: 11, name: "Underground Electrical" },
  { id: 12, name: "Electrical Service" },
  { id: 13, name: "Rough HVAC" },
  { id: 14, name: "Final HVAC" },
  { id: 15, name: "Final Plumbing" },
  { id: 16, name: "Rough Plumbing" },
  { id: 17, name: "Underground Plumbing" },
  { id: 18, name: "Plumbing Sewer Lateral" },
  { id: 19, name: "Building Insulation" },
  { id: 20, name: "Vapor Barrier" },
  { id: 21, name: "Final Occupancy Inspection" },
  { id: 22, name: "Temporary Occupancy Inspection" },
  { id: 23, name: "Gas Inspection" },
  { id: 24, name: "Other see notes" },
];

const RecordInspection = ({
  loading,
  manipulationPermission,
  inspectionsList,
  handleRecordInspection,
  handleAddNewRecord,
}) => {
  const [selectedRecord, setSelectedRecord] = useState();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleDeleteClick = (data) => {
    setSelectedRecord(data);
    setOpenDeleteDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedRecord(null);
  };
  const handleEditClick = (data) => {
    handleRecordInspection(data);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };
  return (
    <div>
      <Container sx={{ my: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2, mr: 1 }}>
          <Button
            disabled={manipulationPermission}
            disableRipple
            onClick={handleAddNewRecord}
            startIcon={<AddRoundedIcon sx={{ fontSize: "22px" }} />}
            sx={{
              fontSize: "17px",
              fontFamily: "Poppins-Bold",
              letterSpacing: "1.26px",
              lineHeight: "37.5px",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "transparent",
              },
              "&:disabled": {
                backgroundColor: "transparent",
                color: "background.bgLayoutB",
              },
            }}
          >
            Add Inspection Report
          </Button>
        </Box>
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            maxHeight: 350,
            borderRadius: "20px",
            [`::-webkit-scrollbar`]: {
              width: "0px",
              background: "transparent",
            },
            ...miniScrollBarStyles,
            border: "1px solid #E7E7E7",
          }}
        >
          <Table
            sx={{ overflowX: "auto" }}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead sx={tableCellBackgroundColor}>
              <TableRow>
                <TableCell sx={tableCellBackgroundColor}>
                  <Typography sx={userHeading} variant="userTableHeadingBold">
                    Date
                  </Typography>
                </TableCell>
                <TableCell sx={tableCellBackgroundColor}>
                  <Typography sx={userHeading} variant="userTableHeadingBold">
                    Inspection Performed
                  </Typography>
                </TableCell>
                <TableCell sx={tableCellBackgroundColor}>
                  <Typography sx={userHeading} variant="userTableHeadingBold">
                    Status
                  </Typography>
                </TableCell>
                <TableCell sx={tableCellBackgroundColor}></TableCell>
                <TableCell sx={tableCellBackgroundColor}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Loader />
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ) : inspectionsList.length < 1 ? (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">
                    {" "}
                    <Typography sx={tableBodyTypos} variant="tableBodyCellName">
                      No Recorded found
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ) : (
                [...inspectionsList]
                  ?.sort((a, b) => new Date(a.date) - new Date(b.date))
                  .map((row) => (
                    <StyledTableRow hover key={row.id}>
                      <TableCell>
                        <Typography
                          sx={tableBodyTypos}
                          variant="tableBodyCellName"
                        >
                          {formatDate(row.date)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={tableBodyTypos}
                          variant="tableBodyCellName"
                        >
                          {row.inspectionPerformed
                            .map((item) => {
                              for (
                                let i = 0;
                                i < inspectionPerformedArray.length;
                                i++
                              ) {
                                if (item === inspectionPerformedArray[i].id) {
                                  return inspectionPerformedArray[i].name;
                                }
                              }
                            })
                            .join(", ")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={tableBodyTypos}
                          variant="tableBodyCellName"
                        >
                          {row.inspectionStatus === null
                            ? "N/A"
                            : row.inspectionStatus}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Box sx={tableIconBox}>
                          <IconButton
                            sx={tableIconButton}
                            onClick={() => {
                              !manipulationPermission && handleEditClick(row);
                            }}
                          >
                            <EditOutlined sx={{ color: "white" }} />
                          </IconButton>

                          <IconButton
                            sx={tableIconButtonWithMargin}
                            onClick={() => {
                              !manipulationPermission && handleDeleteClick(row);
                            }}
                          >
                            <DeleteOutlineOutlined sx={{ color: "white" }} />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </StyledTableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      {openDeleteDialog && (
        <DeleteRecordInspacetionDialog
          openDeleteDialog={openDeleteDialog}
          selectedRecord={selectedRecord}
          handleCloseDialog={handleCloseDialog}
        />
      )}
    </div>
  );
};

export default RecordInspection;
