export const cardTitle = {
  ".MuiCardHeader-title": {
    fontSize: "16px",
    fontFamily: "Poppins-bold",
    color: "white",
  },
};

export const buildingPlacardTypograpghy = {
  "&.MuiTypography-root": {
    fontSize: "17px",
    fontFamily: "Poppins-Bold",
    letterSpacing: "1.26px",
    lineHeight: "37.5px",
  },
  "&.MuiTypography-subText": {
    fontSize: "16px",
    fontFamily: "Poppins",
    letterSpacing: "0.68px",
    lineHeight: "24px",
  },
  "&.MuiTypography-title": {
    fontSize: "16px",
    fontFamily: "Poppins-bold",
    letterSpacing: "0.68px",
    lineHeight: "24px",
  },
};

export const textfield = {
  "&.MuiFormControl-root": {
    width: "100%",
    "& .MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    "& .MuiInputBase-root": {
      fontFamily: "Poppins",
      fontSize: "15px",
      color: "#404041",
      borderRadius: "8px",
    },
    "& label": { color: "#404041" },
  },

  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
};

export const buildingPlacardContractorField = {
  "&.MuiFormControl-root": {
    width: "100%",
    padding: 0,
    "& .MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    "& .MuiInputBase-root": {
      fontFamily: "Poppins",
      fontSize: "15px",
      color: "#404041",
      // borderRadius: "8px",
    },
    "& label": { color: "#404041" },
    "& fieldset": { border: "none" },
  },

  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
};

export const buildingPlacardTextfieldDate = {
  "& .MuiFilledInput-root": {
    backgroundColor: "white",
    width: "100%",
    ".MuiFilledInput-input": {
      border: 0,
      width: "100%",
      height: "20px",
    },
    "& label": { color: "#404041" },
    "& fieldset": {
      borderColor: "white",
      width: "100%",
    },
    "&:hover fieldset": {
      borderColor: "white",
      backgroundColor: "transparent",
    },
    "&.Mui-focused": {
      color: "#404041",
      "& label": { color: "#404041" },
      "& .MuiFilledInput-input": {
        border: 0,
      },
      backgroundColor: "white",
      outline: "none",
    },
  },
};
export const villageCitySelector = {
  "& .MuiSelect-filled": {
    backgroundColor: "white",
    width: "100%",
    ".MuiSelect-filled": {
      border: 0,
      width: "100%",
      height: "20px",
    },
    "& label": { color: "#404041" },
    "& fieldset": {
      borderColor: "white",
      width: "100%",
    },
    "&:hover fieldset": {
      borderColor: "white",
      backgroundColor: "transparent",
    },
    "&.Mui-focused": {
      color: "#404041",
      "& label": { color: "#404041" },
      "& .MuiSelect-filled": {
        border: 0,
      },
      backgroundColor: "white",
      outline: "none",
    },
  },
};

export const tableHeadCellStyle = {
  "&.MuiTableCell-root": {
    color: "white",
    fontFamily: "Poppins-semibold",
  },
};

export const tableCell1stColumnDataStyle = {
  "&.MuiTableCell-root": {
    border: "1px solid lightgray",
    // color: "lightgray",
    width: "200px",
  },
};

export const tableCellDataStyle = {
  "&.MuiTableCell-root": {
    border: "1px solid lightgray",
    color: "lightgray",
    padding: 0,
  },
};

export const contractorCheckboxStyle = {
  "&.MuiCheckbox-root": {
    color: "#212121", // Change the color of the Checkbox when checked
    borderRadius: "50px",
  },
};

export const buildingPlacardFormSubmitButton = {
  display: "flex",
  alignItems: "center",
  backgroundColor: "#d52027",
  height: "100%",
  color: "white",
  padding: "10px",
  fontFamily: "Poppins-semibold",
  fontSize: "14px",
  // marginTop: "30px",
  borderRadius: 2,
  width: "200px",
  "&:hover": {
    backgroundColor: "red",
    transition: ".7s ease-in",
    boxShadow: 2,
  },
};

export const buildingPlacardDownloadButton = {
  display: "flex",
  alignItems: "center",
  height: "45px",
  color: "white",
  padding: "10px",
  fontFamily: "Poppins-semibold",
  fontSize: "14px",
  borderRadius: 2,
  width: "200px",
  "&:disabled": {
    backgroundColor: "grey",
  },
  "&:hover": {
    backgroundColor: "black",
    transition: ".7s ease-in",
    boxShadow: 2,
  },
  "&:active": {
    textDecoration: "none",
  },
};

export const buildingPlacardSiteInfoButton = {
  display: "flex",
  alignItems: "center",
  height: "45px",
  color: "white",
  padding: "10px",
  fontFamily: "Poppins-semibold",
  fontSize: "14px",
  borderRadius: 2,
  width: "200px",
  backgroundColor: "#32363F",
  "&:disabled": {
    backgroundColor: "grey",
  },
  "&:hover": {
    backgroundColor: "black",
    transition: ".7s ease-in",
    boxShadow: 2,
  },
  "&:active": {
    textDecoration: "none",
  },
};
