import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TableContainer,
  Paper,
  Box,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  InputAdornment,
} from "@mui/material";
import {
  repairFee,
  // saveButton,
  tableCellStyle,
  tableHeadStyle,
} from "./PermitFeeComponentStyle";
import {
  StyledTableRow,
  applicationCardStyle,
  miniScrollBarStyles,
  textfield,
} from "../../../utils/muiComponentStyles";
import { tableCellBackgroundColor } from "../../users/muiUserComponentStyles";
import { dispatch, useSelector } from "../../../redux/store";
import CircularProgressLoader from "../../../utils/CircularProgressLoader";
import {
  getApplicantStatus,
  getSingleApplicationData,
  updatePermitFee,
} from "../../../redux/slices/dashboard/applicantsSlice";
import ToasterContext from "../../../utils/context/toasterContext";
import PermitFeePdf from "./PermitFeePdf";
import { uploadPdf } from "../../../redux/slices/PdfDataSlice";
// import { pdf, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { sendEmailToApplicant } from "../../../redux/slices/dashboard/applicantsSlice";

const s3Client = new S3Client({
  endpoint: process.env.REACT_APP_SPACES_ENDPOINT,
  forcePathStyle: true,
  region: process.env.REACT_APP_SPACES_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_SPACES_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SPACES_SECRET_ACCESS_KEY,
  },
});

const Index = () => {
  const fireToasterContext = useContext(ToasterContext);
  const selectedApplicationInfo = JSON.parse(
    localStorage.getItem("selectedApplication")
  );
  const permissions = localStorage.getItem("permissions");
  const permissionArray = permissions ? permissions.split(",") : [];
  const [manipulationPermission, setManipulationPermission] = useState(false);
  const [emailButtonEnable, setEmailButtonEnable] = useState(false);
  const [pdfButtonEnable, setPdfButtonEnable] = useState(false);
  const [emailButtonLoader, setEmailButtonLoader] = useState(false);

  const applicationContainsFee = (name) => {
    let existingIndex = selectedApplicationInfo?.fees?.findIndex((el) => {
      return el.name == name;
    });
    if (existingIndex > -1) {
      return true;
    } else {
      return false;
    }
  };

  let { application } = useSelector((state) => state.applicantsReducer);

  const getCalculatedFee = (cost, percentage, minimumCost, totalSquareFeet) => {
    if (totalSquareFeet) {
      if (
        totalSquareFeet * 1 > 0 &&
        cost * 1 * (totalSquareFeet * 1) > minimumCost
      ) {
        let estimateCost = cost * 1 * (totalSquareFeet * 1);
        return Math.round(estimateCost);
      } else {
        let estimateCost = minimumCost;
        return Math.round(estimateCost);
      }
    } else {
      if (cost * 1 && cost * 1 > 0) {
        let calculatedCost = (cost * 1 * percentage * 1) / 100;
        if (calculatedCost >= minimumCost) {
          return Math.round(calculatedCost);
        } else {
          return Math.round(minimumCost);
        }
      } else {
        return Math.round(minimumCost);
      }
    }
  };

  const [fees, setFees] = useState([
    {
      name: "Residential Building Alterations Repairs",
      calculatedFee: getCalculatedFee(
        selectedApplicationInfo?.constructionCost,
        1,
        60
      ),
      manualFee: getCalculatedFee(
        selectedApplicationInfo?.constructionCost,
        1,
        60
      ),
      checked: applicationContainsFee(
        "Residential Building Alterations Repairs"
      ),
    },
    {
      name: "Residential Electrical Alterations  Repairs",
      calculatedFee: getCalculatedFee(
        selectedApplicationInfo?.electricalCost,
        1,
        60
      ),
      manualFee: getCalculatedFee(
        selectedApplicationInfo?.electricalCost,
        1,
        60
      ),
      checked: applicationContainsFee(
        "Residential Electrical Alterations  Repairs"
      ),
    },
    {
      name: "Residential HVAC Alterations/ Repairs",
      calculatedFee: getCalculatedFee(selectedApplicationInfo?.hvacCost, 1, 60),
      manualFee: getCalculatedFee(selectedApplicationInfo?.hvacCost, 1, 60),
      checked: applicationContainsFee("Residential HVAC Alterations/ Repairs"),
    },
    {
      name: "Residential Plumbing Alterations / Repairs",
      calculatedFee: getCalculatedFee(
        selectedApplicationInfo?.plumbingCost,
        1,
        60
      ),
      manualFee: getCalculatedFee(selectedApplicationInfo?.plumbingCost, 1, 60),
      checked: applicationContainsFee(
        "Residential Plumbing Alterations / Repairs"
      ),
    },
    {
      name: "Commercial Building Alterations / Repairs",
      calculatedFee: getCalculatedFee(
        selectedApplicationInfo?.constructionCost,
        1,
        150
      ),
      manualFee: getCalculatedFee(
        selectedApplicationInfo?.constructionCost,
        1,
        150
      ),
      checked: applicationContainsFee(
        "Commercial Building Alterations / Repairs"
      ),
    },
    {
      name: "Commercial Electrical Alterations / Repairs",
      calculatedFee: getCalculatedFee(
        selectedApplicationInfo?.electricalCost,
        1,
        100
      ),
      manualFee: getCalculatedFee(
        selectedApplicationInfo?.electricalCost,
        1,
        100
      ),
      checked: applicationContainsFee(
        "Commercial Electrical Alterations / Repairs"
      ),
    },
    {
      name: "Commercial HVAC Alterations / Repairs",
      calculatedFee: getCalculatedFee(
        selectedApplicationInfo?.hvacCost,
        1,
        100
      ),
      manualFee: getCalculatedFee(selectedApplicationInfo?.hvacCost, 1, 100),
      checked: applicationContainsFee("Commercial HVAC Alterations / Repairs"),
    },
    {
      name: "Commercial Plumbing Alterations / Repairs",
      calculatedFee: getCalculatedFee(
        selectedApplicationInfo?.plumbingCost,
        1,
        100
      ),
      manualFee: getCalculatedFee(
        selectedApplicationInfo?.plumbingCost,
        1,
        100
      ),
      checked: applicationContainsFee(
        "Commercial Plumbing Alterations / Repairs"
      ),
    },

    {
      name: "Permit to Start Early Construction - Commercial",
      calculatedFee: 400,
      manualFee: 400,
      checked: applicationContainsFee(""),
    },
    {
      name: "Permit to Start Early Construction - Residential",
      calculatedFee: 200,
      manualFee: 200,
      checked: applicationContainsFee(
        "Permit to Start Early Construction - Commercial"
      ),
    },
    {
      name: "Erosion Control Permit - Commercial",
      calculatedFee: 300,
      manualFee: 300,
      checked: applicationContainsFee("Erosion Control Permit - Commercial"),
    },
    {
      name: "Erosion Control Permit - Residential",
      calculatedFee: 200,
      manualFee: 200,
      checked: applicationContainsFee("Erosion Control Permit - Residential"),
    },
    {
      name: "Residential Plan Review - Duplex",
      calculatedFee: 200,
      manualFee: 200,
      checked: applicationContainsFee("Residential Plan Review - Duplex"),
    },
    {
      name: "Residential Plan Review - Single Family",
      calculatedFee: 100,
      manualFee: 100,
      checked: applicationContainsFee(
        "Residential Plan Review - Single Family"
      ),
    },
    {
      name: "Review of Plans - Wisconsin Dept. of Commerce - New Structures / Additions / Remodels",
      calculatedFee: 100,
      manualFee: 100,
      checked: applicationContainsFee(
        "Review of Plans - Wisconsin Dept. of Commerce - New Structures / Additions / Remodels"
      ),
    },
    {
      name: "Deck Permit",
      calculatedFee: 100,
      manualFee: 100,
      checked: applicationContainsFee("Deck Permit"),
    },
    {
      name: "Driveway Building Permit",
      calculatedFee: 60,
      manualFee: 60,
      checked: applicationContainsFee("Driveway Building Permit"),
    },
    {
      name: "Fence Permit",
      calculatedFee: 60,
      manualFee: 60,
      checked: applicationContainsFee("Fence Permit"),
    },
    {
      name: "Misc. Inspections",
      calculatedFee: 75,
      manualFee: 75,
      checked: applicationContainsFee("Misc. Inspections"),
    },
    {
      name: "Wisconsin Uniform Building Permit Seal",
      calculatedFee: 60,
      manualFee: 60,
      checked: applicationContainsFee("Wisconsin Uniform Building Permit Seal"),
    },
    {
      name: "Demolition - Commercial",
      calculatedFee: 100,
      manualFee: 100,
      checked: applicationContainsFee("Demolition - Commercial"),
    },
    {
      name: "Demolition - Residential	Fee",
      calculatedFee: 60,
      manualFee: 60,
      checked: applicationContainsFee("Demolition - Residential	Fee"),
    },
    {
      name: "Post Construction Storm Water Permit",
      calculatedFee: 125,
      manualFee: 125,
      checked: applicationContainsFee("Post Construction Storm Water Permit"),
    },
    {
      name: "Swimming Pool - Above Ground",
      calculatedFee: 60,
      manualFee: 60,
      checked: applicationContainsFee("Swimming Pool - Above Ground"),
    },
    {
      name: "Commercial Plan Review - New Structures 0 - 25,000 cubic feet",
      calculatedFee: 150,
      manualFee: 150,
      checked: applicationContainsFee(
        "Commercial Plan Review - New Structures 0 - 25,000 cubic feet"
      ),
    },
    {
      name: "Commercial Plan Review - New Structures 25,001 - 50,000 cubic feet",
      calculatedFee: 250,
      manualFee: 250,
      checked: applicationContainsFee(
        "Commercial Plan Review - New Structures 25,001 - 50,000 cubic feet"
      ),
    },
    {
      name: "Commercial Plan Review - Remodels 0 - 25,000 cubic feet",
      calculatedFee: 150,
      manualFee: 150,
      checked: applicationContainsFee(
        "Commercial Plan Review - Remodels 0 - 25,000 cubic feet"
      ),
    },
    {
      name: "Commercial Plan Review - Remodels 25,001 - 50,000 cubic feet",
      calculatedFee: 200,
      manualFee: 200,
      checked: applicationContainsFee(
        "Commercial Plan Review - Remodels 25,001 - 50,000 cubic feet"
      ),
    },
    {
      name: "Commercial Plan Review - Remodels 50,001 - 100,000 cubic feet",
      calculatedFee: 300,
      manualFee: 300,
      checked: applicationContainsFee(
        "Commercial Plan Review - Remodels 50,001 - 100,000 cubic feet"
      ),
    },
    {
      name: "Swimming Pool - In Ground",
      calculatedFee: 100,
      manualFee: 100,
      checked: applicationContainsFee("Swimming Pool - In Ground"),
    },
    {
      name: "Building Permit - Group 1",
      calculatedFee: getCalculatedFee(
        0.15,
        null,
        100,
        selectedApplicationInfo?.totalSquareFeet
      ),
      manualFee: getCalculatedFee(
        0.15,
        null,
        100,
        selectedApplicationInfo?.totalSquareFeet
      ),
      checked: applicationContainsFee("Building Permit - Group 1"),
    },
    {
      name: "Building Permit - Group 2",
      calculatedFee: getCalculatedFee(
        0.3,
        null,
        150,
        selectedApplicationInfo?.totalSquareFeet
      ),
      manualFee: getCalculatedFee(
        0.3,
        null,
        150,
        selectedApplicationInfo?.totalSquareFeet
      ),
      checked: applicationContainsFee("Building Permit - Group 2"),
    },
    {
      name: "Building Permit - Group 3",
      calculatedFee: getCalculatedFee(
        0.2,
        null,
        150,
        selectedApplicationInfo?.totalSquareFeet
      ),
      manualFee: getCalculatedFee(
        0.2,
        null,
        150,
        selectedApplicationInfo?.totalSquareFeet
      ),
      checked: applicationContainsFee("Building Permit - Group 3"),
    },
    {
      name: "Electrical Permit - Group 1",
      calculatedFee: getCalculatedFee(
        0.06,
        null,
        100,
        selectedApplicationInfo?.totalSquareFeet
      ),
      manualFee: getCalculatedFee(
        0.06,
        null,
        100,
        selectedApplicationInfo?.totalSquareFeet
      ),
      checked: applicationContainsFee("Electrical Permit - Group 1"),
    },
    {
      name: "Electrical Permit - Group 2",
      calculatedFee: getCalculatedFee(
        0.06,
        null,
        150,
        selectedApplicationInfo?.totalSquareFeet
      ),
      manualFee: getCalculatedFee(
        0.06,
        null,
        150,
        selectedApplicationInfo?.totalSquareFeet
      ),
      checked: applicationContainsFee("Electrical Permit - Group 2"),
    },
    {
      name: "Electrical Permit - Group 3",
      calculatedFee: getCalculatedFee(
        0.05,
        null,
        150,
        selectedApplicationInfo?.totalSquareFeet
      ),
      manualFee: getCalculatedFee(
        0.05,
        null,
        150,
        selectedApplicationInfo?.totalSquareFeet
      ),
      checked: applicationContainsFee("Electrical Permit - Group 3"),
    },
    {
      name: "HVAC Permit - Group 1",
      calculatedFee: getCalculatedFee(
        0.06,
        null,
        100,
        selectedApplicationInfo?.totalSquareFeet
      ),
      manualFee: getCalculatedFee(
        0.06,
        null,
        100,
        selectedApplicationInfo?.totalSquareFeet
      ),
      checked: applicationContainsFee("HVAC Permit - Group 1"),
    },
    {
      name: "HVAC Permit - Group 2",
      calculatedFee: getCalculatedFee(
        0.06,
        null,
        150,
        selectedApplicationInfo?.totalSquareFeet
      ),
      manualFee: getCalculatedFee(
        0.06,
        null,
        150,
        selectedApplicationInfo?.totalSquareFeet
      ),
      checked: applicationContainsFee("HVAC Permit - Group 2"),
    },
    {
      name: "HVAC Permit - Group 3",
      calculatedFee: getCalculatedFee(
        0.05,
        null,
        150,
        selectedApplicationInfo?.totalSquareFeet
      ),
      manualFee: getCalculatedFee(
        0.05,
        null,
        150,
        selectedApplicationInfo?.totalSquareFeet
      ),
      checked: applicationContainsFee("HVAC Permit - Group 3"),
    },
    {
      name: "Plumbing Permit - Group 1",
      calculatedFee: getCalculatedFee(
        0.06,
        null,
        100,
        selectedApplicationInfo?.totalSquareFeet
      ),
      manualFee: getCalculatedFee(
        0.06,
        null,
        100,
        selectedApplicationInfo?.totalSquareFeet
      ),
      checked: applicationContainsFee("Plumbing Permit - Group 1"),
    },
    {
      name: "Plumbing Permit - Group 2",
      calculatedFee: getCalculatedFee(
        0.06,
        null,
        150,
        selectedApplicationInfo?.totalSquareFeet
      ),
      manualFee: getCalculatedFee(
        0.06,
        null,
        150,
        selectedApplicationInfo?.totalSquareFeet
      ),
      checked: applicationContainsFee("Plumbing Permit - Group 2"),
    },
    {
      name: "Plumbing Permit - Group 3",
      calculatedFee: getCalculatedFee(
        0.05,
        null,
        150,
        selectedApplicationInfo?.totalSquareFeet
      ),
      manualFee: getCalculatedFee(
        0.05,
        null,
        150,
        selectedApplicationInfo?.totalSquareFeet
      ),
      checked: applicationContainsFee("Plumbing Permit - Group 3"),
    },
    {
      name: "Sprinkler permit- Group 1",
      calculatedFee: getCalculatedFee(
        0.06,
        null,
        100,
        selectedApplicationInfo?.totalSquareFeet
      ),
      manualFee: getCalculatedFee(
        0.06,
        null,
        100,
        selectedApplicationInfo?.totalSquareFeet
      ),
      checked: applicationContainsFee("Sprinkler permit- Group 1"),
    },
    {
      name: "Sprinkler permit- Group 2",
      calculatedFee: getCalculatedFee(
        0.06,
        null,
        150,
        selectedApplicationInfo?.totalSquareFeet
      ),
      manualFee: getCalculatedFee(
        0.06,
        null,
        150,
        selectedApplicationInfo?.totalSquareFeet
      ),
      checked: applicationContainsFee("Sprinkler permit- Group 2"),
    },
    {
      name: "Sprinkler permit- Group 3",
      calculatedFee: getCalculatedFee(
        0.05,
        null,
        150,
        selectedApplicationInfo?.totalSquareFeet
      ),
      manualFee: getCalculatedFee(
        0.05,
        null,
        150,
        selectedApplicationInfo?.totalSquareFeet
      ),
      checked: applicationContainsFee("Sprinkler permit- Group 3"),
    },
    {
      name: "Fee",
      calculatedFee: applicationContainsFee("Fee")
        ? selectedApplicationInfo?.fees.find((fee) => {
            if (fee.name === "Fee") return fee.manualFee;
          }).manualFee
        : "",
      manualFee: applicationContainsFee("Fee")
        ? selectedApplicationInfo?.fees.find((fee) => {
            if (fee.name === "Fee") return fee.manualFee;
          }).manualFee
        : "",
      checked: applicationContainsFee("Fee"),
    },

    // ... add other fee items here ...
  ]);
  const [finalArray, setFinalArray] = useState([]);

  useEffect(() => {
    if (!permissionArray.includes("manipulate application details")) {
      setManipulationPermission(true);
    } else {
      setManipulationPermission(false);
    }
    if (selectedApplicationInfo?.fees?.length > 0) {
      setEmailButtonEnable(true);
      setPdfButtonEnable(true);
      handlePdfData(selectedApplicationInfo?.fees);
    }
  }, []);

  useEffect(() => {
    // Create a map for quick lookups based on the fee name in the change array
    const changeMap = new Map(
      selectedApplicationInfo?.fees.map((item) => [item.name, item.manualFee])
    );
    // Update the initial array with values from the change array
    const updatedArray = fees.map((item) => {
      const modifiedItem = { ...item }; // Create a copy of the original item

      if (changeMap.has(item.name)) {
        modifiedItem.manualFee = changeMap.get(item.name);
      }

      return modifiedItem;
    });
    setFinalArray(updatedArray);
  }, [application]);

  const [loading, setLoading] = useState(false);

  const handlePdfData = async (localStorageFee) => {
    let pdfLink;
    const pdfBlob = await pdf(<PermitFeePdf data={localStorageFee} />).toBlob();
    const pdfBuffer = await new Response(pdfBlob).arrayBuffer();
    const params = {
      Bucket: process.env.REACT_APP_SPACES_BUCKET_NAME,
      Key: `${process.env.REACT_APP_ENVIRONMENT}/${
        selectedApplicationInfo?.id
      }/permit-fee-${selectedApplicationInfo?.id}-${Date.now()}.pdf`,
      Body: pdfBuffer,
      ACL: "public-read",
      ContentType: "application/pdf",
    };
    try {
      await s3Client.send(new PutObjectCommand(params));
      pdfLink = `${process.env.REACT_APP_SPACES_ENDPOINT}/${params.Bucket}/${params.Key}`;
      selectedApplicationInfo.permitFeePdfUrl = pdfLink;
      localStorage.setItem(
        "selectedApplication",
        JSON.stringify(selectedApplicationInfo)
      );
      const pdfData = {
        applicationId: selectedApplicationInfo?.id,
        applicationDetailPdfUrl: false,
        occupancyLetterPdfUrl: false,
        buildingPlacardPdfUrl: false,
        permitFeePdfUrl: pdfLink,
        recordedInspectionPdfUrl: false,
      };

      dispatch(uploadPdf(pdfData));
      // dispatch(emptyObject());
      setLoading(false);
    } catch (error) {
      console.error("Error uploading PDF:", error);
    }
  };

  const getCheckedFees = () => {
    return finalArray
      .filter((fee) => fee.checked)
      .map((fee) => {
        return (
          <Box
            key={fee.name}
            sx={{
              padding: "8px",
              margin: "5px",
              borderRadius: 50,
              backgroundColor: "#040F25",
              color: "white",
              // fontSize: "16px",
              fontFamily: "Poppins",
            }}
          >
            <Typography sx={{ px: 2 }}>
              {fee.name} = ${(fee.manualFee * 1)?.toFixed(2)}
            </Typography>
          </Box>
        );
      });
  };

  const getCurrentFeeForAccumolation = (fee) => {
    if (fee?.manualFee * 1 && fee?.manualFee * 1 > 0) {
      return fee.manualFee * 1;
    } else {
      return 0;
    }
  };

  const getTotalFee = () => {
    const totalFee = finalArray.reduce(
      (acc, fee) =>
        fee.checked ? acc + getCurrentFeeForAccumolation(fee) : acc,
      0
    );
    return totalFee;
  };

  const handleCheckboxChange = (index) => (event) => {
    setEmailButtonEnable(false);
    setPdfButtonEnable(false);
    const newFees = [...finalArray];
    newFees[index].checked = event.target.checked;
    setFees(newFees);
  };

  // const handleApiResponse = (data) => {
  //   getApiResponse(data);
  // };

  const handleSaveApiResponse = async (data, fees) => {
    setLoading(false);
    setEmailButtonEnable(true);
    setPdfButtonEnable(true);

    if (data.status) {
      selectedApplicationInfo.fees = fees;
      localStorage.setItem(
        "selectedApplication",
        JSON.stringify(selectedApplicationInfo)
      );

      fireToasterContext.fireToasterHandler(
        true,
        data?.message ? data?.message : "Permit Fees saved successfully"
      );
      handlePdfData(fees);
      dispatch(getApplicantStatus(selectedApplicationInfo.id));
      dispatch(getSingleApplicationData(selectedApplicationInfo.id));
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleSave = () => {
    setLoading(true);
    if (finalArray.some((el) => el.checked)) {
      let finalObj = {
        applicationId: selectedApplicationInfo.id,
        fees: [],
      };
      for (let i = 0; i < finalArray.length; i++) {
        let singleFee = finalArray[i];
        if (singleFee.checked) {
          finalObj.fees.push({
            name: singleFee.name,
            manualFee: Math.round(singleFee.manualFee),
            checked: singleFee.checked,
          });
        }
      }
      dispatch(updatePermitFee(finalObj, handleSaveApiResponse, finalObj.fees));
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please select atleast 1 fee plan for your application"
      );
      setLoading(false);
    }
  };

  const handleEmailApiResponse = (data) => {
    setEmailButtonLoader(false);
    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.message ? data.message : "Email sent successfully"
      );
      dispatch(getSingleApplicationData(selectedApplicationInfo?.id));
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleSendEmailToApplicant = () => {
    if (!emailButtonEnable) {
      fireToasterContext.fireToasterHandler(
        false,
        "Please save information of permit fee first!"
      );
      return;
    }
    setEmailButtonLoader(true);
    let finalData = {
      applicationId: selectedApplicationInfo?.id,
      applicationDetail: false,
      permitFee: true,
      buildingPlacard: false,
      occupancyLetter: false,
      recordedInspection: false,
      message: "",
    };
    dispatch(sendEmailToApplicant(finalData, handleEmailApiResponse));
  };

  const handleChangeManualFee = (index, event) => {
    let tempFees = [...finalArray];
    const inputValue = event.target.value;

    // Allow only numbers and format to two decimal places
    // const regex = /^\d*\.?\d{0,2}$/;

    // Validate input to allow only numeric characters
    const numericInput = inputValue.replace(/\D/g, ""); // Remove non-numeric characters

    // if (regex.test(inputValue) || inputValue === "") {
    //   // Round the value to two decimal places
    //   const roundedValue = parseFloat(inputValue).toFixed(2);

    // Update the manualFee property with the rounded value
    tempFees[index].manualFee = numericInput;
    setFees(tempFees);
    setFinalArray(tempFees);
    setPdfButtonEnable(false);
    setEmailButtonEnable(false);
    // }
  };

  return (
    <>
      <Box component="div">
        {/* <PDFViewer style={{ height: "100vh", width: "100%" }}>
          <PermitFeePdf data={finalArray} />
        </PDFViewer> */}
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            maxHeight: 550,
            border: "1px solid #E7E7E7",
            [`::-webkit-scrollbar`]: {
              width: "0px",
              background: "transparent",
            },
            ...miniScrollBarStyles,
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow sx={applicationCardStyle}>
                <TableCell sx={{ ...tableCellBackgroundColor, width: "40px" }}>
                  <Typography sx={tableHeadStyle}>Fee Name</Typography>
                </TableCell>

                <TableCell sx={tableHeadStyle}>
                  <Typography sx={{ ...tableHeadStyle, m: 0 }}>
                    {" "}
                    Calculated Fee
                  </Typography>
                </TableCell>
                <TableCell sx={{ ...tableHeadStyle, m: 0 }}>
                  <Typography sx={tableHeadStyle}> Manual Fee</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {finalArray?.map((fee, index) => (
                <StyledTableRow key={index}>
                  <TableCell sx={{ width: "450px", ...tableCellStyle }}>
                    <FormControlLabel
                      disabled={manipulationPermission}
                      control={
                        <Checkbox
                          checked={fee.checked}
                          onChange={handleCheckboxChange(index)}
                          icon={
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 36 36"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.5"
                                y="0.5"
                                width="35"
                                height="35"
                                rx="9.5"
                                fill="white"
                                stroke="#979797"
                              />
                            </svg>
                          }
                          checkedIcon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              viewBox="0 0 36 36"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9L0 27C0 29.3869 0.948211 31.6761 2.63604 33.364C4.32387 35.0518 6.61305 36 9 36H27C29.3869 36 31.6761 35.0518 33.364 33.364C35.0518 31.6761 36 29.3869 36 27V9C36 6.61305 35.0518 4.32387 33.364 2.63604C31.6761 0.948211 29.3869 0 27 0H9ZM27.0077 13.4203C27.3279 13.0203 27.4761 12.5095 27.4197 12.0003C27.3632 11.491 27.1068 11.025 26.7069 10.7049C26.3069 10.3847 25.7961 10.2365 25.2868 10.2929C24.7776 10.3493 24.3116 10.6057 23.9914 11.0057L14.8783 22.4074L11.2294 19.6714C11.0268 19.5195 10.7963 19.4089 10.5509 19.3461C10.3056 19.2832 10.0503 19.2693 9.79954 19.3051C9.54883 19.3409 9.30762 19.4258 9.08969 19.5548C8.87176 19.6838 8.68139 19.8545 8.52943 20.0571C8.37747 20.2598 8.26691 20.4903 8.20405 20.7357C8.1412 20.981 8.12728 21.2363 8.1631 21.487C8.19891 21.7377 8.28376 21.979 8.4128 22.1969C8.54183 22.4148 8.71253 22.6052 8.91514 22.7571L14.058 26.6143C14.4588 26.9153 14.961 27.0485 15.4583 26.9856C15.9555 26.9228 16.4088 26.6688 16.722 26.2774L27.0077 13.4203Z"
                                fill="#040F25"
                              />
                            </svg>
                          }
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontFamily: "Poppins",
                            letterSpacing: "0.68px",
                            lineHeight: "24px",
                            ml: 1,
                          }}
                        >
                          {fee.name}
                        </Typography>
                      }
                    />
                  </TableCell>
                  {fee.checked ? (
                    <>
                      <TableCell sx={tableCellStyle}>
                        <Typography sx={repairFee}>
                          ${Math.round(fee.calculatedFee * 1)?.toFixed(2)}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" sx={tableCellStyle}>
                        <TextField
                          onKeyDown={(e) => {
                            if (
                              e.key === "." ||
                              e.key === "e" ||
                              e.key === "E"
                            ) {
                              e.preventDefault();
                            }
                          }}
                          step=".01"
                          disabled={manipulationPermission}
                          onChange={(event) => {
                            handleChangeManualFee(index, event);
                          }}
                          value={
                            fee?.manualFee * 1 ? fee?.manualFee * 1 : undefined
                          }
                          onWheel={() => document.activeElement.blur()}
                          name={`manual fee${index + 1}`}
                          id="outlined-required"
                          label="Manual Fee"
                          type="number"
                          sx={{
                            ...textfield,
                            backgroundColor: "white",
                            borderRadius: 2,
                            width: "180px",
                            // height: "200px",
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{ min: 1 }}
                        />
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell sx={tableCellStyle}></TableCell>
                      <TableCell sx={tableCellStyle}></TableCell>
                    </>
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid
          container
          sx={{
            p: 3,
            pb: 0,
          }}
        >
          <Grid item xs={12} sm={6}>
            <Typography sx={{ fontSize: "20px", fontFamily: "Poppins-Bold" }}>
              Summary
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              sx={{
                fontSize: "20px ",
                fontFamily: "Poppins-Bold",
                display: "flex",
                gap: "10px",
                ml: 1.5,
              }}
            >
              Total Fee:{" "}
              <Typography
                sx={{
                  fontSize: "20px",
                  fontFamily: "Poppins-Bold",
                  color: "#D52027",
                }}
              >
                {/* ${(getTotalFee() * 1)?.toFixed(2)} */}
                {Math.round(getTotalFee())
                  ?.toFixed(2)
                  ?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                  })}
              </Typography>
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ p: 3, pb: 0 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {finalArray?.some((fee) => fee.checked) && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {getCheckedFees()}
              </Box>
            )}
          </Grid>
        </Grid>
        <Box
          sx={{
            my: 2,
            mx: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "10px",
            "& > :not(:first-of-type)": {
              m: 0,
            },
            flexWrap: "wrap",
            [`@media screen and (max-width: 1024px)`]: {
              justifyContent: "center",
            },
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: "286px",
              height: "46px",
              fontFamily: "Poppins-Bold",
              backgroundColor: "#32363F",
              borderRadius: "10px",
              color: "white",
              [`@media screen and (max-width: 1024px)`]: {
                width: "100%",
              },
              "&:hover": {
                backgroundColor: "black",
                boxShadow: 2,
                transition: ".7s ease-in",
              },
              "&:disabled": {
                backgroundColor: "#32363F",
                color: "white",
              },
            }}
            disabled={manipulationPermission}
            onClick={handleSendEmailToApplicant}
          >
            Email to Applicant
            {emailButtonLoader ? <CircularProgressLoader /> : ""}
          </Button>

          {pdfButtonEnable ? (
            <PDFDownloadLink
              document={<PermitFeePdf data={selectedApplicationInfo.fees} />}
              fileName={`Permit-fee-${
                selectedApplicationInfo?.id
              }-${Date.now()}`}
            >
              <Button
                variant="contained"
                sx={{
                  width: "286px",
                  height: "46px",
                  fontFamily: "Poppins-Bold",
                  backgroundColor: "#32363F",
                  borderRadius: "10px",
                  color: "white",
                  [`@media screen and (max-width: 1024px)`]: {
                    width: "100%",
                  },
                  "&:hover": {
                    backgroundColor: "black",
                    boxShadow: 2,
                    transition: ".7s ease-in",
                  },
                }}
              >
                Download Permit Fee
              </Button>
            </PDFDownloadLink>
          ) : (
            <Button
              disabled={manipulationPermission}
              variant="contained"
              sx={{
                width: "286px",
                height: "46px",
                fontFamily: "Poppins-Bold",
                backgroundColor: "#32363F",
                borderRadius: "10px",
                color: "white",
                [`@media screen and (max-width: 1024px)`]: {
                  width: "100%",
                },
                "&:hover": {
                  backgroundColor: "black",
                  boxShadow: 2,
                  transition: ".7s ease-in",
                },
                "&:disabled": { backgroundColor: "#32363F", color: "white" },
              }}
              onClick={() =>
                fireToasterContext.fireToasterHandler(
                  false,
                  "Please save information of permit fee first!"
                )
              }
            >
              Download Permit Fee
            </Button>
          )}

          <Button
            onClick={handleSave}
            variant="contained"
            disabled={loading || manipulationPermission}
            sx={
              loading
                ? {
                    width: "200px",
                    height: "46px",
                    background: "#979797",
                    borderRadius: "10px",
                    color: "white",
                    [`@media screen and (max-width: 1024px)`]: {
                      width: "100%",
                    },
                    "&:hover": { backgroundColor: "#979797" },
                    "&:disabled": {
                      backgroundColor: "#979797",
                      color: "white",
                    },
                  }
                : {
                    width: "200px",
                    height: "46px",
                    [`@media screen and (max-width: 1024px)`]: {
                      width: "100%",
                    },
                    backgroundColor: "background.bgLayoutB",
                    borderRadius: "10px",
                    color: "white",
                    fontFamily: "Poppins-Bold",
                    "&:hover": {
                      backgroundColor: "red",
                      boxShadow: 2,
                      transition: ".7s ease-in",
                    },
                    "&:disabled": {
                      backgroundColor: "background.bgLayoutB",
                      color: "white",
                    },
                  }
            }
          >
            Save
            {loading ? <CircularProgressLoader /> : ""}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Index;
