//GLOBLE COLORS//

const BACKGROUND_LIGHT = "#f6f5f5";
const BACKGROUND_DARK = "#526D82";

//Sidebar Color
const LAYOUT_A_LIGHT = "#040F25";
const LAYOUT_A_DARK = "#27374D";

//Selected Sidebar Tab Color
const LAYOUT_B_LIGHT = "#D52027";
const LAYOUT_B_DARK = "#116D6E";

//Selected Sidebar Tab Color
const SIDEBAR_HOVER_LIGHT = "#F5F5F5";
const SIDEBAR_HOVER_DARK = "#526D82";

const FONT_COLOR_LIGHT = "#7D8EA3";
const FONT_COLOR_DARK = "#cad5e3";

const TABLE_FONT_COLOR_LIGHT = "#404041";
const TABLE_FONT_COLOR_DARK = "#FFFFFF";

const TABLE_ODD_ROW_LIGHT = "#F5F5F5";
const TABLE_ODD_ROW_DARK = "#c6c6c6";
const TABLE_EVEN_ROW_LIGHT = "white";
const TABLE_EVEN_ROW_DARK = "#EDEDED";

const TABLE_ROW_HOVER_LIGHT = "#EDEDED";
const TABLE_ROW_HOVER_DARK = "#92a3ad";

const SEARCH_BAR_BG_LIGHT = "white";
const SEARCH_BAR_BG_DARK = "#526D82";

const TABLE_CHIPS_LIGHT = "#D52027";
const TABLE_CHIPS_DARK = "#525e70";

//DASHBOARD COLORS//

const DASHBOARD_WELCOME_HEADING_LIGHT = "#040F25";
const DASHBOARD_WELCOME_HEADING_DARK = "#040F25";

const DASHBOARD_ALARM_CARD_BG_LIGHT = "white";
const DASHBOARD_ALARM_CARD_BG_DARK = "#040F25";

const DASHBOARD_ROOMS_LOGS_CARD_BG_LIGHT = "white";
const DASHBOARD_ROOMS_LOGS_CARD_BG_DARK = "#040F25";

export const lightTheme = {
  palette: {
    mode: "light",
    background: {
      default: BACKGROUND_LIGHT,
      bgLayoutA: LAYOUT_A_LIGHT,
      bgLayoutB: LAYOUT_B_LIGHT,
      bgTableChip: TABLE_CHIPS_LIGHT,
      bgSearchBar: SEARCH_BAR_BG_LIGHT,
      bgTableOddRow: TABLE_ODD_ROW_LIGHT,
      bgSidebarHover: SIDEBAR_HOVER_LIGHT,
      bgTableEvenRow: TABLE_EVEN_ROW_LIGHT,
      bgTableRowHover: TABLE_ROW_HOVER_LIGHT,
      dashboardAlarmCardBg: DASHBOARD_ALARM_CARD_BG_LIGHT,
      dashboardRoomLogCardBg: DASHBOARD_ROOMS_LOGS_CARD_BG_LIGHT,
      main: "#D52027",
      test: "green",
    },
    primary: {
      main: "#D52027",
      fontColor: FONT_COLOR_LIGHT,
      tableFontColor: TABLE_FONT_COLOR_LIGHT,
      dashboardWelcomeHeading: DASHBOARD_WELCOME_HEADING_LIGHT,
      test: "#d40b8d",
    },
  },
};

export const darkTheme = {
  palette: {
    mode: "dark",
    background: {
      default: BACKGROUND_DARK,
      bgLayoutA: LAYOUT_A_DARK,
      bgLayoutB: LAYOUT_B_DARK,
      bgTableChip: TABLE_CHIPS_DARK,
      bgSearchBar: SEARCH_BAR_BG_DARK,
      bgTableOddRow: TABLE_ODD_ROW_DARK,
      bgSidebarHover: SIDEBAR_HOVER_DARK,
      bgTableEvenRow: TABLE_EVEN_ROW_DARK,
      bgTableRowHover: TABLE_ROW_HOVER_DARK,
      dashboardAlarmCardBg: DASHBOARD_ALARM_CARD_BG_DARK,
      dashboardRoomLogCardBg: DASHBOARD_ROOMS_LOGS_CARD_BG_DARK,
      main: "purple",
      test: "#f9dcdc",
    },
    primary: {
      main: "#116D6E",
      fontColor: FONT_COLOR_DARK,
      tableFontColor: TABLE_FONT_COLOR_DARK,
      dashboardWelcomeHeading: DASHBOARD_WELCOME_HEADING_DARK,
      test: "#ee5c42",
    },
  },
};
