import React, { useContext, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Box,
  Button,
  IconButton,
  useMediaQuery,
  Autocomplete,
} from "@mui/material";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import {
  downloadButtonWrapper,
  downloadButtonWrapperVideo,
  downloadMediaButton,
  recordInspectionTypo,
  textfield,
  textfieldDate,
} from "../../../utils/muiComponentStyles";
import {
  createNewInspection,
  getAllInspections,
} from "../../../redux/slices/recordedInspectionsSlice";
import { dispatch } from "../../../redux/store";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ToasterContext from "../../../utils/context/toasterContext";
import CircularProgressLoader from "../../../utils/CircularProgressLoader";
import {
  getApplicantStatus,
  getSingleApplicationData,
  uploadRecordInspectionMedia,
} from "../../../redux/slices/dashboard/applicantsSlice";
import PdfPreview from "./../../../assets/application-media-previews/wipermit-pdf-thumbnail.png";
import DocsPreview from "./../../../assets/application-media-previews/wipermit-docs-thumbnail.jpg";
import PowerPointPreview from "./../../../assets/application-media-previews/wipermit-powerpoint-thumbnail.jpg";
import ExcelPreview from "./../../../assets/application-media-previews/wipermit-xls-thumbnail.jpg";

const inspectionStatus = [
  { id: 1, name: "Completed" },
  { id: 2, name: "Failed" },
  { id: 3, name: "Approved with Conditions noted" },
];

const inspectionPerformed = [
  { id: 1, name: "Footing" },
  { id: 2, name: "Foundation" },
  { id: 3, name: "Drain tile" },
  { id: 4, name: "Foundation water proofing" },
  { id: 5, name: "Foundation Insulation" },
  { id: 6, name: "Erosion Control" },
  { id: 7, name: "Rough Construction" },
  { id: 8, name: "Final Construction" },
  { id: 9, name: "Rough Electrical" },
  { id: 10, name: "Final Electrical" },
  { id: 11, name: "Underground Electrical" },
  { id: 12, name: "Electrical Service" },
  { id: 13, name: "Rough HVAC" },
  { id: 14, name: "Final HVAC" },
  { id: 15, name: "Final Plumbing" },
  { id: 16, name: "Rough Plumbing" },
  { id: 17, name: "Underground Plumbing" },
  { id: 18, name: "Plumbing Sewer Lateral" },
  { id: 19, name: "Building Insulation" },
  { id: 20, name: "Vapor Barrier" },
  { id: 21, name: "Final Occupancy Inspection" },
  { id: 22, name: "Temporary Occupancy Inspection" },
  { id: 23, name: "Gas Inspection" },
  { id: 24, name: "Other See Notes" },
];

const s3Client = new S3Client({
  endpoint: process.env.REACT_APP_SPACES_ENDPOINT,
  forcePathStyle: true,
  region: process.env.REACT_APP_SPACES_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_SPACES_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SPACES_SECRET_ACCESS_KEY,
  },
});

const Index = ({ manipulationPermission, handleBackButton }) => {
  const [loading, setLoading] = useState(false);
  let applicationData = JSON.parse(localStorage.getItem("selectedApplication"));
  const matches = useMediaQuery("(max-width:600px)");
  const [inspectionDetailStatus, setInspectionDetailStatus] = useState();
  const [recordInspectionDetails, setRecordInspectionDetails] = useState({
    projectAddress: applicationData?.projectAddress,
    date: null,
    time: null,
    inspectionNotes: null,
    inspectionStatus: null,
    inspectionPerformed: null,
    inspectorName: "Ryan Lindsey ",
    inspectorCredentials: 980222,
  });
  const [inspectionIds, setInspectionIds] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const fireToasterContext = useContext(ToasterContext);

  const handleChange = (event) => {
    const { id, value, name } = event.target;

    setRecordInspectionDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    // Clear the error message for the field being edited
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [id]: undefined,
    }));
  };

  const handleAddPhotoClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };

  function checkFileExt(filename) {
    const ext = filename.split(".");
    return ext[ext.length - 1];
  }

  // const onDropHandler = (ev) => {
  //   ev.preventDefault();
  //   let file = "";
  //   if (ev.dataTransfer.items) {
  //     // Use DataTransferItemList interface to access the file(s)
  //     file = [...ev.dataTransfer.items]
  //       ?.find((item) => item.kind === "file")
  //       ?.getAsFile();
  //   } else {
  //     // Use DataTransfer interface to access the file(s)
  //     file = ev.dataTransfer.files;
  //   }
  //   console.log("file", file);
  //   handleSelectFiles(file);
  //   // setSelectedMedia([...selectedMedia, file]);
  // };

  // const handleSelectFiles = async (files) => {
  //   if (files) {
  //     let tempPreviewImages = [...previewImages];
  //     let tempSelectedMedia = [...selectedMedia];
  //     const selectedFile = files;

  //     let fileExtension = checkFileExt(files.name);
  //     fileExtension = fileExtension?.toLowerCase();
  //     if (
  //       fileExtension == "png" ||
  //       fileExtension == "jpg" ||
  //       fileExtension == "jpeg" ||
  //       fileExtension == "webp"
  //     ) {
  //       const imageUrl = URL.createObjectURL(selectedFile);

  //       tempPreviewImages.push({
  //         type: "Image",
  //         url: imageUrl,
  //         name: selectedFile.name,
  //       });
  //       setPreviewImages(tempPreviewImages);

  //       tempSelectedMedia.push({
  //         file: selectedFile,
  //         fileId: previewImages?.length ? previewImages?.length + 1 : 0,
  //         type: "Image",
  //         name: selectedFile.name,
  //       });
  //       setSelectedMedia(tempSelectedMedia);
  //     } else if (fileExtension == "docx" || fileExtension == "doc") {
  //       tempPreviewImages.push({
  //         type: "Docs",
  //         url: DocsPreview,
  //         name: selectedFile.name,
  //       });
  //       setPreviewImages(tempPreviewImages);
  //       tempSelectedMedia.push({
  //         file: selectedFile,
  //         fileId: previewImages?.length ? previewImages?.length + 1 : 0,
  //         type: "Docs",
  //       });
  //       setSelectedMedia(tempSelectedMedia);
  //     } else if (
  //       fileExtension == "csv" ||
  //       fileExtension == "xls" ||
  //       fileExtension == "xlsx"
  //     ) {
  //       tempPreviewImages.push({
  //         type: "Excel",
  //         url: ExcelPreview,
  //         name: selectedFile.name,
  //       });
  //       setPreviewImages(tempPreviewImages);
  //       tempSelectedMedia.push({
  //         file: selectedFile,
  //         fileId: previewImages?.length ? previewImages?.length + 1 : 0,
  //         type: "Excel",
  //       });
  //       setSelectedMedia(tempSelectedMedia);
  //     } else if (fileExtension == "pdf") {
  //       tempPreviewImages.push({
  //         type: "Pdf",
  //         url: PdfPreview,
  //         name: selectedFile.name,
  //       });
  //       setPreviewImages(tempPreviewImages);
  //       tempSelectedMedia.push({
  //         file: selectedFile,
  //         fileId: previewImages?.length ? previewImages?.length + 1 : 0,
  //         type: "Pdf",
  //       });
  //       setSelectedMedia(tempSelectedMedia);
  //     } else if (fileExtension == "pptx" || fileExtension == "ppt") {
  //       tempPreviewImages.push({
  //         type: "Powerpoint",
  //         url: PowerPointPreview,
  //         name: selectedFile.name,
  //       });
  //       setPreviewImages(tempPreviewImages);
  //       tempSelectedMedia.push({
  //         file: selectedFile,
  //         fileId: previewImages?.length ? previewImages?.length + 1 : 0,
  //         type: "Powerpoint",
  //       });
  //       setSelectedMedia(tempSelectedMedia);
  //     } else {
  //       fireToasterContext.fireToasterHandler(false, "Invalid File");
  //     }
  //   }
  // };
  const onDropHandler = (ev) => {
    ev.preventDefault();

    let files = [];

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === "file") {
          const file = ev.dataTransfer.items[i].getAsFile();
          if (file) files.push(file); // Add each file to the array
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      files = Array.from(ev.dataTransfer.files); // Get all files
    }

    if (files.length > 0) {
      handleSelectFiles(files); // Pass all files to handleSelectFiles
    }

    console.log("files", files); // Debugging: log all selected files
  };

  const handleSelectFiles = async (files) => {
    if (files && files.length) {
      let tempPreviewImages = [...previewImages];
      let tempSelectedMedia = [...selectedMedia];

      files.forEach((file) => {
        let fileExtension = checkFileExt(file.name).toLowerCase();

        if (["png", "jpg", "jpeg", "webp"].includes(fileExtension)) {
          const imageUrl = URL.createObjectURL(file);

          tempPreviewImages.push({
            type: "Image",
            url: imageUrl,
            name: file.name,
          });
          tempSelectedMedia.push({
            file,
            fileId: previewImages?.length ? previewImages?.length + 1 : 0,
            type: "Image",
            name: file.name,
          });
        } else if (["docx", "doc"].includes(fileExtension)) {
          tempPreviewImages.push({
            type: "Docs",
            url: DocsPreview,
            name: file.name,
          });
          tempSelectedMedia.push({
            file,
            fileId: previewImages?.length ? previewImages?.length + 1 : 0,
            type: "Docs",
          });
        } else if (["csv", "xls", "xlsx"].includes(fileExtension)) {
          tempPreviewImages.push({
            type: "Excel",
            url: ExcelPreview,
            name: file.name,
          });
          tempSelectedMedia.push({
            file,
            fileId: previewImages?.length ? previewImages?.length + 1 : 0,
            type: "Excel",
          });
        } else if (fileExtension === "pdf") {
          tempPreviewImages.push({
            type: "Pdf",
            url: PdfPreview,
            name: file.name,
          });
          tempSelectedMedia.push({
            file,
            fileId: previewImages?.length ? previewImages?.length + 1 : 0,
            type: "Pdf",
          });
        } else if (["pptx", "ppt"].includes(fileExtension)) {
          tempPreviewImages.push({
            type: "Powerpoint",
            url: PowerPointPreview,
            name: file.name,
          });
          tempSelectedMedia.push({
            file,
            fileId: previewImages?.length ? previewImages?.length + 1 : 0,
            type: "Powerpoint",
          });
        } else if (
          ["avi", "mov", "wmv", "mkv", "flv", "mp4"].includes(fileExtension)
        ) {
          const videoUrl = URL.createObjectURL(file);
          console.log("video url", videoUrl);
          tempPreviewImages.push({
            type: "Video",
            url: videoUrl,
            name: file.name,
          });
          tempSelectedMedia.push({
            file,
            fileId: previewImages?.length ? previewImages?.length + 1 : 0,
            type: "Video",
          });
        } else {
          fireToasterContext.fireToasterHandler(false, "Invalid File");
        }
      });

      setPreviewImages(tempPreviewImages);
      setSelectedMedia(tempSelectedMedia);
    }
  };

  const handleUploadMediaResponse = async (data) => {
    setLoading(false);
    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        "Recorded inspection Created successfully"
      );
      await dispatch(getAllInspections());
      handleBackButton();
    } else {
      fireToasterContext.fireToasterHandler(false, "Something went wrong");
    }
  };

  const handleUploadFilesToSpaces = async (inspectionId, selectedMedia) => {
    try {
      let allMediaArr = previewImages.filter((el) => {
        return el.url
          .toString()
          .startsWith(process.env.REACT_APP_SPACES_ENDPOINT);
      });

      // Loop through each selected media file
      for (let i = 0; i < selectedMedia.length; i++) {
        const params = {
          Bucket: process.env.REACT_APP_SPACES_BUCKET_NAME,
          Key: `${process.env.REACT_APP_ENVIRONMENT}/${
            applicationData.id
          }/RecordInspection/${inspectionId}/${
            selectedMedia[i].file.name.split(" ").join("-").split(".")[0]
          }-${Date.now()}.${checkFileExt(selectedMedia[i].file.name)}`,
          Body: selectedMedia[i].file,
          ACL: "public-read",
        };
        // Upload the media file to the S3 bucket
        await s3Client.send(new PutObjectCommand(params));
        // Add the uploaded media URL to the array
        allMediaArr.push({
          url: `${process.env.REACT_APP_SPACES_ENDPOINT}/${params.Bucket}/${params.Key}`,
          type: selectedMedia[i].type,
        });
      }

      // Dispatch the uploadMedia action with the necessary parameters
      dispatch(
        uploadRecordInspectionMedia(
          inspectionId,
          allMediaArr,
          handleUploadMediaResponse
        )
      );
    } catch (error) {
      setLoading(false);
    }
  };

  const handleRemovePreview = async (index) => {
    const updatedPreviews = previewImages.filter((_, i) => i !== index);
    setPreviewImages(updatedPreviews);

    const updatedSelectedMedia = selectedMedia.filter((_, i) => i !== index);
    setSelectedMedia(updatedSelectedMedia);
  };

  const handleApiRes = (data) => {
    if (data.status) {
      if (selectedMedia.length < 1) {
        fireToasterContext.fireToasterHandler(
          true,
          "Recorded inspection Created successfully"
        );
        dispatch(getSingleApplicationData(applicationData?.id));
        dispatch(getApplicantStatus(applicationData.id));
        handleBackButton();
      } else {
        handleUploadFilesToSpaces(data.data.id, selectedMedia);
      }
    } else {
      fireToasterContext.fireToasterHandler(false, data?.message);
    }
  };

  const handleSave = () => {
    const errors = {};
    if (!recordInspectionDetails.projectAddress)
      errors.projectAddress = "Project Address is required.";
    // if (!recordInspectionDetails.inspectionNotes)
    //   errors.inspectionNotes = "Inspection Notes is required.";
    if (!recordInspectionDetails.date) errors.date = "Date is required.";
    if (inspectionIds.length < 1)
      errors.inspectionPerformed = "Inspection Performed is required.";
    if (!recordInspectionDetails.time) errors.time = "Time is required.";
    if (!inspectionDetailStatus)
      errors.inspectionStatus = "Inspection Status is required.";
    if (!recordInspectionDetails.inspectorName)
      errors.inspectorName = "Inspector name is required.";
    if (!recordInspectionDetails.inspectorCredentials)
      errors.inspectorCredentials = "State credentials is required.";

    setValidationErrors(errors);

    let applicationId = JSON.parse(localStorage.getItem("selectedApplication"));
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      const finalObj = {
        applicationId: applicationId.id,
        projectAddress: recordInspectionDetails.projectAddress,
        date: recordInspectionDetails.date,
        time: recordInspectionDetails.time,
        inspectionPerformed: inspectionIds,
        inspectionNotes: recordInspectionDetails.inspectionNotes,
        inspectionStatus: inspectionDetailStatus,
        inspectorInformation: recordInspectionDetails.inspectorInformation,
        inspectorName: recordInspectionDetails.inspectorName,
        inspectorCredentials: recordInspectionDetails.inspectorCredentials,
      };
      dispatch(createNewInspection(finalObj, handleApiRes));
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill all the Required Fields"
      );
    }
  };

  return (
    <div>
      <Container sx={{ my: 2 }}>
        <Box sx={{ mb: 1 }}>
          <Typography sx={recordInspectionTypo}>Inspection Report</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="date"
              label="Date"
              name="date"
              onChange={handleChange}
              error={Boolean(validationErrors.date)}
              helperText={validationErrors.date}
              disableUnderline
              fullWidth
              sx={textfieldDate}
              // label="Inspetion Date"
              inputProps={{
                max: new Date().toISOString().split("T")[0],
              }}
              type="date"
              variant="filled"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                disableUnderline: true,
                readOnly: manipulationPermission,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="time"
              label="Time"
              name="time"
              sx={textfield}
              onChange={handleChange}
              error={Boolean(validationErrors.time)}
              helperText={validationErrors.time}
              type="time"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{ readOnly: manipulationPermission }}
            />
          </Grid>

          {/* <Grid item xs={6}>
            <FormControl
              fullWidth
              onClick={() => {
                const inputElement = hourRef.current.querySelector("input");
                if (inputElement) {
                  inputElement.dispatchEvent(
                    new MouseEvent("mousedown", { bubbles: true })
                  );
                }
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "10px",
                border: "1px solid #c4c4c4",
                borderRadius: "8px",
              }}
            >
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  position: "relative",
                  marginBottom: "32px",
                  fontFamily: "Poppins",
                }}
              >
                Select Time
              </InputLabel>

              <TextField
                // label="Hour"
                ref={hourRef}
                select
                value={hour}
                onChange={(event) => setHour(event.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent",
                    },
                  },
                  "& .MuiInputLabel-outlined": {
                    // Additional styles for label if needed
                  },
                }}
              >
                {Array.from({ length: 12 }, (_, i) => i + 1).map(
                  (hourValue) => (
                    <MenuItem
                      key={hourValue}
                      value={hourValue.toString().padStart(2, "0")}
                    >
                      <Typography sx={{ fontFamily: "Poppins" }}>
                        {hourValue.toString().padStart(2, "0")}
                      </Typography>
                    </MenuItem>
                  )
                )}
              </TextField>
              <TextField
                // label="Minute"
                select
                value={minute}
                onChange={(event) => setMinute(event.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent",
                    },
                  },
                  "& .MuiInputLabel-outlined": {
                    // Additional styles for label if needed
                  },
                }}
              >
                {["00", "15", "30", "45"].map((minuteValue) => (
                  <MenuItem key={minuteValue} value={minuteValue}>
                    <Typography sx={{ fontFamily: "Poppins" }}>
                      {minuteValue}
                    </Typography>
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                // label="Period"
                select
                value={period}
                onChange={(event) => setPeriod(event.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent",
                    },
                  },
                  "& .MuiInputLabel-outlined": {
                    // Additional styles for label if needed
                  },
                }}
              >
                {["AM", "PM"].map((periodValue) => (
                  <MenuItem key={periodValue} value={periodValue}>
                    <Typography sx={{ fontFamily: "Poppins" }}>
                      {periodValue}
                    </Typography>
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid> */}

          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              readOnly={manipulationPermission}
              multiple
              limitTags={5}
              name="inspectionPerformed"
              id="inspection-performed"
              options={inspectionPerformed}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Inspection Performed"
                  sx={textfield}
                  error={Boolean(validationErrors.inspectionPerformed)}
                  helperText={validationErrors.inspectionPerformed}
                />
              )}
              onChange={(event, selectedOptions) => {
                const selectedIds = selectedOptions.map((option) => option.id);
                setInspectionIds(selectedIds);
                // Check if the selected options are empty
                setValidationErrors((prevErrors) => ({
                  ...prevErrors,
                  inspectionPerformed:
                    selectedOptions.length === 0
                      ? "Select at least one inspection"
                      : "",
                }));
              }}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              // multiline
              id="inspectionNotes"
              label="Inspection Notes"
              name="inspectionNotes"
              sx={textfield}
              type="text"
              // rows={4}
              onChange={handleChange}
              InputProps={{ readOnly: manipulationPermission }}
              // error={Boolean(validationErrors.inspectionNotes)}
              // helperText={validationErrors.inspectionNotes}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              readOnly={manipulationPermission}
              name="inspectionStatus"
              id="inspection-status"
              options={inspectionStatus}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Inspection Status"
                  sx={textfield}
                  error={Boolean(validationErrors.inspectionStatus)}
                  helperText={validationErrors.inspectionStatus}
                />
              )}
              onChange={(event, newValue) => {
                setInspectionDetailStatus(newValue?.name);
                const errorMessage =
                  inspectionDetailStatus === null
                    ? "Select at least one Status"
                    : "";

                // Update the validationErrors state with the error message
                setValidationErrors({
                  ...validationErrors,
                  inspectionStatus: errorMessage,
                });
              }}
              isOptionEqualToValue={(option, value) => option?.name === value}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="address"
              label="Project Address"
              name="projectAddress"
              type="text"
              sx={textfield}
              value={recordInspectionDetails.projectAddress}
              onChange={handleChange}
              error={Boolean(validationErrors.projectAddress)}
              helperText={validationErrors.projectAddress}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{ readOnly: manipulationPermission }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Typography sx={recordInspectionTypo}>
                Inspector Information
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="inspectorName"
              label="Inspector Name"
              name="inspectorName"
              sx={textfield}
              type="text"
              onChange={handleChange}
              value={recordInspectionDetails.inspectorName}
              error={Boolean(validationErrors.inspectorName)}
              helperText={validationErrors.inspectorName}
              InputProps={{ readOnly: manipulationPermission }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="inspectorCredentials"
              label="State Credentials"
              name="inspectorCredentials"
              sx={textfield}
              type="text"
              onChange={handleChange}
              value={recordInspectionDetails.inspectorCredentials}
              error={Boolean(validationErrors.inspectorCredentials)}
              helperText={validationErrors.inspectorCredentials}
              InputProps={{ readOnly: manipulationPermission }}
            />
          </Grid>
        </Grid>

        {/*  add media section */}
        <Box>
          <Box sx={{ my: 3 }}>
            <Typography sx={recordInspectionTypo}>
              Add Photo & Documents
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 3,
              flexWrap: matches ? "wrap" : "nowrap",
            }}
          >
            <Box
              onDrop={onDropHandler}
              onDragOver={(e) => e.preventDefault()}
              sx={{
                minWidth: 327,
                height: 240,
                borderRadius: 2,
                backgroundColor: "#ECECEC",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                cursor: "pointer",
              }}
              onClick={handleAddPhotoClick}
            >
              <Typography
                sx={{
                  ...recordInspectionTypo,
                  color: "#CBCBCB",
                  cursor: "pointer",
                }}
              >
                +Add Photo & Documents
              </Typography>
              {/* <input
                id="fileInput"
                multiple
                disabled={manipulationPermission}
                style={{ display: "none", cursor: "pointer" }}
                type="file"
                onChange={(event) =>
                  handleSelectFiles(
                    event.target.files[0],
                    (document.getElementById("fileInput").value = "")
                  )
                }
              /> */}
              <input
                id="fileInput"
                multiple
                disabled={manipulationPermission}
                style={{ display: "none", cursor: "pointer" }}
                type="file"
                onChange={(event) =>
                  handleSelectFiles(
                    Array.from(event.target.files), // Pass all selected files
                    (document.getElementById("fileInput").value = "")
                  )
                }
              />
            </Box>
            <Box
              sx={{
                overflowX: "auto",
                display: "flex",
                gap: 3,
                borderRadius: 2,
                // [`::-webkit-scrollbar`]: {
                //   width: "0px",
                //   background: "transparent",
                // },
              }}
            >
              {previewImages.map((media, index) => (
                <Box
                  key={index}
                  sx={{
                    position: "relative",
                    [`::-webkit-scrollbar`]: {
                      width: "10px",
                      height: "10px",
                      backgroundColor: "white",
                    },
                    [`::-webkit-scrollbar-track `]: {
                      borderRadius: "10px",
                      backgroundColor: "#F5F5F5",
                    },
                    [`::-webkit-scrollbar-thumb`]: {
                      borderRadius: "10px",
                      backgroundColor: "#cbcbcb",
                    },
                  }}
                >
                  {media.type === "Video" ? (
                    <video
                      style={{
                        width: 327,
                        height: 200,
                        borderRadius: "10px",
                      }}
                      controls
                    >
                      <source src={media.url} type="video/mp4" />
                    </video>
                  ) : (
                    <img
                      src={media.url}
                      alt="image preview"
                      style={{
                        width: 327,
                        height: 240,
                        borderRadius: "10px",
                      }}
                    />
                  )}
                  {media.type === "Video" ? (
                    <div style={downloadButtonWrapperVideo}>
                      <Button
                        variant="outlined"
                        fullWidth
                        sx={downloadMediaButton}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Typography
                            variant="subText"
                            sx={{
                              color: "white",
                              marginRight: "8px",
                              fontSize: "16px",
                            }}
                          >
                            {media?.name
                              ?.split(" ")
                              ?.join("-")
                              ?.split(".")
                              ?.slice(0, -1)
                              ?.join(".")}
                          </Typography>
                        </Box>
                      </Button>
                    </div>
                  ) : (
                    <div style={downloadButtonWrapper}>
                      <Button
                        variant="outlined"
                        fullWidth
                        sx={downloadMediaButton}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Typography
                            variant="subText"
                            sx={{
                              color: "white",
                              marginRight: "8px",
                              fontSize: "16px",
                            }}
                          >
                            {media?.name
                              ?.split(" ")
                              ?.join("-")
                              ?.split(".")
                              ?.slice(0, -1)
                              ?.join(".")}
                          </Typography>
                        </Box>
                      </Button>
                    </div>
                  )}
                  <button
                    onClick={() => handleRemovePreview(index, media.url)}
                    style={{
                      position: "absolute",
                      top: 8,
                      right: 6,
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <span>
                      <IconButton
                        size="small"
                        sx={{
                          backgroundColor: "#D52027",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#D52027",
                            color: "white",
                            boxShadow: 2,
                          },
                        }}
                      >
                        <CloseRoundedIcon />
                      </IconButton>
                    </span>
                  </button>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 1,
          }}
        >
          <Button
            onClick={handleSave}
            disabled={loading || manipulationPermission}
            sx={
              loading
                ? {
                    width: "178px",
                    height: "46px",
                    background: "#979797",
                    borderRadius: "10px",
                    color: "white",
                    "&:hover": { backgroundColor: "#979797" },
                  }
                : {
                    width: "178px",
                    height: "46px",
                    backgroundColor: "background.bgLayoutB",
                    borderRadius: "10px",
                    color: "white",
                    fontFamily: "Poppins-Bold",
                    "&:hover": {
                      backgroundColor: "red",
                      boxShadow: 2,
                      transition: ".7s ease-in",
                    },
                    "&:disabled": {
                      backgroundColor:
                        manipulationPermission && "background.bgLayoutB",
                      color: "white",
                    },
                  }
            }
          >
            Save
            {loading ? <CircularProgressLoader /> : ""}
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default Index;
