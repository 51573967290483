import React, { useState } from "react";
import {
  Box,
  Menu,
  AppBar,
  Avatar,
  Toolbar,
  Tooltip,
  useTheme,
  MenuItem,
  Typography,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import {
  appbar,
  topbarLogo,
  topbarHeading,
  topbarMainBox,
  topbarBackButton,
  topbarSecondaryBox,
  topbarSecondaryBoxTooltipAvatar,
  topbarSecondaryBoxTooltipIconButton,
  // themeIconButton,
} from "../utils/muiComponentStyles";
import logo from "../assets/icons/logo.png";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Logout from "@mui/icons-material/Logout";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarResponsive from "./SidebarResponsive";
import RightSidebarResponsive from "./RightSidebarResponsive";
// import Brightness4Icon from "@mui/icons-material/Brightness4";
// import Brightness7Icon from "@mui/icons-material/Brightness7";
// localization
// import LanguagePopover from "./LanguagePopover";
import useLocales from "../hooks/useLocales";
// import ThemeContext from "../utils/themeContext";

const Topbar = () => {
  const loggedInUserDetails = JSON.parse(localStorage.getItem("user"));
  const theme = useTheme();
  const { translate } = useLocales();
  // const themeContext = useContext(ThemeContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const location = useLocation();
  const path = location.pathname.split("/").pop().replace(/-/g, " ");

  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("userDefaultId");
    // localStorage.removeItem("permissions");
    // localStorage.removeItem("isSuperAdmin");
    // localStorage.removeItem("user");
    // localStorage.removeItem("selectedApplication");
    localStorage.clear();
    navigate("/login");
  };

  // const handleChangeTheme = () => {
  //   themeContext.setTheme("sending from Demo");
  // };

  const handleBackArrowButton = () => {
    navigate(-1);
    localStorage.removeItem("selectedContractor");
    localStorage.removeItem("selectedUser");
  };

  return (
    <AppBar elevation={0} sx={appbar} component="nav">
      <Toolbar>
        {isMatch && <SidebarResponsive />}

        <Box component="div" sx={topbarLogo} onClick={() => navigate("/")}>
          <img
            alt="Logo"
            src={logo}
            style={{
              display: "block",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              cursor: "pointer",
            }}
          />
        </Box>
        <Box sx={topbarMainBox}>
          <Box
            style={{ width: "100%" }}
            sx={{
              ...topbarHeading,
              ml: path === "" ? "117px" : "70px",
            }}
          >
            {path === "" ? (
              <Typography sx={topbarHeading} variant="topHeadingSmall">
                welcome to Wi Permit Dashboard
              </Typography>
            ) : (
              <IconButton onClick={handleBackArrowButton} sx={topbarBackButton}>
                {<ArrowBackIcon />}
              </IconButton>
            )}
            {path?.length >= 18 ? (
              <Typography sx={topbarHeading} variant="topHeadingSmall">
                {translate(path)}
              </Typography>
            ) : path?.length >= 15 ? (
              <Typography sx={topbarHeading} variant="topHeadingSmall">
                {translate(path)}
              </Typography>
            ) : (
              <Typography sx={topbarHeading} variant="topHeadingSmall">
                {translate(path)}
              </Typography>
            )}
          </Box>
          <Box />
          <Box sx={topbarSecondaryBox}>
            {/* <IconButton
              sx={themeIconButton}
              onClick={() => handleChangeTheme()}
            >
              {themeContext.isDarkTheme == "false" ||
              themeContext.isDarkTheme == !true ? (
                <Brightness7Icon sx={{ color: "white" }} />
              ) : (
                <Brightness4Icon />
              )}
            </IconButton> */}

            {/* <Box component="div">
              <LanguagePopover />
            </Box> */}

            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={topbarSecondaryBoxTooltipIconButton}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar sx={topbarSecondaryBoxTooltipAvatar}>
                  {loggedInUserDetails?.firstName?.slice(0, 1)}
                </Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            disableScrollLock={true}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              {translate("logout")}
            </MenuItem>
          </Menu>
        </Box>
        {isMatch && <RightSidebarResponsive />}
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
