export const tableCellBackgroundColor = {
  backgroundColor: "background.bgLayoutA",
};

export const tableAvatarCell = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
};

export const tableAvatar = {
  backgroundColor: "background.bgLayoutB",
  color: "white",
  marginRight: "10px",
};

export const tableIconBox = {
  display: "flex",
  justifyContent: "end",
};

export const tableIconButton = {
  backgroundColor: "background.bgLayoutA",
  "&:hover": { backgroundColor: "background.bgLayoutA" },
};

export const tableIconButtonWithMargin = {
  marginLeft: "10px",
  backgroundColor: "background.bgLayoutB",
  "&:hover": { backgroundColor: "background.bgLayoutB" },
};
