import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import {
  applicationCardStyle,
  miniScrollBarStyles,
  textfield,
  textfieldDate,
  userHeading,
} from "../../utils/muiComponentStyles";
import { ValidatorForm } from "react-material-ui-form-validator";
import ToasterContext from "../../utils/context/toasterContext";
import { applicantTypos } from "../applicationRecord/applicationDetailForm/ApplicationDetailFormStyle";
import CircularProgressLoader from "../../utils/CircularProgressLoader";
import { useDispatch } from "react-redux";
import {
  createContractor,
  getAllContractors,
} from "../../redux/slices/contractorSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "../../redux/store";
const contractorTypeArr = [
  "General",
  "Dwelling",
  "Electrical",
  "Plumbing",
  "HVAC",
];

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [contractorType, setContractorType] = useState("Dwelling");
  const [saveElectricalContractorPressed, setSaveElectricalContractorPressed] =
    useState(false);
  const [electricalMasterEmailError, setElectrcialMasterEmailError] =
    useState(false);
  const [electricalEmailError, setElectricalEmailError] = useState(false);
  const [electricalObject, setElectricalObject] = useState({});
  const fireToasterContext = useContext(ToasterContext);
  const [dwellQualifierEmailError] = useState(false);
  const [contractorObject, setContractorObject] = useState({});
  let { contractorForListingData } = useSelector(
    (state) => state.contractorReducer
  );
  const handleChange = (event) => {
    setContractorType(event.target.value);
    setSaveElectricalContractorPressed(false);
  };
  const handleElectricalChange = (event) => {
    setElectricalObject({
      ...electricalObject,
      [event.target.name]: event.target.value,
    });
  };

  const handleContractorChange = (event) => {
    setContractorObject({
      ...contractorObject,
      [event.target.name]: event.target.value,
    });
  };

  const handleApiResponse = (data) => {
    setLoading(false);
    if (data.status) {
      navigate("/contractors");
      fireToasterContext.fireToasterHandler(
        true,
        data.message ? data.message : "Something went wrong"
      );
      dispatch(getAllContractors());
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data.message ? data.message : "Something went wrong"
      );
    }
  };

  // ** handleSubmit to create contractor
  const handleCreateElectricalContractorSubmit = () => {
    setSaveElectricalContractorPressed(true);
    if (electricalObject?.email) {
      var pattern = /\S+@\S+\.\S+/;
      if (!pattern.test(electricalObject?.email)) {
        setElectricalEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter a valid email address"
        );
        return;
      } else {
        setElectricalEmailError(false);
      }
    }
    if (electricalObject?.masterElectricianEmail) {
      var patternMasterEmail = /\S+@\S+\.\S+/;
      if (!patternMasterEmail.test(electricalObject?.masterElectricianEmail)) {
        setElectrcialMasterEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Master Electrician Email is not valid. Please enter a valid email address"
        );
        return;
      } else {
        setElectrcialMasterEmailError(false);
      }
    }

    // Check for existing contractors
    const existingContractor = contractorForListingData.find(
      (contractor) =>
        contractor.name === electricalObject?.name ||
        // contractor.email === electricalObject?.email ||
        contractor.companyName === electricalObject?.companyName ||
        // contractor.phoneNumber === electricalObject?.phoneNumber ||
        contractor.licenseNumber === electricalObject?.licenseNumber ||
        contractor.licenseExpirationDate ===
          electricalObject?.licenseExpirationDate
      // contractor.address === electricalObject?.address ||
      // contractor.city === electricalObject?.city ||
      // contractor.zip === electricalObject?.zip
    );
    if (existingContractor) {
      fireToasterContext.fireToasterHandler(
        false,
        "This contractor already exists."
      );
      return; // Stop further execution if duplicate found
    }
    let finalElectricalContractorObject = {
      type: contractorType,
      name: null,
      email: electricalObject?.email,
      companyName: electricalObject?.companyName,
      phoneNumber: electricalObject?.phoneNumber,
      licenseNumber: electricalObject?.licenseNumber,
      licenseExpirationDate: electricalObject?.licenseExpirationDate,
      address: electricalObject?.address,
      city: electricalObject?.city,
      zip: electricalObject?.zip,
      masterElectricianName: electricalObject?.masterElectricianName,
      masterElectricianEmail: electricalObject?.masterElectricianEmail,
      masterElectricianPhoneNumber:
        electricalObject?.masterElectricianPhoneNumber,
      masterElectricianLicenseNumber:
        electricalObject?.masterElectricianLicenseNumber,
      masterElectricianLicenseExpirationDate:
        electricalObject?.masterElectricianLicenseExpirationDate,
      masterElectricianAddress: electricalObject?.masterElectricianAddress,
      masterElectricianCity: electricalObject?.masterElectricianCity,
      masterElectricianZip: electricalObject?.masterElectricianZip,
      temporary: false,
    };

    let finalDwellContractorObject = {
      type: "Construction",
      temporary: false,
      name: electricalObject?.name,
      companyName: electricalObject?.companyName,
      phoneNumber: electricalObject?.phoneNumber,
      email: electricalObject?.email,
      licenseNumber: electricalObject?.licenseNumber,
      licenseExpirationDate: electricalObject?.licenseExpirationDate,
      address: electricalObject?.address,
      city: electricalObject?.city,
      zip: electricalObject?.zip,
      dwellingContractorQualifierName:
        contractorObject.dwellingContractorQualifierName,
      dwellingContractorQualifierLicenseNumber:
        contractorObject.dwellingContractorQualifierLicenseNumber,
      dwellingContractorQualifierLicenseExpirationDate:
        contractorObject.dwellingContractorQualifierLicenseExpirationDate,
      dwellingContractorQualifierAddress:
        contractorObject?.dwellingContractorQualifierAddress,
      dwellingContractorQualifierCity:
        contractorObject.dwellingContractorQualifierCity,
      dwellingContractorQualifierZip:
        contractorObject.dwellingContractorQualifierZip,
      dwellingContractorQualifierEmail:
        contractorObject.dwellingContractorQualifierEmail,
      dwellingContractorQualifierPhoneNumber:
        contractorObject.dwellingContractorQualifierPhoneNumber,
    };

    // ** for HVAC
    let dwellingContraactorObj = {
      type: contractorType,
      name: null,
      email: electricalObject?.email,
      companyName: electricalObject?.companyName,
      phoneNumber: electricalObject?.phoneNumber,
      licenseNumber: electricalObject?.licenseNumber,
      licenseExpirationDate: electricalObject?.licenseExpirationDate,
      address: electricalObject?.address,
      city: electricalObject?.city,
      zip: electricalObject?.zip,
      temporary: false,
    };

    let plumbingContraactorObj = {
      type: contractorType,
      name: null,
      email: electricalObject?.email,
      companyName: electricalObject?.companyName,
      phoneNumber: electricalObject?.phoneNumber,
      licenseNumber: electricalObject?.licenseNumber,
      licenseExpirationDate: electricalObject?.licenseExpirationDate,
      address: electricalObject?.address,
      city: electricalObject?.city,
      zip: electricalObject?.zip,
      temporary: false,
    };

    let generalContraactorObj = {
      type: contractorType,
      name: electricalObject?.name,
      email: electricalObject?.email,
      companyName: electricalObject?.companyName,
      phoneNumber: electricalObject?.phoneNumber,
      temporary: false,
    };

    if (contractorType === "Electrical") {
      if (
        // electricalObject?.name &&
        electricalObject?.companyName &&
        electricalObject?.email &&
        electricalObject?.phoneNumber &&
        electricalObject?.licenseNumber &&
        electricalObject?.licenseExpirationDate &&
        electricalObject?.address &&
        electricalObject?.city &&
        electricalObject?.zip &&
        electricalObject?.masterElectricianName &&
        electricalObject?.masterElectricianEmail &&
        electricalObject?.masterElectricianPhoneNumber &&
        electricalObject?.masterElectricianLicenseNumber &&
        electricalObject?.masterElectricianLicenseExpirationDate &&
        electricalObject?.masterElectricianAddress &&
        electricalObject?.masterElectricianCity &&
        electricalObject?.masterElectricianZip
      ) {
        setLoading(true);
        let finalObj = finalElectricalContractorObject;
        dispatch(createContractor(finalObj, handleApiResponse));
      } else {
        fireToasterContext.fireToasterHandler(
          false,
          `Please fill in all required fields`
        );
        return;
      }
    } else if (contractorType === "Dwelling") {
      if (
        electricalObject?.name &&
        electricalObject?.companyName &&
        electricalObject?.email &&
        electricalObject?.phoneNumber &&
        electricalObject?.licenseNumber &&
        electricalObject?.licenseExpirationDate &&
        electricalObject?.address &&
        electricalObject?.city &&
        electricalObject?.zip &&
        contractorObject?.dwellingContractorQualifierEmail &&
        contractorObject?.dwellingContractorQualifierName &&
        contractorObject?.dwellingContractorQualifierLicenseNumber &&
        contractorObject?.dwellingContractorQualifierAddress &&
        contractorObject?.dwellingContractorQualifierLicenseExpirationDate &&
        contractorObject?.dwellingContractorQualifierCity &&
        contractorObject?.dwellingContractorQualifierZip &&
        contractorObject?.dwellingContractorQualifierEmail &&
        contractorObject?.dwellingContractorQualifierPhoneNumber
      ) {
        setLoading(true);
        let finalObj = finalDwellContractorObject;
        dispatch(createContractor(finalObj, handleApiResponse));
      } else {
        fireToasterContext.fireToasterHandler(
          false,
          `Please fill in all required fields`
        );
        return;
      }
    } else if (contractorType === "HVAC") {
      if (
        // electricalObject?.name &&
        electricalObject?.email &&
        electricalObject?.companyName &&
        electricalObject?.phoneNumber &&
        electricalObject?.licenseNumber &&
        electricalObject?.licenseExpirationDate
      ) {
        setLoading(true);
        let finalObj = dwellingContraactorObj;
        dispatch(createContractor(finalObj, handleApiResponse));
      } else {
        fireToasterContext.fireToasterHandler(
          false,
          `Please fill in all required fields`
        );
        return;
      }
    } else if (contractorType === "Plumbing") {
      if (
        // electricalObject?.name &&
        electricalObject?.email &&
        electricalObject?.companyName &&
        electricalObject?.phoneNumber &&
        electricalObject?.licenseNumber &&
        electricalObject?.licenseExpirationDate &&
        electricalObject?.address &&
        electricalObject?.city &&
        electricalObject?.zip
      ) {
        setLoading(true);
        let finalObj = plumbingContraactorObj;
        dispatch(createContractor(finalObj, handleApiResponse));
      } else {
        fireToasterContext.fireToasterHandler(
          false,
          `Please fill in all required fields`
        );
        return;
      }
    } else if (contractorType === "General") {
      if (
        electricalObject?.name &&
        electricalObject?.email &&
        electricalObject?.phoneNumber &&
        electricalObject.companyName
      ) {
        setLoading(true);
        let finalObj = generalContraactorObj;
        dispatch(createContractor(finalObj, handleApiResponse));
      } else {
        fireToasterContext.fireToasterHandler(
          false,
          `Please fill in all required fields`
        );
        return;
      }
    }
  };

  if (!contractorType) {
    fireToasterContext.fireToasterHandler(
      false,
      "Please select a contractor type"
    );
  }

  return (
    <Container>
      <Card>
        <CardHeader
          disableTypography
          title="Add new Contractor"
          sx={applicationCardStyle}
        />
        <ValidatorForm
          style={{ width: "100%" }}
          onSubmit={handleCreateElectricalContractorSubmit}
          onError={(errors) => console.log(errors)}
        >
          <CardContent
            sx={{
              mt: 2,
              mx: 2,
              height:
                contractorType === "General"
                  ? "280px"
                  : contractorType === "HVAC" || contractorType === "Plumbing"
                  ? "380px"
                  : "580px",
              overflowY: "auto",
              [`::-webkit-scrollbar`]: {
                width: "0px",
                background: "transparent",
              },
              ...miniScrollBarStyles,
            }}
          >
            <Grid container spacing={2} s>
              {/* contractor type */}

              <Grid item xs={12}>
                <TextField
                  select
                  label="Select Contractor Type"
                  value={contractorType}
                  onChange={handleChange}
                  fullWidth
                  sx={textfield}
                  error={!contractorType && saveElectricalContractorPressed}
                  helperText={
                    !contractorType && saveElectricalContractorPressed
                      ? "Name is required"
                      : ""
                  }
                >
                  {contractorTypeArr.sort().map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {contractorType === "General" ? (
                <>
                  {" "}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      error={
                        !electricalObject?.name &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.name &&
                        saveElectricalContractorPressed
                          ? "Name is required"
                          : ""
                      }
                      id="electrical-contractor-name"
                      label={`${contractorType} Contractor Name`}
                      name="name"
                      sx={textfield}
                      onChange={handleElectricalChange}
                    />
                  </Grid>
                  {/* Electrical company name */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="electrical-contract-company"
                      label={`${contractorType} Contractor Company Name`}
                      name="companyName"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        !electricalObject?.companyName &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.companyName &&
                        saveElectricalContractorPressed
                          ? "Company Name is required"
                          : ""
                      }
                    />
                  </Grid>
                  {/* Electrical company email */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="electrical-company-email"
                      label={`${contractorType} Contractor Email`}
                      type="email"
                      name="email"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        !!electricalEmailError ||
                        (!electricalObject?.email &&
                          saveElectricalContractorPressed)
                      }
                      helperText={
                        electricalEmailError ||
                        (!electricalObject?.email &&
                          saveElectricalContractorPressed)
                          ? "Please enter a valid email address"
                          : ""
                      }
                    />
                  </Grid>
                  {/* electrical phone number */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      error={
                        !electricalObject?.phoneNumber &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.phoneNumber &&
                        saveElectricalContractorPressed
                          ? "Phone number is required"
                          : ""
                      }
                      id="electrical-phone-number"
                      label={`${contractorType} Contractor Phone No.`}
                      name="phoneNumber"
                      sx={textfield}
                      onChange={handleElectricalChange}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  {/* Electrical name */}
                  {contractorType === "Dwelling" && (
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        error={
                          !electricalObject?.name &&
                          saveElectricalContractorPressed
                        }
                        helperText={
                          !electricalObject?.name &&
                          saveElectricalContractorPressed
                            ? "Name is required"
                            : ""
                        }
                        id="electrical-contractor-name"
                        label={`${contractorType} Contractor Name`}
                        name="name"
                        sx={textfield}
                        onChange={handleElectricalChange}
                      />
                    </Grid>
                  )}
                  {/* Electrical company name */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="electrical-contract-company"
                      label={`${contractorType} Contractor Company Name`}
                      name="companyName"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        !electricalObject?.companyName &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.companyName &&
                        saveElectricalContractorPressed
                          ? "Company Name is required"
                          : ""
                      }
                    />
                  </Grid>
                  {/* Electrical company email */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="electrical-company-email"
                      label={`${contractorType} Contractor Email`}
                      type="email"
                      name="email"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        !!electricalEmailError ||
                        (!electricalObject?.email &&
                          saveElectricalContractorPressed)
                      }
                      helperText={
                        electricalEmailError ||
                        (!electricalObject?.email &&
                          saveElectricalContractorPressed)
                          ? "Please enter a valid email address"
                          : ""
                      }
                    />
                  </Grid>
                  {/* electrical phone number */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      error={
                        !electricalObject?.phoneNumber &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.phoneNumber &&
                        saveElectricalContractorPressed
                          ? "Phone number is required"
                          : ""
                      }
                      id="electrical-phone-number"
                      label={`${contractorType} Contractor Phone No.`}
                      name="phoneNumber"
                      sx={textfield}
                      onChange={handleElectricalChange}
                    />
                  </Grid>
                  {/*  electrical License number */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      error={
                        !electricalObject?.licenseNumber &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.licenseNumber &&
                        saveElectricalContractorPressed
                          ? "License number is required"
                          : ""
                      }
                      id="electrician-license-number"
                      label={`${contractorType} Contractor License No.`}
                      name="licenseNumber"
                      sx={textfield}
                      onChange={handleElectricalChange}
                    />
                  </Grid>
                  {/* electrician license expire date */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      error={
                        !electricalObject?.licenseExpirationDate &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.licenseExpirationDate &&
                        saveElectricalContractorPressed
                          ? "License expiration date is required"
                          : ""
                      }
                      id="electrician-license-expire-date"
                      name="licenseExpirationDate"
                      onChange={handleElectricalChange}
                      disableUnderline
                      fullWidth
                      sx={textfieldDate}
                      label="License expiration date"
                      inputProps={{
                        max: "9999-12-31",
                      }}
                      type="date"
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>
                  {/* electrical street address */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="electrical-street-address"
                      label={`${contractorType} Contractor Street Address`}
                      name="address"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        contractorType !== "HVAC" &&
                        !electricalObject?.address &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        contractorType !== "HVAC" &&
                        !electricalObject?.address &&
                        saveElectricalContractorPressed
                          ? "Street address is required"
                          : ""
                      }
                    />
                  </Grid>
                  {/* electrical city field */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="electrical-city"
                      label={`${contractorType} Contractor City`}
                      name="city"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        contractorType !== "HVAC" &&
                        !electricalObject?.city &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        contractorType !== "HVAC" &&
                        !electricalObject?.city &&
                        saveElectricalContractorPressed
                          ? "City is required"
                          : ""
                      }
                    />
                  </Grid>
                  {/* electrical zip code */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={contractorType === "Dwelling" ? 12 : 6}
                    lg={contractorType === "Dwelling" ? 12 : 6}
                  >
                    <TextField
                      id="electrical-zip-code"
                      label={`${contractorType} Contractor Zip code`}
                      name="zip"
                      type="number"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        contractorType !== "HVAC" &&
                        !electricalObject?.zip &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        contractorType !== "HVAC" &&
                        !electricalObject?.zip &&
                        saveElectricalContractorPressed
                          ? "Zip code is required"
                          : ""
                      }
                    />
                  </Grid>
                </>
              )}
            </Grid>
            {contractorType === "Electrical" ? (
              <Box component="div" sx={{ marginTop: "20px" }}>
                <Typography sx={applicantTypos}>
                  Master Electrician Contractor&apos;s Information
                </Typography>
                <Grid container spacing={2} sx={{ my: "5px" }}>
                  {/* master electrician name */}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="master-electrician-name"
                      label="Master Electrician Name"
                      name="masterElectricianName"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        !electricalObject?.masterElectricianName &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.masterElectricianName &&
                        saveElectricalContractorPressed
                          ? "Master electrician Name is required"
                          : ""
                      }
                    />
                  </Grid>

                  {/* master electrician email */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="master-electrical-email"
                      label="Master Electrician Email"
                      name="masterElectricianEmail"
                      type="email"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        !!electricalMasterEmailError ||
                        (!electricalObject?.masterElectricianEmail &&
                          saveElectricalContractorPressed)
                      }
                      helperText={
                        electricalMasterEmailError ||
                        (!electricalObject?.masterElectricianEmail &&
                          saveElectricalContractorPressed)
                          ? "Please enter a valid email address"
                          : ""
                      }
                    />
                  </Grid>

                  {/* master electrician phonenumber */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="master-electrician-phoneNumber"
                      label="Master Electrician Phone Number"
                      name="masterElectricianPhoneNumber"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        !electricalObject?.masterElectricianPhoneNumber &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.masterElectricianPhoneNumber &&
                        saveElectricalContractorPressed
                          ? "Master electrician phone is required"
                          : ""
                      }
                    />
                  </Grid>

                  {/* master electrician certificate no */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      error={
                        !electricalObject?.masterElectricianLicenseNumber &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.masterElectricianLicenseNumber &&
                        saveElectricalContractorPressed
                          ? "Master electrican license number is required"
                          : ""
                      }
                      id="master-electrician-certificate"
                      label="Master Electrician License/Certificate No."
                      name="masterElectricianLicenseNumber"
                      sx={textfield}
                      onChange={handleElectricalChange}
                    />
                  </Grid>

                  {/* master electrician license expiration date */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      error={
                        !electricalObject?.masterElectricianLicenseExpirationDate &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.masterElectricianLicenseExpirationDate &&
                        saveElectricalContractorPressed
                          ? "Master license expiration date is required"
                          : ""
                      }
                      id="master-electrical-license-expire-date"
                      name="masterElectricianLicenseExpirationDate"
                      onChange={handleElectricalChange}
                      disableUnderline
                      fullWidth
                      sx={textfieldDate}
                      label="License Expiration Date"
                      inputProps={{
                        max: "9999-12-31",
                      }}
                      type="date"
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>

                  {/* master electrical address */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="master-electrical-address"
                      label="Master Electrician Street Address"
                      name="masterElectricianAddress"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        !electricalObject?.masterElectricianAddress &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.masterElectricianAddress &&
                        saveElectricalContractorPressed
                          ? "Master electrician street address is required"
                          : ""
                      }
                    />
                  </Grid>

                  {/* master Electrical city */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="master-electrical-city"
                      label="Master Electrician City"
                      name="masterElectricianCity"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        !electricalObject?.masterElectricianCity &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.masterElectricianCity &&
                        saveElectricalContractorPressed
                          ? "Master electrician city is required"
                          : ""
                      }
                    />
                  </Grid>

                  {/* master electrical zipcode */}
                  <Grid item xs={12} sm={12} md={12} lg={12} align="right">
                    <TextField
                      id="master-electrical-zipcode"
                      label="Master Electrician Zip code"
                      name="masterElectricianZip"
                      sx={textfield}
                      type="number"
                      onChange={handleElectricalChange}
                      error={
                        !electricalObject?.masterElectricianZip &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.masterElectricianZip &&
                        saveElectricalContractorPressed
                          ? "Master electrician zip code is required"
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            ) : contractorType === "Dwelling" ? (
              <Box sx={{ marginTop: "20px" }}>
                <Typography sx={applicantTypos}>
                  Dwelling Contractor&apos;s Qualifier Information
                </Typography>
                <Grid container spacing={2}>
                  {/* dweeling contractor qualifier name */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="dwelling-contractor-qualifier-name"
                      label="Dwelling Contractor Qualifier Name"
                      value={
                        contractorObject?.dwellingContractorQualifierName || ""
                      }
                      name="dwellingContractorQualifierName"
                      sx={textfield}
                      onChange={handleContractorChange}
                      error={
                        !contractorObject?.dwellingContractorQualifierName &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !contractorObject?.dwellingContractorQualifierName &&
                        saveElectricalContractorPressed
                          ? "Dwelling contractor qualifier name is required"
                          : ""
                      }
                    />
                  </Grid>
                  {/* dwelling contractor qualifier's license number */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="dwelling-contractor-qualifier-license-number"
                      label="Dwelling Contractor Qualifier License No."
                      name="dwellingContractorQualifierLicenseNumber"
                      value={
                        contractorObject?.dwellingContractorQualifierLicenseNumber ||
                        ""
                      }
                      sx={textfield}
                      onChange={handleContractorChange}
                      error={
                        !contractorObject?.dwellingContractorQualifierLicenseNumber &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !contractorObject?.dwellingContractorQualifierLicenseNumber &&
                        saveElectricalContractorPressed
                          ? "Dwelling contractor qualifier license number is required"
                          : ""
                      }
                    />
                  </Grid>
                  {/* dwelling contractor qualifier's license number expire date */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="dwelling-contractor-qualifier-license-number-expire-date"
                      name="dwellingContractorQualifierLicenseExpirationDate"
                      value={
                        contractorObject?.dwellingContractorQualifierLicenseExpirationDate?.split(
                          "T"
                        )[0]
                      }
                      onChange={handleContractorChange}
                      disableUnderline
                      fullWidth
                      sx={textfieldDate}
                      label="License Expiration Date"
                      inputProps={{
                        max: "9999-12-31",
                      }}
                      type="date"
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ disableUnderline: true }}
                      error={
                        !contractorObject?.dwellingContractorQualifierLicenseExpirationDate &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !contractorObject?.dwellingContractorQualifierLicenseExpirationDate &&
                        saveElectricalContractorPressed
                          ? "Dwelling contractor qualifier license expiration date is required"
                          : ""
                      }
                    />
                  </Grid>
                  {/* Dwelling Contractor Qualifier Address */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="dwelling-contractor-Qualifier-address"
                      label="Dwelling Contractor Qualifier Address"
                      name="dwellingContractorQualifierAddress"
                      value={
                        contractorObject?.dwellingContractorQualifierAddress ||
                        ""
                      }
                      sx={textfield}
                      onChange={handleContractorChange}
                      error={
                        !contractorObject?.dwellingContractorQualifierAddress &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !contractorObject?.dwellingContractorQualifierAddress &&
                        saveElectricalContractorPressed
                          ? "Dwelling contractor qualifier address is required"
                          : ""
                      }
                    />
                  </Grid>
                  {/* dwelling contractor qualifier city */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="dwelling-contractor-qualifier-city"
                      label="Dwelling Contractor Qualifier City"
                      name="dwellingContractorQualifierCity"
                      value={
                        contractorObject?.dwellingContractorQualifierCity || ""
                      }
                      sx={textfield}
                      onChange={handleContractorChange}
                      error={
                        !contractorObject?.dwellingContractorQualifierCity &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !contractorObject?.dwellingContractorQualifierCity &&
                        saveElectricalContractorPressed
                          ? "Dwelling contractor qualifier city is required"
                          : ""
                      }
                    />
                  </Grid>
                  {/* dwelling contractor qualifier zipCode */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="dwelling-contractor-qualifier-zip-code"
                      label="Dwelling Contractor Qualifier Zip Code"
                      name="dwellingContractorQualifierZip"
                      type="number"
                      value={
                        contractorObject?.dwellingContractorQualifierZip || ""
                      }
                      sx={textfield}
                      onChange={handleContractorChange}
                      error={
                        !contractorObject?.dwellingContractorQualifierZip &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !contractorObject?.dwellingContractorQualifierZip &&
                        saveElectricalContractorPressed
                          ? "Dwelling contractor qualifier zip code is required"
                          : ""
                      }
                    />
                  </Grid>
                  {/* dwelling contractor qualifier email */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="dwelling-contractor-qualifier-email"
                      label="Dwelling Contractor Qualifier Email"
                      name="dwellingContractorQualifierEmail"
                      value={
                        contractorObject?.dwellingContractorQualifierEmail || ""
                      }
                      type="email"
                      sx={textfield}
                      onChange={handleContractorChange}
                      error={
                        !!dwellQualifierEmailError ||
                        (!contractorObject?.dwellingContractorQualifierEmail &&
                          saveElectricalContractorPressed)
                      }
                      helperText={
                        dwellQualifierEmailError ||
                        (!contractorObject?.dwellingContractorQualifierEmail &&
                          saveElectricalContractorPressed)
                          ? "Please enter a valid email address"
                          : ""
                      }
                    />
                  </Grid>
                  {/* dwelling contractor qualifier phone number */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="dwelling-contractor-qualifier-phone-number"
                      label="Dwelling Contractor Qualifier Phone No."
                      name="dwellingContractorQualifierPhoneNumber"
                      value={
                        contractorObject?.dwellingContractorQualifierPhoneNumber ||
                        ""
                      }
                      sx={textfield}
                      onChange={handleContractorChange}
                      error={
                        !contractorObject?.dwellingContractorQualifierPhoneNumber &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !contractorObject?.dwellingContractorQualifierPhoneNumber &&
                        saveElectricalContractorPressed
                          ? "Dwelling contractor qualifier phone number is required"
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            ) : null}
          </CardContent>
          <CardActions
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Button
              disableRipple
              type="submit"
              disabled={loading}
              sx={
                loading
                  ? {
                      background: "#979797",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "187px",
                      "&:hover": { backgroundColor: "#979797" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
                  : {
                      backgroundColor: "background.bgLayoutB",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "187px",
                      "&:hover": { backgroundColor: "background.bgLayoutB" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
              }
            >
              <Typography sx={userHeading} variant="userHeadingBoldButton">
                Add contractor
              </Typography>
              {loading ? <CircularProgressLoader /> : ""}
            </Button>
          </CardActions>
        </ValidatorForm>
      </Card>
    </Container>
  );
};

export default Index;
