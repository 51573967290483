import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { dispatch } from "../store";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};
const initialState = {
  buildingPlacardData: {},
};

const slice = createSlice({
  name: "buildinPlacard",
  initialState,
  reducers: {
    getBuildingPlacard(state, action) {
      state.buildingPlacardData = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getAllBuilidingPlacardData(handleApiResponse) {
  let applicationId = JSON.parse(
    localStorage.getItem("selectedApplication")
  ).id;
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/building-placards?applicationId=${applicationId}`,
        { headers }
      );
      dispatch(slice.actions.getBuildingPlacard(response.data));
      handleApiResponse(response.data);
    } catch (error) {
      console.log(error);
      if (handleApiResponse) {
        handleApiResponse(error?.response?.data);
      }
    }
  };
}

export function createBuilidingPlacardData(data, handleCreateApiResponse) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/building-placards`,
        data,
        { headers }
      );
      dispatch(slice.actions.getBuildingPlacard(response.data));
      // dispatch(getAllBuilidingPlacardData(handleCreateApiResponse));
      handleCreateApiResponse(response.data);
    } catch (error) {
      console.log(error);
      handleCreateApiResponse(error.response.data);
    }
  };
}

export function emptyObject() {
  dispatch(slice.actions.getBuildingPlacard({}));
}
