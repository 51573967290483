import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { dispatch } from "../store";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};
const initialState = {
  applicationPdfData: {},
};

const slice = createSlice({
  name: "PdfData",
  initialState,
  reducers: {
    getApplicationPdfData(state, action) {
      state.applicationPdfData = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getApplicationPdf(handleApiResponse) {
  let applicationId = JSON.parse(
    localStorage.getItem("selectedApplication")
  ).id;
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/applications/application-pdf?id=${applicationId}`,
        { headers }
      );
      dispatch(slice.actions.getApplicationPdfData(response.data));

      handleApiResponse(response.data);
    } catch (error) {
      console.log(error);
      if (handleApiResponse) {
        handleApiResponse(error?.response?.data);
      }
    }
  };
}

export function uploadPdf(pdfData, pdfDataResponse) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/applications/create-or-update-pdf`,
        pdfData,
        { headers }
      );
      if (pdfDataResponse) {
        pdfDataResponse(response.data);
      }
      // if (handleApiRes) {
      //   handleApiRes(response.data);
      // }
    } catch (error) {
      console.log(error);
      // if (handleApiRes) {
      //   handleApiRes(error.response.data);
      // }
    }
  };
}
